import { useSWRConfig } from 'swr';

import { API_ROUTES } from '../routes';

/**
 * Mutate all keys except the ones that are used to fetch the organization and user
 */
export const useMutateAllKeys = () => {
  const { mutate } = useSWRConfig();

  const mutateAllKeys = () =>
    mutate(
      (key) =>
        key !== API_ROUTES.organizations.get() &&
        key !== API_ROUTES.users.get(),
      undefined,
      {
        revalidate: true,
      }
    );

  return {
    mutateAllKeys,
  };
};
