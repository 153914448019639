import * as React from 'react';
import { SWRConfig, useSWRConfig } from 'swr';

import { Membership, Organization, Platform } from '../types';

export type ApiFinaryConfig = {
  api: string;
  getToken: (() => Promise<string | null>) | null | undefined;
  membershipId: Membership['id'] | null;
  organizationId: Organization['id'] | null;
  platform: Platform;
};

export const ApiContext = React.createContext<ApiFinaryConfig>({
  platform: Platform.Web,
  api: '',
  getToken: null,
  organizationId: null,
  membershipId: null,
});

type ApiProviderProps = {
  children: React.ReactNode;
  swrConfig?: React.ComponentProps<typeof SWRConfig>['value'];
} & ApiFinaryConfig;

export const ApiProvider = ({
  getToken,
  children,
  api,
  membershipId,
  organizationId,
  platform,
  swrConfig,
}: ApiProviderProps) => {
  return (
    <SWRConfig value={swrConfig}>
      <ApiContext.Provider
        value={{
          getToken,
          platform,
          api,
          membershipId,
          organizationId,
        }}
      >
        {children}
      </ApiContext.Provider>
    </SWRConfig>
  );
};

export const useFinaryApiConfig = () => {
  return React.useContext(ApiContext);
};

export const useApiConfig = () => {
  const swrConfig = useSWRConfig();
  const apiConfig = useFinaryApiConfig();

  return { ...swrConfig, ...apiConfig };
};

export const useClearCache = () => {
  const { cache } = useSWRConfig();

  return () => {
    if (!(cache instanceof Map)) {
      throw new Error(
        'useClearCache requires the cache provider to be a Map instance'
      );
    }
    cache.clear();
  };
};

export type ApiConfig = ReturnType<typeof useApiConfig>;
