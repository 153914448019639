import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../../config';
import { useApiConfig, useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import { PreciousMetal } from '../../../types';

export type CreatePreciousMetalParams = {
  buying_price: number;
  correlation_id: string;
  quantity: number;
};

export const useCreatePreciousMetal = () => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    API_ROUTES.precious_metals.post({ organizationId, membershipId }),
    async (url: string, { arg }: { arg: CreatePreciousMetalParams }) =>
      fetchApi<PreciousMetal>(url, {
        body: arg,
        method: 'POST',
        ...apiConfiguration,
      })
  );
};
