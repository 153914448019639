import { API_ROUTES } from '../../routes';
import { PublicInfoResponse } from '../../types';
import { usePublicApi } from '../useApi';

export const useSharingLinkPublicInfoById = (id?: string) => {
  const response = usePublicApi<PublicInfoResponse>(
    id ? API_ROUTES.sharing_links.byId(id).public_info() : null
  );

  return {
    ...response,
    displayCashflowFeature: id
      ? !!response?.data?.features?.find(
          (feature) => feature.name === 'cashflow'
        )
      : true,
  };
};
