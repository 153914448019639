import {
  Alternative,
  FeesAccount,
  SecurityAlternativesResponse,
} from '../types';

export const formatFeesAccountName = (feesAccount: FeesAccount) =>
  feesAccount.institution?.name
    ? `${feesAccount.name} | ${feesAccount.institution.name}`
    : feesAccount.name;

export const getUniqueAlternatives = (
  data: SecurityAlternativesResponse | undefined
) => {
  const relevantAlternatives = data?.relevant ?? [];

  const cheapAlternatives =
    data?.cheap?.filter(
      (alternative) =>
        !relevantAlternatives.find(
          (relevant) => relevant.isin === alternative.isin
        )
    ) ?? [];

  const popularAlternatives =
    data?.popular?.filter(
      (alternative) =>
        !relevantAlternatives.find(
          (relevant) => relevant.isin === alternative.isin
        ) &&
        !cheapAlternatives.find(
          (relevant) => relevant.isin === alternative.isin
        )
    ) ?? [];

  const finaryAlternatives = data?.finary?.filter(
    (alternative) =>
      !relevantAlternatives.find(
        (relevant) => relevant.isin === alternative.isin
      ) &&
      !cheapAlternatives.find(
        (relevant) => relevant.isin === alternative.isin
      ) &&
      !popularAlternatives.find(
        (relevant) => relevant.isin === alternative.isin
      )
  );

  return {
    relevantAlternatives,
    cheapAlternatives,
    popularAlternatives,
    finaryAlternatives: finaryAlternatives,
  };
};

export const getMaximumCumulatedPotentialSavings = (
  alternatives: Alternative[]
) => {
  if (alternatives.length) {
    return Math.max(
      ...alternatives.map((alternative) =>
        Number(alternative.cumulated_potential_savings)
      )
    );
  }

  return 0;
};
