import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import { ApiGetProps, Loan } from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { useApi } from '../../useApi';

export type UseLoansByIdProps = {
  loanId: number;
} & ApiGetProps;

export const useLoansById = ({
  loanId,
  sharingLinkId,
  accessCode,
}: UseLoansByIdProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });

  return useApi<Loan>(
    API_ROUTES.loans.byId(loanId).get({
      organizationId,
      membershipId,
      params,
    }),
    undefined,
    !!sharingLinkId
  );
};
