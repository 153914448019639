import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../config';
import { useApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import { User } from '../../types';

export type CreateFamilyMemberParams = {
  firstname: string;
  lastname?: string;
};

export const useCreateFamilyMember = () => {
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    API_ROUTES.family.members.post(),
    async (url: string, { arg }: { arg: CreateFamilyMemberParams }) =>
      fetchApi<User>(url, {
        method: 'POST',
        body: arg,
        ...apiConfiguration,
      })
  );
};
