import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../config';
import { useApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';

export const useDeleteSharingLink = (id?: string) => {
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    id ? API_ROUTES.sharing_links.byId(id).delete() : null,
    async (url: string) =>
      fetchApi<null>(url, {
        method: 'DELETE',
        ...apiConfiguration,
      })
  );
};
