import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../config';
import { useApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import { NotSharingLinkProps, SharingLinkProps, User } from '../../types';
import {
  createSharingLinkParams,
  isOnlyFreeAccessUser,
  isLiteAccessUser,
  isNewUser,
  isPlusAccessUser,
  isProAccessUser,
  isSubscriptionTrialUser,
  getUserPlanLevel,
} from '../../utils';
import { UseApiConfig, useApi } from '../useApi';

export type UseUserProps = (NotSharingLinkProps | SharingLinkProps) &
  UseApiConfig<User>;

export const useUser = ({
  sharingLinkId,
  accessCode,
  ...config
}: UseUserProps = {}) => {
  const apiConfiguration = useApiConfig();
  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  const apiResult = useApi<User>(
    API_ROUTES.users.get(params),
    config,
    !!sharingLinkId
  );

  const { trigger, isMutating } = useSWRMutation(
    API_ROUTES.users.put(),
    async (
      url: string,
      { arg: body }: { arg: Partial<User> & { godfather_referral_id?: string } }
    ) => {
      return apiResult.mutate(
        fetchApi<User>(url, {
          body,
          method: 'PUT',
          ...apiConfiguration,
        }),
        {
          optimisticData: apiResult.data
            ? {
                ...apiResult.data,
                ...body,
              }
            : undefined,
        }
      );
    }
  );

  return {
    ...apiResult,
    update: trigger,
    isMutating,
    /**
     * True if user has no subscription
     */
    hasOnlyFreeAccess: isOnlyFreeAccessUser(apiResult.data),
    /**
     * True if user has a subscription that is Lite, Plus or Pro
     */
    hasLiteAccess: isLiteAccessUser(apiResult.data),
    /**
     * True if user has a subscription that is Plus or Pro
     */
    hasPlusAccess: isPlusAccessUser(apiResult.data),
    /**
     * True if user has a subscription that is Pro
     */
    hasProAccess: isProAccessUser(apiResult.data),
    /**
     * User's subscription is in trial
     */
    isSubscriptionInTrial: isSubscriptionTrialUser(apiResult.data),
    /**
     * User hasn't finish the onboarding or has no assets
     */
    isNewUser: isNewUser(apiResult.data),
    /**
     * Get user exact plan level free|lite|plus|pro
     */
    planLevel: getUserPlanLevel(apiResult.data),
  };
};
