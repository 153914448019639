import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../../config';
import { useApiConfig, useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import { OtherAsset, WatchState } from '../../../types';

export type CreateOtherAssetParams = {
  asset_attributes?: {
    state?: WatchState;
    with_box?: boolean;
    with_certificate?: boolean;
  };
  buying_price?: number;
  category?: string;
  current_price?: number;
  name?: string;
  quantity?: number;
  watch?: {
    correlation_id: string;
  };
};

export const useCreateOtherAsset = () => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    API_ROUTES.other_assets.post({ organizationId, membershipId }),
    async (url: string, { arg }: { arg: CreateOtherAssetParams }) =>
      fetchApi<OtherAsset>(url, {
        body: arg,
        method: 'POST',
        ...apiConfiguration,
      })
  );
};
