import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import { RealEstatesFeesResponse, ApiGetProps } from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { useApi } from '../../useApi';

export const usePortfolioRealEstatesFees = ({
  skip = false,
  sharingLinkId,
  accessCode,
}: ApiGetProps) => {
  const { membershipId, organizationId } = useFinaryApiConfig();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });

  return useApi<RealEstatesFeesResponse>(
    API_ROUTES.portfolio.real_estates.fees.get({
      membershipId,
      organizationId,
      params,
    }),
    undefined,
    !!sharingLinkId,
    skip
  );
};
