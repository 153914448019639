import * as React from 'react';

import { FinaryPlus } from '../FinaryPlus';
import { Typography } from '../Typography';

import { Button, ButtonPolymorphicProps } from './Button';
import { buttonFinaryPlusTypographyStyles } from './ButtonFinaryPlus.css';

export const ButtonFinaryPlus = <C extends React.ElementType = 'button'>({
  as,
  children,
  ...props
}: ButtonPolymorphicProps<C>) => {
  const Component: React.ElementType = as || 'button';
  return (
    <Button<typeof Component>
      as={Component}
      variant="plus"
      size="small"
      {...props}
    >
      <FinaryPlus />
      <Typography
        variant="bodySmallEmphasis"
        className={buttonFinaryPlusTypographyStyles}
      >
        {children}
      </Typography>
    </Button>
  );
};
