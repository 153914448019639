import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../config';
import { useApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';

export const useDeleteUser = () => {
  const apiConfiguration = useApiConfig();

  return useSWRMutation(API_ROUTES.users.delete(), async (url: string) =>
    fetchApi<void>(url, {
      method: 'DELETE',
      ...apiConfiguration,
    })
  );
};
