import { API_ROUTES } from '../routes';
import { CompanyMembership, UserMembership, Organization } from '../types';

import { useApi } from './useApi';

export const useOrganization = () => {
  const {
    data: organizations,
    mutate,
    isLoading,
    isInitialLoading,
  } = useApi<Organization[]>(API_ROUTES.organizations.get());

  const organization = organizations?.[0];

  const hasMoreThanOneFamilyMember =
    (organization?.memberships.length ?? 0) > 1;

  const companiesMemberships = (organization?.memberships?.filter(
    (membership) => membership.member_type === 'Company'
  ) ?? []) as CompanyMembership[];
  const usersMemberships = (organization?.memberships?.filter(
    (membership) => membership.member_type === 'User'
  ) ?? []) as UserMembership[];

  const data: Organization = {
    ...organization,
    memberships: [...usersMemberships, ...companiesMemberships],
  } as Organization;

  return {
    data,
    companiesMemberships,
    usersMemberships,
    mutate,
    isLoading,
    isInitialLoading,
    hasMoreThanOneFamilyMember,
  };
};
