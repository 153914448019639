import React from 'react';

import {
  Toast,
  ToastContentProps,
  ToastProvider,
  ToastVariant,
  ToastViewport,
} from './Toast';

const ToasterContext = React.createContext<
  | {
      error: (toast: ToastMethodArgs) => void;
      info: (toast: ToastMethodArgs) => void;
      success: (toast: ToastMethodArgs) => void;
      warning: (toast: ToastMethodArgs) => void;
    }
  | undefined
>(undefined);

type ToastMethodArgs = Omit<ToastContentProps, 'variant'>;

export const ToasterProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [toasts, setToasts] = React.useState<
    (ToastContentProps & { id: string })[]
  >([]);

  const toast = React.useMemo(() => {
    const toastFactory = (variant: ToastVariant) => (toast: ToastMethodArgs) =>
      setToasts((prev) => [
        ...prev,
        { ...toast, id: crypto.randomUUID(), variant },
      ]);

    return {
      info: toastFactory('informational'),
      error: toastFactory('danger'),
      success: toastFactory('success'),
      warning: toastFactory('warning'),
    };
  }, []);

  return (
    <ToasterContext.Provider value={toast}>
      {children}
      <ToastProvider>
        {toasts.map(({ id, ...toast }) => (
          <Toast key={id} {...toast} />
        ))}
        <ToastViewport />
      </ToastProvider>
    </ToasterContext.Provider>
  );
};

export const useToast = () => {
  const context = React.useContext(ToasterContext);

  if (context === undefined) {
    throw new Error('useToast must be used within a ToasterProvider');
  }

  return context;
};
