import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../config';
import { useApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import { HoldingsAccount } from '../../types';

export const useCreateHoldingsAccountFromImport = () => {
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    API_ROUTES.holdingsAccounts.import.post(),
    async (url: string, { arg }: { arg: FormData }) =>
      fetchApi<HoldingsAccount>(url, {
        method: 'POST',
        body: arg,
        ...apiConfiguration,
      })
  );
};
