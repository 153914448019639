export const isNullish = (value: unknown): value is null | undefined =>
  value === undefined || value === null;

export const isDefined = <T>(
  value: T | (null | undefined)
): value is NonNullable<T> => {
  if (typeof value === 'number') {
    return !isNullish(value) && !Number.isNaN(value);
  }

  return !isNullish(value);
};

export const isValidDate = (
  date: string | Date | null | undefined
): date is Date => {
  if (date instanceof Date) {
    return !isNaN(date.getTime());
  }

  if (typeof date === 'string') {
    const dateToTest = new Date(date);
    return !isNaN(dateToTest.getTime());
  }

  return false;
};
