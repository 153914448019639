import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useRouter } from 'next/router';
import * as React from 'react';

export type HistoryContextType = string[];

export const firstVisitedPageAtom = atomWithStorage<string | undefined>(
  'firstVisitedPage',
  undefined
);

export const HistoryContext = React.createContext<HistoryContextType>([]);

const history: string[] = [];

export const HistoryProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { asPath } = useRouter();
  const [firstVisitedPage, setFirstVisitedPage] = useAtom(firstVisitedPageAtom);

  // We don't use a hook for the path history, nor the router events,
  // so we have the value available at first mount, instead of having
  // to wait for an effect or an event.
  // Server-side, the history will only contain the current path
  const isClient = typeof document !== 'undefined';
  let contextValue = [asPath];

  if (isClient) {
    const last = history.slice(-1)[0];
    const changed = last !== asPath;
    if (changed) {
      history.push(asPath);
      contextValue = [...history];
    } else {
      contextValue = history;
    }
    if (!firstVisitedPage) {
      setFirstVisitedPage(asPath);
    }
  }

  return (
    <HistoryContext.Provider value={contextValue}>
      {children}
    </HistoryContext.Provider>
  );
};
