import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../../config';
import { useApiConfig, useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import { FondsEuro, InstitutionWithProviders } from '../../../types';

export type CreateFondsEuroInstitutionBody =
  | InstitutionWithProviders
  | Pick<InstitutionWithProviders, 'name'>;

export type CreateFondsEuroParams = {
  annual_yield: number;
  bank: CreateFondsEuroInstitutionBody;
  buying_price: number;
  current_price: number;
  name: string;
};

export const useCreateFondsEuro = () => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    API_ROUTES.fonds_euro.post({ organizationId, membershipId }),
    async (url: string, { arg }: { arg: CreateFondsEuroParams }) =>
      fetchApi<FondsEuro>(url, {
        body: arg,
        method: 'POST',
        ...apiConfiguration,
      })
  );
};
