import { atomWithStorage } from 'jotai/utils';

export const organizationIdAtom = atomWithStorage<string | null>(
  'organizationId',
  null
);

export const membershipIdAtom = atomWithStorage<string | null>(
  'membershipId',
  null
);
