import { API_ROUTES } from '../../../routes';
import { Watch } from '../../../types';
import { useApi } from '../../useApi';

export const useWatchesAutocomplete = (query: string) => {
  const params = new URLSearchParams();

  if (query) {
    params.append('query', query);
  }

  return useApi<Watch[]>(
    query ? API_ROUTES.watches.autocomplete.get(params) : null,
    {
      keepPreviousData: true,
    }
  );
};
