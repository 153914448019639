import * as React from 'react';

import { theme } from '../../styles/theme.css';

import { loaderPathStyles } from './Loader.css';

export type LoaderProps = {
  className?: string;
  style?: React.CSSProperties;
};

export const Loader = ({ className, style }: LoaderProps) => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <defs>
        <linearGradient id="main-gradient" x1="0" y1="0" x2="1" y2="0">
          <stop offset="0" stopColor="#5682f2" stopOpacity="1" />
          <stop
            offset="1"
            stopColor={theme.color.palette.brand400}
            stopOpacity="1"
          />
        </linearGradient>
      </defs>
      <path
        className={loaderPathStyles}
        d="M 22 6 H 10 C 6.5 6 4 8.5 4 12 H 16 C 19.5 12 22 9.5 22 6 Z"
        stroke="url('#main-gradient')"
        strokeWidth="0.25"
      />
      <path
        className={loaderPathStyles}
        d="M 9.5 13.5 H 15.5 C 15.5 16.5 13 19.5 10 19.5 H 4 C 4 16.5 6.5 13.5 9.5 13.5 Z"
        stroke="url('#main-gradient')"
        strokeWidth="0.25"
      />
    </svg>
  );
};
