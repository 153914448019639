import { API_ROUTES } from '../../routes';
import { useApi } from '../useApi';

export type SyncStatusResponse = {
  bank_connections: boolean;
  checking_accounts: boolean;
  credit_accounts: boolean;
  cryptos: boolean;
  insta_sync: boolean;
  investment_accounts: boolean;
  real_estates: boolean;
  savings_accounts: boolean;
};

export const useSyncStatus = () => {
  return useApi<SyncStatusResponse>(API_ROUTES.sync.status.get(), {
    refreshInterval: 5 * 1000,
  });
};
