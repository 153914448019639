export const ReviewsIcon = () => {
  return (
    <svg
      width="76"
      height="16"
      viewBox="0 0 76 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8823_8922)">
        <path
          d="M10.4297 6.03906L13.7812 6.53125C14.0625 6.57812 14.2969 6.76562 14.3906 7.04688C14.4844 7.30469 14.4141 7.60938 14.2031 7.79688L11.7656 10.2109L12.3516 13.6328C12.3984 13.9141 12.2812 14.1953 12.0469 14.3594C11.8125 14.5469 11.5078 14.5469 11.25 14.4297L8.25 12.8125L5.22656 14.4297C4.99219 14.5469 4.66406 14.5469 4.45312 14.3594C4.21875 14.1953 4.10156 13.9141 4.14844 13.6328L4.71094 10.2109L2.27344 7.79688C2.0625 7.60938 1.99219 7.30469 2.08594 7.04688C2.17969 6.76562 2.41406 6.57812 2.69531 6.53125L6.07031 6.03906L7.57031 2.92188C7.6875 2.66406 7.94531 2.5 8.25 2.5C8.53125 2.5 8.78906 2.66406 8.90625 2.92188L10.4297 6.03906Z"
          fill="#F1C086"
        />
        <path
          d="M26.4297 6.03906L29.7812 6.53125C30.0625 6.57812 30.2969 6.76562 30.3906 7.04688C30.4844 7.30469 30.4141 7.60938 30.2031 7.79688L27.7656 10.2109L28.3516 13.6328C28.3984 13.9141 28.2812 14.1953 28.0469 14.3594C27.8125 14.5469 27.5078 14.5469 27.25 14.4297L24.25 12.8125L21.2266 14.4297C20.9922 14.5469 20.6641 14.5469 20.4531 14.3594C20.2188 14.1953 20.1016 13.9141 20.1484 13.6328L20.7109 10.2109L18.2734 7.79688C18.0625 7.60938 17.9922 7.30469 18.0859 7.04688C18.1797 6.76562 18.4141 6.57812 18.6953 6.53125L22.0703 6.03906L23.5703 2.92188C23.6875 2.66406 23.9453 2.5 24.25 2.5C24.5312 2.5 24.7891 2.66406 24.9062 2.92188L26.4297 6.03906Z"
          fill="#F1C086"
        />
        <path
          d="M42.4297 6.03906L45.7812 6.53125C46.0625 6.57812 46.2969 6.76562 46.3906 7.04688C46.4844 7.30469 46.4141 7.60938 46.2031 7.79688L43.7656 10.2109L44.3516 13.6328C44.3984 13.9141 44.2812 14.1953 44.0469 14.3594C43.8125 14.5469 43.5078 14.5469 43.25 14.4297L40.25 12.8125L37.2266 14.4297C36.9922 14.5469 36.6641 14.5469 36.4531 14.3594C36.2188 14.1953 36.1016 13.9141 36.1484 13.6328L36.7109 10.2109L34.2734 7.79688C34.0625 7.60938 33.9922 7.30469 34.0859 7.04688C34.1797 6.76562 34.4141 6.57812 34.6953 6.53125L38.0703 6.03906L39.5703 2.92188C39.6875 2.66406 39.9453 2.5 40.25 2.5C40.5312 2.5 40.7891 2.66406 40.9062 2.92188L42.4297 6.03906Z"
          fill="#F1C086"
        />
        <path
          d="M58.4297 6.03906L61.7812 6.53125C62.0625 6.57812 62.2969 6.76562 62.3906 7.04688C62.4844 7.30469 62.4141 7.60938 62.2031 7.79688L59.7656 10.2109L60.3516 13.6328C60.3984 13.9141 60.2812 14.1953 60.0469 14.3594C59.8125 14.5469 59.5078 14.5469 59.25 14.4297L56.25 12.8125L53.2266 14.4297C52.9922 14.5469 52.6641 14.5469 52.4531 14.3594C52.2188 14.1953 52.1016 13.9141 52.1484 13.6328L52.7109 10.2109L50.2734 7.79688C50.0625 7.60938 49.9922 7.30469 50.0859 7.04688C50.1797 6.76562 50.4141 6.57812 50.6953 6.53125L54.0703 6.03906L55.5703 2.92188C55.6875 2.66406 55.9453 2.5 56.25 2.5C56.5312 2.5 56.7891 2.66406 56.9062 2.92188L58.4297 6.03906Z"
          fill="#F1C086"
        />
        <path
          d="M74.4297 6.03906L77.7812 6.53125C78.0625 6.57812 78.2969 6.76562 78.3906 7.04688C78.4844 7.30469 78.4141 7.60938 78.2031 7.79688L75.7656 10.2109L76.3516 13.6328C76.3984 13.9141 76.2812 14.1953 76.0469 14.3594C75.8125 14.5469 75.5078 14.5469 75.25 14.4297L72.25 12.8125L69.2266 14.4297C68.9922 14.5469 68.6641 14.5469 68.4531 14.3594C68.2188 14.1953 68.1016 13.9141 68.1484 13.6328L68.7109 10.2109L66.2734 7.79688C66.0625 7.60938 65.9922 7.30469 66.0859 7.04688C66.1797 6.76562 66.4141 6.57812 66.6953 6.53125L70.0703 6.03906L71.5703 2.92188C71.6875 2.66406 71.9453 2.5 72.25 2.5C72.5312 2.5 72.7891 2.66406 72.9062 2.92188L74.4297 6.03906Z"
          fill="#F1C086"
        />
      </g>
      <defs>
        <clipPath id="clip0_8823_8922">
          <rect
            width="75"
            height="16"
            fill="white"
            transform="translate(0.25)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
