import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  BankAccountSummary,
  NotSharingLinkProps,
  SharingLinkProps,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { usePeriodDisplayMode } from '../../configuration';
import { useApi } from '../../useApi';

export type UsePortfolioSavingsProps = NotSharingLinkProps | SharingLinkProps;

export const usePortfolioSavings = ({
  sharingLinkId,
  accessCode,
}: UsePortfolioSavingsProps = {}) => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const { data: periodDisplayMode } = usePeriodDisplayMode(!!sharingLinkId);

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('period', periodDisplayMode);

  return useApi<BankAccountSummary>(
    API_ROUTES.portfolio.savings_accounts.get({
      organizationId,
      membershipId,
      params,
    }),
    undefined,
    !!sharingLinkId
  );
};
