import { API_ROUTES } from '../../../routes';
import { usePostApi } from '../../useApi';

export type PowensTemporaryCodeResponse = {
  access: string;
  code: string;
  expires_in: number;
  type: string;
};

export const usePowensGetTemporaryCode = () => {
  return usePostApi<PowensTemporaryCodeResponse>(
    API_ROUTES.powens.temporaryCode.post()
  );
};
