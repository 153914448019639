import { useFinaryApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import {
  AssetCategory,
  NotSharingLinkProps,
  Portfolio,
  SharingLinkProps,
} from '../../types';
import { createSharingLinkParams } from '../../utils';
import { usePeriodDisplayMode } from '../configuration';
import { useApi } from '../useApi';

export type UsePortfolioBaseProps = {
  categories?: AssetCategory[];
};

export type UsePortfolioWithoutSharingProps = UsePortfolioBaseProps &
  NotSharingLinkProps;

export type UsePortfolioWithSharingProps = UsePortfolioBaseProps &
  SharingLinkProps;

export type UsePortfolioProps =
  | UsePortfolioWithoutSharingProps
  | UsePortfolioWithSharingProps;

export const usePortfolio = ({
  categories,
  sharingLinkId,
  accessCode,
}: UsePortfolioProps = {}) => {
  const { membershipId, organizationId } = useFinaryApiConfig();
  const { data: periodDisplayMode } = usePeriodDisplayMode();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('new_format', 'true');
  params.append('period', periodDisplayMode);

  if (categories && categories.length) {
    params.append('categories', categories.join(','));
  }

  return useApi<Portfolio>(
    API_ROUTES.portfolio.get({
      membershipId,
      organizationId,
      params,
    }),
    { keepPreviousData: true },
    !!sharingLinkId
  );
};
