import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../../config';
import { useApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import { OtherAsset, OtherAssetData } from '../../../types';

export const useEditOtherAsset = (assetId?: string) => {
  const apiConfiguration = useApiConfig();
  const { organizationId, membershipId } = apiConfiguration;

  return useSWRMutation(
    assetId
      ? API_ROUTES.other_assets.byId(assetId).put({
          organizationId,
          membershipId,
        })
      : null,
    async (url: string, { arg: body }: { arg: OtherAssetData }) =>
      fetchApi<OtherAsset>(url, {
        body,
        method: 'PUT',
        ...apiConfiguration,
      })
  );
};
