import { NumberBreakdown, Timeseries } from './finary';

export type AssetListItem = {
  account_id: string;
  asset_id: string;
  asset_type: AssetType;

  category_name: Exclude<
    AssetCategory,
    'savings_accounts' | 'checking_accounts'
  >;
  current_value: number;
  logo_url: string | null;
  name: string;
  sparkline: Timeseries;
  symbol: string | null;
  display_upnl_difference?: number | null;
  display_upnl_percent?: number | null;
};

export type AssetType =
  | 'UserFiat'
  | 'UserSecurity'
  | 'UserCrypto'
  | 'UserFondEuro'
  | 'UserStartup'
  | 'UserPreciousMetal'
  | 'UserScpi'
  | 'GenericAsset'
  | 'RealEstate'
  | 'Loan'
  | 'UserCrowdlending';

export type Liability = 'credit_accounts' | 'loans';

export type LiabilityNewFormat = 'credits' | 'loans';

// TODO: Check category when @Hugo update it in backend
//       investments / investment_accounts
//       savings / savings_accounts
//       checkings / checking_accounts
//       credits / credit_accounts
//       commodities / precious_metals
export type Asset =
  | 'checking_accounts'
  | 'commodities'
  | 'crowdlendings'
  | 'cryptos'
  | 'fonds_euro'
  | 'investment_accounts'
  | 'other_assets'
  | 'real_estates'
  | 'savings_accounts'
  | 'startups';

export type AssetNewFormat =
  | 'investments'
  | 'savings'
  | 'checkings'
  | 'commodities'
  | 'crowdlendings'
  | 'cryptos'
  | 'fonds_euro'
  | 'credits'
  | 'other_assets'
  | 'real_estates'
  | 'startups';

export type AssetCategory = Liability | Asset;

export type AssetCategoryNewFormat = LiabilityNewFormat | AssetNewFormat;

export enum AssetOrder {
  perfHighToLow = 'perf_high_low',
  perfLowToHigh = 'perf_low_high',
  perfPercentHighToLow = 'perf_percent_high_low',
  perfPercentLowToHigh = 'perf_percent_low_high',
  valueHighToLow = 'value_high_low',
  valueLowToHigh = 'value_low_high',
}

export type DistributionByType = {
  assets: {
    [key in Asset]: NumberBreakdown;
  };
  liabilities: {
    [key in Liability]: NumberBreakdown;
  };
  total: Omit<NumberBreakdown, 'ownership_repartition'>;
};
