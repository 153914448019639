import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../config';
import { useApiConfig, useFinaryApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import { HoldingsAccount } from '../../types';

export const useUpdateHoldingsAccountFromImport = (id: string | null) => {
  const apiConfiguration = useApiConfig();
  const { organizationId, membershipId } = useFinaryApiConfig();

  return useSWRMutation(
    id
      ? API_ROUTES.holdingsAccounts.import
          .byId(id)
          .post({ organizationId, membershipId })
      : null,
    async (url: string, { arg }: { arg: FormData }) =>
      fetchApi<HoldingsAccount>(url, {
        method: 'POST',
        body: arg,
        ...apiConfiguration,
      })
  );
};
