import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  NotSharingLinkProps,
  PortfolioTimeseries,
  SharingLinkProps,
  TimeseriesType,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { usePeriodDisplayMode } from '../../configuration';
import { useApi } from '../../useApi';

export type UsePortfolioFondsEuroTimeseriesBaseProps = {
  type?: TimeseriesType;
};

export type UsePortfolioFondsEuroTimeseriesWithoutSharingProps =
  UsePortfolioFondsEuroTimeseriesBaseProps & NotSharingLinkProps;

export type UsePortfolioFondsEuroTimeseriesWithSharingProps =
  UsePortfolioFondsEuroTimeseriesBaseProps & SharingLinkProps;

export type UsePortfolioFondsEuroTimeseriesProps =
  | UsePortfolioFondsEuroTimeseriesWithoutSharingProps
  | UsePortfolioFondsEuroTimeseriesWithSharingProps;

export const usePortfolioFondsEuroTimeseries = ({
  type = 'sum',
  sharingLinkId,
  accessCode,
}: UsePortfolioFondsEuroTimeseriesProps) => {
  const { membershipId, organizationId } = useFinaryApiConfig();
  const { data: periodDisplayMode } = usePeriodDisplayMode(!!sharingLinkId);

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('timeseries_type', type);
  params.append('period', periodDisplayMode);

  return useApi<PortfolioTimeseries>(
    API_ROUTES.portfolio.fonds_euro.timeseries.get({
      membershipId,
      organizationId,
      params,
    }),
    { keepPreviousData: true },
    !!sharingLinkId
  );
};
