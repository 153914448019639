import { logoLettersStyles } from '/components/layout/FinaryLogo.css';

import { SVGProps } from 'react';

export type FinaryLogoProps = {
  responsive?: boolean;
} & SVGProps<SVGSVGElement>;

export const FinaryLogo = ({ responsive, ...props }: FinaryLogoProps) => {
  return (
    <svg
      width="88"
      height="24"
      viewBox="0 0 88 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g className={responsive ? logoLettersStyles : undefined}>
        <path
          d="M29.733 0C30.1406 0 30.4606 0.00260287 30.8003 0.0535714V2.52553C30.4946 2.49155 30.2765 2.49744 30.0387 2.49744C29.3084 2.49744 28.714 2.71831 28.2554 3.16003C27.8139 3.60175 27.5931 4.20488 27.5931 4.9694V6.42857H30.8003V8.95362H27.5931V19.9286H24.7398V8.95362H22.3223V6.42857H24.7398V5.04585C24.7398 3.48283 25.1898 2.2511 26.09 1.35066C26.9901 0.450219 28.2045 0 29.733 0Z"
          fill="#F1C086"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.0879 2.35712V5.14283H34.8781V2.35712H32.0879ZM32.0879 19.9285V6.42854H34.8781V19.9285H32.0879Z"
          fill="#F1C086"
        />
        <path
          d="M43.5785 6.21429C40.6341 6.21429 39.2395 8.49138 38.9554 9.26766V6.42858H36.166V19.9286H38.9554V11.8811C39.0329 11.1825 39.4203 10.458 40.0659 9.75931C40.7375 9.03478 41.5898 8.67251 42.6229 8.67251C44.4824 8.67251 45.8254 9.88869 45.8254 12.2952V19.9286H48.6148V12.1399C48.6148 10.1992 48.1241 8.72427 47.1426 7.7151C46.1612 6.70594 44.9731 6.21429 43.5785 6.21429Z"
          fill="#F1C086"
        />
        <path
          d="M56.4052 19.9286C58.4972 19.9286 60.1607 18.7603 60.7656 17.5159H60.9168V19.9286H63.6389V6.42858H60.9168V8.62699H60.7656C60.1607 7.38255 58.4972 6.21429 56.4052 6.21429C54.8929 6.21429 53.3302 6.79842 52.0447 7.89048C50.7593 8.98255 49.9023 10.8619 49.9023 13.0714C49.9023 15.281 50.7593 17.1603 52.0447 18.2524C53.3302 19.3445 54.8929 19.9286 56.4052 19.9286ZM56.758 17.4905C55.649 17.4905 54.666 17.1095 53.8091 16.3222C52.9773 15.5349 52.5488 14.4683 52.5488 13.0714C52.5488 11.6746 52.9773 10.6079 53.8091 9.82064C54.666 9.03334 55.649 8.65239 56.758 8.65239C57.8922 8.65239 58.8752 9.03334 59.707 9.82064C60.5639 10.6079 60.9924 11.6746 60.9924 13.0714C60.9924 14.4683 60.5639 15.5349 59.707 16.3222C58.8752 17.1095 57.8922 17.4905 56.758 17.4905Z"
          fill="#F1C086"
        />
        <path
          d="M72.5996 6.21429C70.181 6.21429 68.3346 7.77274 67.7364 9.87663V6.42209H64.9277V19.9286H67.7364V13.5649C67.8924 12.1623 68.4386 11.0455 69.3488 10.1883C70.259 9.33118 71.3773 8.88962 72.6776 8.88962C72.9897 8.88962 73.2237 8.91559 73.4058 8.94157V6.26624C73.2237 6.24027 72.9637 6.21429 72.5996 6.21429Z"
          fill="#F1C086"
        />
        <path
          d="M81.132 15.9088L77.036 6.21429H74.2637L79.7833 18.8897L77.4606 24H80.1579L82.3308 19.2404L88.0003 6.21429H85.228L81.132 15.9088Z"
          fill="#F1C086"
        />
      </g>
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 6.42859C2.68629 6.42859 0 9.11488 0 12.4286H12.0293C15.343 12.4286 18.0293 9.7423 18.0293 6.42859H6ZM6 13.9286C2.68629 13.9286 0 16.6149 0 19.9286H5.59024C8.90395 19.9286 11.5902 17.2423 11.5902 13.9286H6Z"
          fill="#F1C086"
        />
      </g>
    </svg>
  );
};
