import { NotSharingLinkProps, SharingLinkProps } from '../../types';

import { useUIConfiguration } from './useUIConfiguration';

export type UseSecretModeProps = NotSharingLinkProps | SharingLinkProps;

export const useSecretMode = ({
  sharingLinkId,
  accessCode,
}: UseSecretModeProps) => {
  const { data: configuration, update: updateUiConfiguration } =
    useUIConfiguration({ sharingLinkId, accessCode });

  const currentSecretMode = configuration.secret_mode;

  const update = (secretMode: boolean) =>
    updateUiConfiguration({ secret_mode: secretMode });

  const toggle = () => {
    update(!currentSecretMode);
  };

  return {
    update,
    toggle,
    isSecretMode: currentSecretMode,
  };
};
