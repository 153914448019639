import { RealEstate, RealEstatesMarketComparison, SCPIAccount } from '../types';

export const isSCPI = (asset: SCPIAccount | RealEstate): asset is SCPIAccount =>
  'scpi' in asset;

export const getRealEstateMarketComparison = (
  realEstate: RealEstate | undefined
): RealEstatesMarketComparison => {
  const {
    display_surface_unit_current_price,
    display_surface_unit_current_price_min,
    display_surface_unit_current_price_avg,
    display_surface_unit_current_price_max,
    price_hubble_confidence,
  } = realEstate || {};

  const isPriceHubbleEstimable =
    !!price_hubble_confidence &&
    !!display_surface_unit_current_price_min &&
    !!display_surface_unit_current_price_avg &&
    !!display_surface_unit_current_price_max;

  if (!isPriceHubbleEstimable) {
    return 'unavailable';
  }

  if (
    display_surface_unit_current_price ===
    display_surface_unit_current_price_avg
  ) {
    return 'neutral';
  }

  if (
    (display_surface_unit_current_price ?? 0) >
    (display_surface_unit_current_price_avg ?? 0)
  ) {
    return 'above';
  }

  return 'below';
};

export const getRealEstateCurrentValuation = ({
  current_price,
  current_value,
  surface,
}: Pick<RealEstate, 'current_price' | 'current_value' | 'surface'>) =>
  current_price ? current_price * surface : current_value;
