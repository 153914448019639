import { mutate } from 'swr';

import { useFinaryApiConfig } from '../providers';
import { OrganizationRouteParams } from '../routes';

type GetRoute = (params: OrganizationRouteParams) => string | null;

type UseMutateOrganizationRouteByKey = (routeConfig: GetRoute | GetRoute[]) => {
  mutate: () => Promise<undefined[]>;
};

export const useMutateOrganizationRouteByKey: UseMutateOrganizationRouteByKey =
  (routeConfig) => {
    const { membershipId, organizationId } = useFinaryApiConfig();
    const matchers = Array.isArray(routeConfig)
      ? routeConfig.map((route) =>
          route({
            membershipId,
            organizationId,
          })
        )
      : routeConfig({
          membershipId,
          organizationId,
        });

    return {
      mutate: () =>
        mutate(
          (key) => {
            if (typeof matchers === 'string') {
              return typeof key === 'string' && key.includes(matchers);
            }

            return (
              typeof key === 'string' &&
              (matchers ?? []).some((substr) => key.startsWith(substr ?? ''))
            );
          },
          undefined,
          { revalidate: true }
        ),
    };
  };
