import useSWRMutation from 'swr/mutation';
import { CountryCode } from 'utils';

import { fetchApi } from '../../config';
import { useApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import { CompanyType, User } from '../../types';

export type UpdateCompanyParams = {
  country: CountryCode;
  name: string;
  company_type?: CompanyType;
  identification_number?: string;
  shareholders?: {
    id: string;
    membership_id: string;
    share: number;
  }[];
  tax_regime?: string;
};

export const useUpdateCompany = (id?: string) => {
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    id ? API_ROUTES.companies.byId(id).put() : null,
    async (url: string, { arg }: { arg: UpdateCompanyParams }) =>
      fetchApi<User>(url, {
        method: 'PUT',
        body: arg,
        ...apiConfiguration,
      })
  );
};
