import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  AllocationResponse,
  NotSharingLinkProps,
  SharingLinkProps,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { useApi } from '../../useApi';

export type UsePortfolioInvestmentsByAccountSectorAllocationBaseProps = {
  accountId: string;
};

export type UsePortfolioInvestmentsByAccountSectorAllocationWithoutSharingProps =
  UsePortfolioInvestmentsByAccountSectorAllocationBaseProps &
    NotSharingLinkProps;

export type UsePortfolioInvestmentsByAccountSectorAllocationWithSharingProps =
  UsePortfolioInvestmentsByAccountSectorAllocationBaseProps & SharingLinkProps;

export type UsePortfolioInvestmentsByAccountSectorAllocationProps =
  | UsePortfolioInvestmentsByAccountSectorAllocationWithoutSharingProps
  | UsePortfolioInvestmentsByAccountSectorAllocationWithSharingProps;

export const usePortfolioInvestmentsByAccountSectorAllocation = ({
  accountId,
  sharingLinkId,
  accessCode,
}: UsePortfolioInvestmentsByAccountSectorAllocationProps) => {
  const { membershipId, organizationId } = useFinaryApiConfig();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });

  return useApi<AllocationResponse>(
    API_ROUTES.portfolio.investments
      .accountById(accountId)
      .sectorAllocation.get({
        membershipId,
        organizationId,
        params,
      }),
    {
      keepPreviousData: true,
    },
    !!sharingLinkId
  );
};
