import { useSession } from '@clerk/nextjs';
import { ApiProvider as CoreApiProvider } from 'core-api/providers/ApiProvider';
import { Platform } from 'core-api/types';
import * as React from 'react';

import { useOrganizationState } from '/hooks/useOrganizationState';

export const FINARY_API = process.env.NEXT_PUBLIC_API_FINARY_URL as string;

type ApiProviderProps = {
  children?: React.ReactNode;
};

export const ApiProvider = ({ children }: ApiProviderProps) => {
  const { session, isLoaded } = useSession();
  const { membershipId, organizationId } = useOrganizationState();

  return isLoaded ? (
    <CoreApiProvider
      platform={Platform.Web}
      getToken={session?.getToken}
      api={FINARY_API}
      membershipId={membershipId}
      organizationId={organizationId}
    >
      {children}
    </CoreApiProvider>
  ) : null;
};
