import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../../config';
import { useApiConfig, useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import { UpdateSecurityBody, UserSecurity } from '../../../types';

export const useEditSecurity = (id?: number) => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    id
      ? API_ROUTES.securities.byId(id).put({ organizationId, membershipId })
      : null,
    async (url: string, { arg }: { arg: UpdateSecurityBody }) =>
      fetchApi<UserSecurity>(url, {
        body: arg,
        method: 'PUT',
        ...apiConfiguration,
      })
  );
};
