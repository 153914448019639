import { useTheme } from 'next-themes';

/**
 * Utility hook to use the next-themes hook and have correct typing for the theme
 */
export const useNextTheme = () => {
  const result = useTheme();

  const resolvedTheme = result.resolvedTheme as 'light' | 'dark' | undefined;
  const theme = result.theme as 'light' | 'dark' | 'system' | undefined;

  const isDark = resolvedTheme === 'dark';

  /**
   * Utility function to get the correct asset path based on the current theme.
   * We append _light to the file name for light theme assets
   */
  const getThemeAsset = (path: string) => {
    if (isDark) {
      return path;
    }

    const pathComponents = path.split('.');
    const extension = pathComponents.pop();

    const adjustedPath = `${pathComponents.join('.')}_light.${extension}`;

    return adjustedPath;
  };

  return {
    ...result,
    theme,
    isDark,
    resolvedTheme,
    getThemeAsset,
  };
};
