import { API_ROUTES } from '../routes';
import { SubscriptionDetails } from '../types';

import { useApi } from './useApi';

export const useSubscriptionDetails = () => {
  return useApi<SubscriptionDetails>(API_ROUTES.users.subscription.getAll(), {
    keepPreviousData: true,
  });
};
