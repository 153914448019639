import { fetchApi } from '../../config';
import { useApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import { FinaryConnectConnection } from '../../types';

/** This hook doesn't use swr because we pass a dynamic id to the fetch call */
export const useFetchFinaryConnectConnection = () => {
  const apiConfiguration = useApiConfig();

  return (id: string) =>
    fetchApi<FinaryConnectConnection>(
      API_ROUTES.connect.connections.byId(id).get(),
      {
        method: 'GET',
        ...apiConfiguration,
      }
    );
};
