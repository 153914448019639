import Head from 'next/head';

export const PageOpenGraph = ({ imageSrc }: { imageSrc: string }) => {
  return (
    <Head>
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Finary" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image" content={imageSrc} />
      <meta name="twitter:site" content="@finaryhq" />
      <meta name="twitter:creator" content="@finaryhq" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={imageSrc} />
    </Head>
  );
};
