import { API_ROUTES } from '../../routes';
import { Currency, SecurityType } from '../../types';
import { useApi } from '../useApi';

export type AutocompleteSecurity = {
  correlation_id: string;
  currency: Currency;
  external_logo_url: string;
  name: string;
  symbol: string;
  type: SecurityType;
};

export const useSecuritiesAutocomplete = (query: string) => {
  const params = new URLSearchParams();

  if (query) {
    params.append('query', query);
  }

  return useApi<AutocompleteSecurity[]>(
    query ? API_ROUTES.securities.autocomplete.get(params) : null,
    {
      keepPreviousData: true,
    }
  );
};
