import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../config';
import { useApiConfig, useFinaryApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import { Security } from '../../types';

export type CreateSecurityParams = {
  buying_price: number;
  correlation_id: string;
  quantity: number;
  security_currency: boolean;
  holdings_account?: {
    id: string;
  };
};

export const useCreateSecurity = () => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    API_ROUTES.securities.post({ organizationId, membershipId }),
    async (url: string, { arg }: { arg: CreateSecurityParams }) =>
      fetchApi<Security>(url, {
        body: arg,
        method: 'POST',
        ...apiConfiguration,
      })
  );
};
