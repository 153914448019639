import { useFinaryApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import {
  AssetLeaderBoardResult,
  AssetLeaderBoardType,
  LeaderboardMode,
  NotSharingLinkProps,
  SharingLinkProps,
} from '../../types';
import { createSharingLinkParams } from '../../utils';
import { useApi } from '../useApi';

export type UseAssetLeaderboardBaseProps = {
  type: AssetLeaderBoardType;
  mode?: LeaderboardMode;
};

export type UseAssetLeaderboardWithoutSharingProps =
  UseAssetLeaderboardBaseProps & NotSharingLinkProps;

export type UseAssetLeaderboardWithSharingProps = UseAssetLeaderboardBaseProps &
  SharingLinkProps;

export type UseAssetLeaderboardProps =
  | UseAssetLeaderboardWithoutSharingProps
  | UseAssetLeaderboardWithSharingProps;

export const useAssetLeaderboard = ({
  type,
  mode,
  sharingLinkId,
  accessCode,
}: UseAssetLeaderboardProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });

  params.append('type', type);
  if (!!mode) {
    params.append('mode', mode);
  }

  return useApi<AssetLeaderBoardResult>(
    API_ROUTES.portfolio.asset_leaderboard.get({
      organizationId,
      membershipId,
      params,
    }),
    { keepPreviousData: true },
    !!sharingLinkId
  );
};
