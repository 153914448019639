import classNames from 'classnames';
import * as React from 'react';

import { sprinkles, Sprinkles } from '../../styles/sprinkles.css';
import { getSprinklesProps } from '../../utils/sprinkles';
import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from '../Polymorphic';

export type BoxProps<C extends React.ElementType = 'div'> =
  PolymorphicComponentPropWithRef<C, Sprinkles>;

type BoxComponent = <C extends React.ElementType = 'div'>(
  props: BoxProps<C>
) => React.ReactNode | null;

export const Box: BoxComponent & { displayName?: string } = React.forwardRef(
  <C extends React.ElementType = 'div'>(
    { as, className, display = 'flex', ...originalProps }: BoxProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const Component = as || 'div';

    const props = { display, ...originalProps };
    const userClasses = className;
    const { sprinklesProps, otherProps } = getSprinklesProps(props);

    return (
      <Component
        ref={ref}
        className={classNames(sprinkles(sprinklesProps), userClasses)}
        {...otherProps}
      />
    );
  }
);

Box.displayName = 'Box';
