import type {
  QuestionnaireOnboardingGoals,
  QuestionnaireOnboardingInvestorLevel,
  QuestionnaireOnboardingOrganizationNeeds,
  QuestionnaireOnboardingSource,
} from 'core-api';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import type { CountryCode } from 'utils';

import type { ClerkAPIResponseError } from '/types/auth';

export type SignupFieldValues = {
  country: CountryCode;
  email: string;
  first_name: string;
  goals: QuestionnaireOnboardingGoals;
  investor_level: QuestionnaireOnboardingInvestorLevel;
  last_name: string;
  organizations_needs: QuestionnaireOnboardingOrganizationNeeds;
  password: string;
  source: QuestionnaireOnboardingSource;
};

export const signupValuesAtom = atomWithStorage<SignupFieldValues | undefined>(
  'signupValues',
  undefined
);

export const signupErrorAtom = atom<ClerkAPIResponseError | Error | undefined>(
  undefined
);
