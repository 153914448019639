import * as React from 'react';

import { Box } from '../Box';
import { Icon } from '../Icon';
import { Typography } from '../Typography';

export const SelectCurrencyButtonLabel = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <Typography color="tertiary" variant="labelCaption">
    {children}
  </Typography>
);

export type SelectCurrencyButtonProps = {
  children: React.ReactNode;
};

export const SelectCurrencyButton = React.forwardRef<
  HTMLButtonElement,
  SelectCurrencyButtonProps
>(({ children, ...props }, ref) => (
  <Box
    ref={ref}
    as="button"
    flexDirection="row"
    alignItems="center"
    type="button"
    borderRadius="small"
    gap="s3"
    {...props}
  >
    <SelectCurrencyButtonLabel>{children}</SelectCurrencyButtonLabel>
    <Icon name="caretDown" color="tertiary" size="small" />
  </Box>
));

SelectCurrencyButton.displayName = 'CurrencySelectButton';
