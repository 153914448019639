import type { AssetCategory, AssetOrder } from 'core-api';
import { atomWithStorage } from 'jotai/utils';

export const moversCategoriesAtom = atomWithStorage<AssetCategory[]>(
  'moversCategories',
  []
);

export const moversSortFilterAtom = atomWithStorage<AssetOrder | undefined>(
  'moversSortFilter',
  undefined
);
