import { fetchApi } from '../../config';
import { useApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';

export type FetchFinaryConnectSynchronizationRequiresExternalAuthResponse = {
  external_auth_url: string | null;
  requires_external_auth: boolean | null;
};

/** This hook doesn't use swr because we pass a dynamic id to the fetch call */
export const useFetchFinaryConnectSynchronizationRequiresExternalAuth = () => {
  const apiConfiguration = useApiConfig();

  return (connectionId: string, synchronizationId: string) =>
    fetchApi<FetchFinaryConnectSynchronizationRequiresExternalAuthResponse>(
      API_ROUTES.connect.connections
        .byId(connectionId)
        .synchronizations.byId(synchronizationId)
        .requiresExternalAuth.get(),
      {
        method: 'GET',
        ...apiConfiguration,
      }
    );
};
