import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../config';
import { useApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import { FinaryConnectFieldId } from '../../types/finary-connect';

export type FinaryConnectBankPasswordBody = {
  fields: Record<FinaryConnectFieldId, string>;
  version: number;
};

export type CreateBankConnectionBody =
  | {
      access_token: string;
      connection_id: string;
      provider: 'budget_insight';
    }
  | {
      bank_slug: string;
      password: FinaryConnectBankPasswordBody;
      provider: 'finary_connect';
    }
  | {
      access_token: string;
      provider: 'plaid';
    }
  | {
      provider: 'flanks';
    };

export type CreateBankConnectionResponse = {
  correlation_id?: string;
  id?: string;
  institution_name?: string;
  last_sync_at?: string;
  provider?: {
    correlation_id: string;
    name: string;
  };
};

export const useCreateBanksConnection = () => {
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    API_ROUTES.banks.init.post(),
    async (url: string, { arg }: { arg: CreateBankConnectionBody }) =>
      fetchApi<CreateBankConnectionResponse>(url, {
        body: arg,
        method: 'POST',
        ...apiConfiguration,
      })
  );
};
