import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import { ApiGetProps, PortfolioFeesResponse } from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { useApi } from '../../useApi';

export const usePortfolioScpiFees = ({
  sharingLinkId,
  accessCode,
}: ApiGetProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });

  return useApi<PortfolioFeesResponse>(
    API_ROUTES.portfolio.fees.get({
      organizationId,
      membershipId,
      params,
    }),
    { keepPreviousData: true },
    !!sharingLinkId
  );
};
