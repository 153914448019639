import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../config';
import { useApiConfig, useFinaryApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';

export const useDeleteHoldingsAccounts = (id?: string) => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    id
      ? API_ROUTES.holdingsAccounts
          .byId(id)
          .delete({ organizationId, membershipId })
      : null,
    async (url: string) =>
      fetchApi<null>(url, {
        method: 'DELETE',
        ...apiConfiguration,
      })
  );
};
