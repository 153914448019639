import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../../config';
import { useApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import { FondsEuro, FondsEuroData } from '../../../types';

export const useEditFondsEuro = (assetId?: number) => {
  const apiConfiguration = useApiConfig();
  const { organizationId, membershipId } = apiConfiguration;

  return useSWRMutation(
    assetId
      ? API_ROUTES.fonds_euro.byId(assetId).put({
          organizationId,
          membershipId,
        })
      : null,
    async (url: string, { arg: body }: { arg: FondsEuroData }) =>
      fetchApi<FondsEuro>(url, {
        body,
        method: 'PUT',
        ...apiConfiguration,
      })
  );
};
