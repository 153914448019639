import { API_ROUTES } from '../../routes';
import { Security } from '../../types';
import { useApi } from '../useApi';
import { useUser } from '../users';

export const useMostPopularSecurities = (limit = 20) => {
  const { data: user } = useUser();
  const params = new URLSearchParams();
  params.append('limit', limit.toString());
  if (user?.country) {
    params.append('country', user.country);
  }

  return useApi<Security[]>(API_ROUTES.securities.mostPopular.get(params));
};
