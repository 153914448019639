import { useUser } from 'core-api';
import { Trans } from 'react-i18next';
import { Typography } from 'ui/components/Typography';

export type InterpolateProps = React.ComponentProps<typeof Trans> & {
  children?: string;
};

/**
 * This component is a wrapper around `Trans` component : https://react.i18next.com/latest/trans-component
 *
 * It adds some default components to the `components` prop.
 *
 * You can either pass it an `i18nkey` and `ns` props or a `children` already translated (useful if you want to use it with `useTranslation` hook to return an array for example)
 */
export const Interpolate = ({
  children,
  components,
  ...props
}: InterpolateProps) => {
  const { hasPlusAccess } = useUser();

  return (
    <Trans
      i18nKey={children as InterpolateProps['i18nKey']}
      t={children ? (noTranslate as InterpolateProps['t']) : undefined}
      components={{
        highlight: <Typography color="accentPrimary" />,
        externalLink: (
          <Typography
            as="a"
            style={{ textDecoration: 'underline' }}
            target="_blank"
            rel="noopener noreferrer"
          />
        ),
        gradient: <Typography variant="gradient" />,
        finaryPlusHighlight: (
          <Typography color="accentPrimary" hideValue={!hasPlusAccess} />
        ),
        danger: <Typography color="error" />,
        primaryHighlight: <Typography color="primary" />,
        successHighlight: <Typography color="success" />,
        errorHighlight: <Typography color="error" />,
        ...components,
      }}
      {...props}
    />
  );
};

const noTranslate = (text: string) => text;
