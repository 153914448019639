import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  DividendsResponse,
  NotSharingLinkProps,
  SharingLinkProps,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { useApi } from '../../useApi';

export type UsePortfolioInvestmentsDividendsProps =
  | NotSharingLinkProps
  | SharingLinkProps;

export const usePortfolioInvestmentsDividends = ({
  sharingLinkId,
  accessCode,
}: UsePortfolioInvestmentsDividendsProps) => {
  const { membershipId, organizationId } = useFinaryApiConfig();

  const params = new URLSearchParams({
    with_real_estate: 'true',
  });

  const sharingLinkParams = createSharingLinkParams(
    { sharingLinkId, accessCode },
    params
  );
  return useApi<DividendsResponse>(
    API_ROUTES.portfolio.investments.dividends.get({
      membershipId,
      organizationId,
      params: sharingLinkParams,
    }),
    undefined,
    !!sharingLinkId
  );
};
