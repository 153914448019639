import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import { Loan } from '../../../types';
import { useApi } from '../../useApi';

export const useLoans = () => {
  const { organizationId, membershipId } = useFinaryApiConfig();

  return useApi<Loan[]>(
    API_ROUTES.loans.get({
      organizationId,
      membershipId,
    })
  );
};
