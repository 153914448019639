import { API_ROUTES } from '../../routes';
import { GeneralUIConfigResponse } from '../../types';
import { useApi } from '../useApi';

export const useWebappUIConfiguration = () => {
  const { data, ...rest } = useApi<GeneralUIConfigResponse>(
    API_ROUTES.uiConfiguration.webapp.get()
  );

  return {
    data: data?.config.webapp,
    ...rest,
  };
};
