/**
 * Safely constructs a URL by removing control characters and preventing execution of JavaScript code.
 * @param url - The URL to be sanitized.
 * @returns The sanitized URL.
 */
export const safeUrl = (url?: string | null) => {
  if (!url) {
    return undefined;
  }
  const ctrlCharactersRegex =
    /[\u0000-\u001F\u007F-\u009F\u2000-\u200D\uFEFF]/gim;
  const decodedUrl = decodeURIComponent(url || '');
  return decodedUrl
    .replace(ctrlCharactersRegex, '')
    ?.replace(/javascript:.*$/i, '');
};

/**
 * Removes query parameters from a URL.
 * @param url - The URL from which to remove query parameters.
 * @returns The URL without query parameters.
 */
export const removeQueryParams = (url: string) => url.split('?')[0];

/**
 * Prevents open redirects by checking if the given URL belongs to the specified domain.
 * If the URL does not belong to the domain, the domain root is returned.
 * @param domain - The domain to check against.
 * @param url - The URL to check.
 * @returns The original URL if it belongs to the domain, otherwise domain root.
 */
export const preventOpenRedirect = (
  domain: string,
  url: string | undefined | null
) => {
  const root = `https://${domain}`;

  if (!url) {
    return root;
  }

  const isRelativeUrl = url.startsWith('/');

  if (isRelativeUrl) {
    return url;
  }

  try {
    const parsedUrl = new URL(url);

    if (parsedUrl.hostname !== domain) {
      return root;
    }
  } catch {
    return root;
  }

  return url;
};
