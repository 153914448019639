import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  ApiGetProps,
  PortfolioTimeseries,
  TimeseriesWithBenchmarkType,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { usePeriodDisplayMode } from '../../configuration';
import { useApi } from '../../useApi';

export type UsePortfolioInvestmentsTimeseriesProps = {
  type: TimeseriesWithBenchmarkType;
  benchmarkAssetId?: string;
} & ApiGetProps;

export const usePortfolioInvestmentsTimeseries = ({
  type = 'sum',
  sharingLinkId,
  accessCode,
  benchmarkAssetId,
  skip,
}: UsePortfolioInvestmentsTimeseriesProps) => {
  const { membershipId, organizationId } = useFinaryApiConfig();
  const { data: periodDisplayMode } = usePeriodDisplayMode(!!sharingLinkId);

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('timeseries_type', type);
  params.append('period', periodDisplayMode);

  if (benchmarkAssetId) {
    params.append('benchmark_asset_id', benchmarkAssetId);
  }

  return useApi<PortfolioTimeseries>(
    API_ROUTES.portfolio.investments.timeseries.get({
      membershipId,
      organizationId,
      params,
    }),
    { keepPreviousData: !skip },
    !!sharingLinkId || skip
  );
};
