import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import { ApiGetProps, PortfolioFees } from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { useApi } from '../../useApi';

export type UsePortfolioFeesProps = ApiGetProps & {
  isInvestments?: boolean;
};

export const usePortfolioFees = ({
  sharingLinkId,
  accessCode,
  isInvestments,
}: UsePortfolioFeesProps) => {
  const { membershipId, organizationId } = useFinaryApiConfig();
  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('new_format', 'true');

  return useApi<PortfolioFees>(
    (isInvestments
      ? API_ROUTES.portfolio.investments.fees.get
      : API_ROUTES.portfolio.fees.get)({
      membershipId,
      organizationId,
      params,
    }),
    { keepPreviousData: true },
    !!sharingLinkId
  );
};
