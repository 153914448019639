import {
  Asset,
  AssetCategory,
  AssetNewFormat,
  Liability,
  LiabilityNewFormat,
  NotSharingLinkProps,
  PortfolioAccount,
  SharingLinkProps,
} from '../../../types';

import { usePortfolioCheckingAccounts } from './usePortfolioCheckingAccounts';
import { usePortfolioCommoditiesAccounts } from './usePortfolioCommoditiesAccounts';
import { usePortfolioCreditAccounts } from './usePortfolioCreditAccounts';
import { usePortfolioCrowdlendingsAccounts } from './usePortfolioCrowdlendingsAccounts';
import { usePortfolioCryptosAccounts } from './usePortfolioCryptosAccounts';
import { usePortfolioFondsEurosAccounts } from './usePortfolioFondsEurosAccounts';
import { usePortfolioInvestmentsAccounts } from './usePortfolioInvestmentsAccounts';
import { usePortfolioLoansAccounts } from './usePortfolioLoansAccounts';
import { usePortfolioOtherAssetsAccounts } from './usePortfolioOtherAssetsAccounts';
import { usePortfolioRealEstatesAccounts } from './usePortfolioRealEstatesAccounts';
import { usePortfolioSavingsAccounts } from './usePortfolioSavingsAccounts';
import { usePortfolioStartupsAccounts } from './usePortfolioStartupsAccounts';

export type UsePortfolioAccountsProps = {
  categories?:
    | (Asset | AssetNewFormat | Liability | LiabilityNewFormat)[]
    | undefined;
} & (NotSharingLinkProps | SharingLinkProps);

export const usePortfolioAccounts = ({
  sharingLinkId,
  accessCode,
  categories,
}: UsePortfolioAccountsProps) => {
  const {
    data: portfolioCheckingAccounts,
    isInitialLoading: isCheckingAccountsInitialLoading,
    isLoading: isCheckingAccountsLoading,
    mutate: mutateCheckingAccounts,
  } = usePortfolioCheckingAccounts({
    sharingLinkId,
    accessCode,
    skip:
      !categories ||
      (!categories.includes('checking_accounts') &&
        !categories.includes('checkings')),
  });
  const {
    data: portfolioCommoditiesAccounts,
    isInitialLoading: isCommoditiesAccountsInitialLoading,
    isLoading: isCommoditiesAccountsLoading,
    mutate: mutateCommoditiesAccounts,
  } = usePortfolioCommoditiesAccounts({
    sharingLinkId,
    accessCode,
    skip: !categories || !categories.includes('commodities'),
  });

  const {
    data: portfolioCreditAccounts,
    isInitialLoading: isCreditAccountsInitialLoading,
    isLoading: isCreditAccountsLoading,
    mutate: mutateCreditAccounts,
  } = usePortfolioCreditAccounts({
    sharingLinkId,
    accessCode,
    skip:
      !categories ||
      (!categories.includes('credit_accounts') &&
        !categories.includes('credits')),
  });
  const {
    data: portfolioCrowdlendingsAccounts,
    isInitialLoading: isCrowdlendingsAccountsInitialLoading,
    isLoading: isCrowdlendingsAccountsLoading,
    mutate: mutateCrowdlendingsAccounts,
  } = usePortfolioCrowdlendingsAccounts({
    sharingLinkId,
    accessCode,
    skip: !categories || !categories.includes('crowdlendings'),
  });
  const {
    data: portfolioCryptosAccounts,
    isInitialLoading: isCryptosAccountsInitialLoading,
    isLoading: isCryptosAccountsLoading,
    mutate: mutateCryptosAccounts,
  } = usePortfolioCryptosAccounts({
    sharingLinkId,
    accessCode,
    skip: !categories || !categories.includes('cryptos'),
  });
  const {
    data: portfolioFondsEurosAccounts,
    isInitialLoading: isFondsEurosAccountsInitialLoading,
    isLoading: isFondsEurosAccountsLoading,
    mutate: mutateFondsEurosAccounts,
  } = usePortfolioFondsEurosAccounts({
    sharingLinkId,
    accessCode,
    skip: !categories || !categories.includes('fonds_euro'),
  });
  const {
    data: portfolioInvestmentsAccountsAccounts,
    isInitialLoading: isInvestmentsAccountsInitialLoading,
    isLoading: isInvestmentsAccountsLoading,
    mutate: mutateInvestmentsAccounts,
  } = usePortfolioInvestmentsAccounts({
    sharingLinkId,
    accessCode,
    skip:
      !categories ||
      (!categories.includes('investments') &&
        !categories.includes('investment_accounts')),
  });
  const {
    data: portfolioLoansAccounts,
    isInitialLoading: isLoansInitialLoading,
    isLoading: isLoansLoading,
    mutate: mutateLoans,
  } = usePortfolioLoansAccounts({
    sharingLinkId,
    accessCode,
    skip: !categories || !categories.includes('loans'),
  });
  const {
    data: portfolioOtherAssetsAccounts,
    isInitialLoading: isOtherAssetsAccountsInitialLoading,
    isLoading: isOtherAssetsAccountsLoading,
    mutate: mutateOtherAssetsAccounts,
  } = usePortfolioOtherAssetsAccounts({
    sharingLinkId,
    accessCode,
    skip: !categories || !categories.includes('other_assets'),
  });
  const {
    data: portfolioRealEstatesAccounts,
    isInitialLoading: isRealEstatesAccountsInitialLoading,
    isLoading: isRealEstatesAccountsLoading,
    mutate: mutateRealEstatesAccounts,
  } = usePortfolioRealEstatesAccounts({
    sharingLinkId,
    accessCode,
    skip: !categories || !categories.includes('real_estates'),
  });
  const {
    data: portfolioSavingsAccounts,
    isInitialLoading: isSavingsAccountsInitialLoading,
    isLoading: isSavingsAccountsLoading,
    mutate: mutateSavingsAccounts,
  } = usePortfolioSavingsAccounts({
    sharingLinkId,
    accessCode,
    skip:
      !categories ||
      (!categories.includes('savings_accounts') &&
        !categories.includes('savings')),
  });
  const {
    data: portfolioStartupsAccounts,
    isInitialLoading: isStartupAccountsInitialLoading,
    isLoading: isStartupAccountsLoading,
    mutate: mutateStartupAccounts,
  } = usePortfolioStartupsAccounts({
    sharingLinkId,
    accessCode,
    skip: !categories || !categories.includes('startups'),
  });

  // Map accounts to portfolio account
  const portfolioAccounts: { [A in AssetCategory]?: PortfolioAccount[] } = {
    investment_accounts: portfolioInvestmentsAccountsAccounts,
    credit_accounts: portfolioCreditAccounts,
    crowdlendings: portfolioCrowdlendingsAccounts,
    cryptos: portfolioCryptosAccounts,
    commodities: portfolioCommoditiesAccounts,
    fonds_euro: portfolioFondsEurosAccounts,
    loans: portfolioLoansAccounts,
    other_assets: portfolioOtherAssetsAccounts,
    real_estates: portfolioRealEstatesAccounts,
    checking_accounts: portfolioCheckingAccounts,
    savings_accounts: portfolioSavingsAccounts,
    startups: portfolioStartupsAccounts,
  };

  return {
    portfolioAccounts,
    isInitialLoading:
      isCheckingAccountsInitialLoading ||
      isCommoditiesAccountsInitialLoading ||
      isCreditAccountsInitialLoading ||
      isCrowdlendingsAccountsInitialLoading ||
      isCryptosAccountsInitialLoading ||
      isFondsEurosAccountsInitialLoading ||
      isInvestmentsAccountsInitialLoading ||
      isLoansInitialLoading ||
      isOtherAssetsAccountsInitialLoading ||
      isRealEstatesAccountsInitialLoading ||
      isStartupAccountsInitialLoading ||
      isSavingsAccountsInitialLoading,
    isLoading:
      isCheckingAccountsLoading ||
      isCommoditiesAccountsLoading ||
      isCreditAccountsLoading ||
      isCrowdlendingsAccountsLoading ||
      isCryptosAccountsLoading ||
      isFondsEurosAccountsLoading ||
      isInvestmentsAccountsLoading ||
      isLoansLoading ||
      isOtherAssetsAccountsLoading ||
      isRealEstatesAccountsLoading ||
      isStartupAccountsLoading ||
      isSavingsAccountsLoading,
    mutate: () =>
      Promise.all([
        mutateCheckingAccounts(),
        mutateSavingsAccounts(),
        mutateInvestmentsAccounts(),
        mutateCryptosAccounts(),
        mutateCreditAccounts(),
        mutateCommoditiesAccounts(),
        mutateFondsEurosAccounts(),
        mutateStartupAccounts(),
        mutateRealEstatesAccounts(),
        mutateLoans(),
        mutateOtherAssetsAccounts(),
        mutateCrowdlendingsAccounts(),
      ]),
  };
};
