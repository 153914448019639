import { HoldingsAccount } from './holdings-account';
import { PortfolioAccount } from './portfolio';

export type Provider = {
  correlation_id: string;
  name: string;
};

export enum BankProvider {
  budgetInsight = 'BudgetInsight',
  flanks = 'Flanks',
  finaryConnect = 'FinaryConnect',
  plaid = 'Plaid',
}

export type InstitutionProvider = {
  capabilities: Capability[];
  correlation_id: string;
  id: string;
  is_enabled: boolean;
  provider: Provider;
  status: string;
};

export type InstitutionProviderAccountType = {
  display_name: string;
  name: string;
  order: number;
  subtypes: { display_name: string; name: string }[];
};

export type InstitutionProviderWithConnectionInfos = InstitutionProvider & {
  account_types: InstitutionProviderAccountType[];
  institution: Institution;
  manual_sync: boolean;
  sources: string[];
};

export enum ConnectionErrorStatusEnum {
  'action_needed' = 'ACTION_NEEDED',
  'auth_required' = 'AUTH_REQUIRED',
  'error' = 'ERROR',
  'forbidden' = 'FORBIDDEN',
  'institution_error' = 'INSTITUTION_ERROR',
  'notsupported' = 'notSupported',
  'provider_error' = 'PROVIDER_ERROR',
  'ratelimiting' = 'rateLimiting',
  'revoked' = 'REVOKED',
  'sca_required' = 'SCA_REQUIRED',
  'timeout_2fa_code' = 'TIMEOUT_2FA_CODE',
  'timeout_external_auth' = 'TIMEOUT_EXTERNAL_AUTH',
  'validating' = 'validating',
  'wrong_2fa_code' = 'WRONG_2FA_CODE',
  'wrong_password' = 'WRONG_PASSWORD',
}

export type ConnectionStatusWithAction =
  | 'auth_required'
  | 'wrong_password'
  | 'sca_required'
  | 'action_needed'
  | 'revoked'
  | 'forbidden';

export type Capability =
  | 'cash'
  | 'stocks'
  | 'crypto'
  | 'startups'
  | 'commodities'
  | 'other'
  | 'loan';

export type ProviderConnection = {
  correlation_id: string;
  id: string;
  last_sync_at: string;
  provider: Provider;
};

export type InstitutionSubscriptionLevel = 'lite' | 'plus' | 'pro';

export type Institution = {
  countries: string[];
  id: string;
  logo_url: string | null;
  name: string;
  slug: string;
  twitter_handle: string;
  whitelist: boolean;
  subscription_level?: InstitutionSubscriptionLevel | null;
};

export type InstitutionWithProviders = Institution & {
  providers: InstitutionProvider[];
  is_requestable?: boolean;
};

export type InstitutionConnection = {
  connection_state: ConnectionErrorStatusEnum | null;
  correlation_id: string | null;
  id: string;
  institution: Institution;
  last_provider_update_at: string | null;
  last_sync_at: string | null;
  manual_sync: boolean;
  name: string;
  original_connection_state: string | null;
  provider_connection: ProviderConnection;
  institution_provider?: InstitutionProvider;
};

export type ConnectionWithInstitutionAndAccounts = {
  accounts: PortfolioAccount[];
  display_total: number;
  institution: Institution;
  institution_connection: InstitutionConnection;
};

export type Connection = InstitutionConnection & {
  balance: number;
  holdings_accounts?: HoldingsAccount[];
};

export type ConnectionType = 'banks' | 'cryptos' | 'startups';

export type Connections = Record<ConnectionType, Connection[]>;

export type ConnectionStatus =
  | 'in_progress'
  | 'pause'
  | 'success'
  | 'error'
  | 'outdated'
  | null;

export enum CryptoProviders {
  binance = 'Binance',
  bitcoin = 'Bitcoin',
  bitpanda = 'Bitpanda',
  bitstamp = 'Bitstamp',
  coinbase = 'Coinbase',
  coinbasePro = 'Coinbase Pro',
  cryptoCom = 'Crypto.com',
  ethereum = 'Ethereum',
  justmining = 'Justmining',
  kraken = 'Kraken',
  kucoin = 'Kucoin',
  gateIo = 'Gate.io',
}

export type ProvidersAction = 'connect' | 'manage' | 'reconnect';
