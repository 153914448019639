import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  AllocationResponse,
  NotSharingLinkProps,
  SharingLinkProps,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { useApi } from '../../useApi';

export type UsePortfolioRealEstatesByAccountGeoAllocationBaseProps = {
  accountId: string | undefined;
};

export type UsePortfolioRealEstatesByAccountGeoAllocationWithoutSharingProps =
  UsePortfolioRealEstatesByAccountGeoAllocationBaseProps & NotSharingLinkProps;

export type UsePortfolioRealEstatesByAccountGeoAllocationWithSharingProps =
  UsePortfolioRealEstatesByAccountGeoAllocationBaseProps & SharingLinkProps;

export type UsePortfolioRealEstatesByAccountGeoAllocationProps =
  | UsePortfolioRealEstatesByAccountGeoAllocationWithoutSharingProps
  | UsePortfolioRealEstatesByAccountGeoAllocationWithSharingProps;

export const usePortfolioRealEstatesByAccountGeoAllocation = ({
  accountId,
  sharingLinkId,
  accessCode,
}: UsePortfolioRealEstatesByAccountGeoAllocationProps) => {
  const { membershipId, organizationId } = useFinaryApiConfig();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });

  return useApi<AllocationResponse>(
    accountId
      ? API_ROUTES.portfolio.real_estates
          .accountById(accountId)
          .geoAllocation.get({
            membershipId,
            organizationId,
            params,
          })
      : null,
    undefined,
    !!sharingLinkId
  );
};
