import { useFinaryApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import { HoldingsAccount, HoldingsAccountManualType } from '../../types';
import { useApi } from '../useApi';

type UseGetHoldingsAccountsParams = {
  manual_type?: HoldingsAccountManualType;
  viewAsFamily?: boolean;
  withAllOrgAccounts?: boolean;
  without_empty?: boolean;
};

export const useGetHoldingsAccounts = ({
  manual_type,
  viewAsFamily,
  withAllOrgAccounts,
  without_empty,
}: UseGetHoldingsAccountsParams = {}) => {
  const { membershipId, organizationId } = useFinaryApiConfig();

  const params = new URLSearchParams();
  if (manual_type) {
    params.append('manual_type', manual_type);
  }
  if (without_empty) {
    params.append('without_empty', 'true');
  }

  if (withAllOrgAccounts) {
    params.append('with_all_org_accounts', 'true');
  }

  return useApi<HoldingsAccount[]>(
    API_ROUTES.holdingsAccounts.get({
      membershipId,
      organizationId,
      params,
      viewAsFamily,
    }),
    { keepPreviousData: true }
  );
};
