/**
 * Sort helpers
 */

export enum SortOrder {
  ascending = 1,
  descending = -1,
}

// The types that we provide Sorters for
export type SortableType = string | number | Date;

export type Sorter<T> = (a: T, b: T) => number;

export const sortNumber: Sorter<number> = (a, b) => a - b;

export const sortDate: Sorter<Date> = (a, b) => a.getTime() - b.getTime();

export const sortString: Sorter<string> = (a, b) => {
  return a.localeCompare(b);
};
