import { fetchApi } from '../../config';
import { useApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import { FinaryConnectCode2Fa } from '../../types/finary-connect';

export type FetchFinaryConnectSynchronizationRequires2FaCodeResponse = {
  message_2fa: { code: FinaryConnectCode2Fa };
  requires_2fa_code: boolean | null;
};

/** This hook doesn't use swr because we pass a dynamic id to the fetch call */
export const useFetchFinaryConnectSynchronizationRequires2FaCode = () => {
  const apiConfiguration = useApiConfig();

  return (connectionId: string, synchronizationId: string) =>
    fetchApi<FetchFinaryConnectSynchronizationRequires2FaCodeResponse>(
      API_ROUTES.connect.connections
        .byId(connectionId)
        .synchronizations.byId(synchronizationId)
        .requires2FaCode.get(),
      {
        method: 'GET',
        ...apiConfiguration,
      }
    );
};
