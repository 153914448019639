import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../../config';
import { useApiConfig, useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import { RealEstate } from '../../../types';

export const useEditRealEstatesById = (id: string | number) => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    API_ROUTES.real_estates.byId(id).put({ organizationId, membershipId }),
    async (url: string, { arg }: { arg: Partial<RealEstate> }) =>
      fetchApi<RealEstate>(url, {
        body: arg,
        method: 'PUT',
        ...apiConfiguration,
      })
  );
};
