import { useRouter } from 'next/router';
import * as React from 'react';

export type SharingContextState = {
  accessCode?: string;
};

export type SharingContextType = SharingContextState & {
  setAccessCode: (accessCode: string | undefined) => void;
};

export const SharingContext = React.createContext<SharingContextType>({
  setAccessCode: () => undefined,
});

export const SharingProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, setState] = React.useState<SharingContextState>({});
  const setAccessCode = (accessCode: string | undefined) => {
    setState((prevState) => ({ ...prevState, accessCode }));
  };

  // If a code is given in the URL, we set it in the context
  const { query } = useRouter();
  const { code } = query;
  React.useEffect(() => {
    if (code) {
      setAccessCode(code as string);
    }
  }, [code]);

  return (
    <SharingContext.Provider value={{ ...state, setAccessCode }}>
      {children}
    </SharingContext.Provider>
  );
};
