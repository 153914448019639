import { CurrencyCode } from '../types';

export const CURRENCIES: CurrencyCode[] = [
  'EUR',
  'USD',
  'GBP',
  'CHF',
  'CAD',
  'SGD',
];

export const PREMIUM_CURRENCIES: CurrencyCode[] = ['BTC', 'ETH'];
