import { DateRange } from 'utils';

import { useFinaryApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import {
  AssetCategory,
  NotSharingLinkProps,
  PortfolioTimeseries,
  SharingLinkProps,
  TimeseriesType,
  WealthDisplayMode,
} from '../../types';
import { createSharingLinkParams } from '../../utils';
import { usePeriodDisplayMode, useWealthDisplayMode } from '../configuration';
import { useApi } from '../useApi';

export type UsePortfolioTimeseriesBaseProps = {
  categories?: AssetCategory[];
  period?: DateRange;
  timeseriesType?: TimeseriesType;
  type?: WealthDisplayMode;
};

export type UsePortfolioTimeseriesWithoutSharingProps =
  UsePortfolioTimeseriesBaseProps & NotSharingLinkProps;

export type UsePortfolioTimeseriesWithSharingProps =
  UsePortfolioTimeseriesBaseProps & SharingLinkProps;

export type UsePortfolioTimeseriesProps =
  | UsePortfolioTimeseriesWithoutSharingProps
  | UsePortfolioTimeseriesWithSharingProps;

export const usePortfolioTimeseries = ({
  period,
  type,
  timeseriesType = 'sum',
  categories,
  sharingLinkId,
  accessCode,
}: UsePortfolioTimeseriesProps) => {
  const { membershipId, organizationId } = useFinaryApiConfig();
  const { data: periodDisplayMode } = usePeriodDisplayMode(!!sharingLinkId);
  const { data: wealthDisplayMode } = useWealthDisplayMode(!!sharingLinkId);

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('period', period ?? periodDisplayMode);
  params.append('timeseries_type', timeseriesType);
  params.append('value_type', type ?? wealthDisplayMode);
  params.append('new_format', 'true');

  if (categories?.length) {
    params.append('categories', categories.join(','));
  }

  return useApi<PortfolioTimeseries>(
    API_ROUTES.portfolio.timeseries.get({
      membershipId,
      organizationId,
      params,
    }),
    { keepPreviousData: true },
    !!sharingLinkId
  );
};
