import { ProvidersAction } from '../../../types';

import { usePowensGetTemporaryCode } from './usePowensGetTemporaryCode';

export type GetPowensUrlParams = {
  action: ProvidersAction;
  locale: string;
  bankUuid?: string;
  connection_id?: string | null;
};

export type usePowensGetUrlProps = {
  powensClientId: string;
  powensDomain: string;
  redirectUri: string;
};

export const usePowensGetUrl = ({
  powensClientId,
  powensDomain,
  redirectUri,
}: usePowensGetUrlProps) => {
  const getTempCode = usePowensGetTemporaryCode();

  return {
    getPowensUrl: async ({
      action,
      bankUuid,
      connection_id,
      locale,
    }: GetPowensUrlParams) => {
      const tempCode = await getTempCode();

      const uri = `https://webview.powens.com/${locale}/${action}`;

      const params: Record<string, string> = {
        state: 'refreshBanks',
        client_id: powensClientId,
        redirect_uri: redirectUri,
        domain: `${powensDomain}.biapi.pro`,
      };

      if (bankUuid) {
        params.connector_uuids = bankUuid;
      }
      if (connection_id) {
        params.connection_id = connection_id;
      }
      if (tempCode?.code) {
        params.code = tempCode.code;
      }

      const queryParams = new URLSearchParams(params);

      return `${uri}?${queryParams.toString()}`;
    },
  };
};
