import { API_ROUTES } from '../routes';
import { GodsonsResponse } from '../types';

import { useApi } from './useApi';

export const useSponsorships = () => {
  return useApi<GodsonsResponse>(API_ROUTES.sponsorships.get(), {
    keepPreviousData: true,
  });
};
