import { API_ROUTES } from '../../../routes';
import { PreciousMetal } from '../../../types';
import { useApi } from '../../useApi';
import { useUser } from '../../users';

export const useMostPopularPreciousMetals = (limit = 20) => {
  const { data: user } = useUser();
  const params = new URLSearchParams();
  params.append('limit', limit.toString());
  if (user?.country) {
    params.append('country', user.country);
  }

  return useApi<PreciousMetal[]>(
    API_ROUTES.precious_metals.mostPopular.get(params)
  );
};
