import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import { ApiGetProps, HoldingsAccount } from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { usePeriodDisplayMode } from '../../configuration';
import { useApi } from '../../useApi';

export type UsePortfolioInvestmentsByAccountProps = {
  accountId?: string;
} & ApiGetProps;

export const usePortfolioInvestmentsByAccount = ({
  accountId,
  sharingLinkId,
  accessCode,
}: UsePortfolioInvestmentsByAccountProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const { data: periodDisplayMode } = usePeriodDisplayMode(!!sharingLinkId);

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('period', periodDisplayMode);

  return useApi<HoldingsAccount>(
    accountId
      ? API_ROUTES.portfolio.investments.accountById(accountId).get({
          organizationId,
          membershipId,
          params,
        })
      : null,
    undefined,
    !!sharingLinkId
  );
};
