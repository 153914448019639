import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../../config';
import { useApiConfig, useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import { CryptoAccount } from '../../../types';

export type CreateCryptoAssetParams = {
  buying_price: number;
  correlation_id: string;
  quantity: number;
  holdings_account?: {
    id: string;
  };
};

export const useCreateCryptoAsset = () => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    API_ROUTES.cryptos.post({ organizationId, membershipId }),
    async (url: string, { arg }: { arg: CreateCryptoAssetParams }) =>
      fetchApi<CryptoAccount>(url, {
        body: arg,
        method: 'POST',
        ...apiConfiguration,
      })
  );
};
