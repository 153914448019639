import { useFinaryApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import { InstitutionWithProviders } from '../../types';
import { useApi } from '../useApi';

export const useInstitution = (slugOrId?: string, skip?: boolean) => {
  const { membershipId, organizationId } = useFinaryApiConfig();

  return useApi<InstitutionWithProviders>(
    slugOrId
      ? API_ROUTES.institutions.bySlugOrId(slugOrId).get({
          membershipId,
          organizationId,
        })
      : null,
    undefined,
    undefined,
    skip
  );
};
