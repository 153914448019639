import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  NotSharingLinkProps,
  PortfolioTimeseries,
  SharingLinkProps,
  TimeseriesType,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { usePeriodDisplayMode } from '../../configuration';
import { useApi } from '../../useApi';

export type UsePortfolioCrowdlendingsTimeseriesBaseProps = {
  type?: TimeseriesType;
};

export type UsePortfolioCrowdlendingsTimeseriesWithoutSharingProps =
  UsePortfolioCrowdlendingsTimeseriesBaseProps & NotSharingLinkProps;

export type UsePortfolioCrowdlendingsTimeseriesWithSharingProps =
  UsePortfolioCrowdlendingsTimeseriesBaseProps & SharingLinkProps;

export type UsePortfolioCrowdlendingsTimeseriesProps =
  | UsePortfolioCrowdlendingsTimeseriesWithoutSharingProps
  | UsePortfolioCrowdlendingsTimeseriesWithSharingProps;

export const usePortfolioCrowdlendingsTimeseries = ({
  type = 'sum',
  sharingLinkId,
  accessCode,
}: UsePortfolioCrowdlendingsTimeseriesProps) => {
  const { membershipId, organizationId } = useFinaryApiConfig();
  const { data: periodDisplayMode } = usePeriodDisplayMode(!!sharingLinkId);

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('timeseries_type', type);
  params.append('period', periodDisplayMode);

  return useApi<PortfolioTimeseries>(
    API_ROUTES.portfolio.crowdlendings.timeseries.get({
      membershipId,
      organizationId,
      params,
    }),
    { keepPreviousData: true },
    !!sharingLinkId
  );
};
