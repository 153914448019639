import { DisplayCurrency } from '../../types';

import { useUIConfiguration } from './useUIConfiguration';

export const useDisplayCurrency = () => {
  const { data: configuration, update: updateUIConfiguration } =
    useUIConfiguration();
  return {
    data: configuration.display_currency,
    update: (displayCurrency: DisplayCurrency) =>
      updateUIConfiguration({ display_currency: displayCurrency }),
  };
};
