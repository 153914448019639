import * as React from 'react';

import { Icon } from '../Icon';

import { TextField, TextFieldProps } from './TextField';

export type TextFieldWithConfirmationProps = Omit<
  TextFieldProps,
  'suffix' | 'ref'
> & {
  isDirty: boolean | undefined;
  onSubmit: () => Promise<void>;
};

export const TextFieldWithConfirmation = React.forwardRef<
  HTMLInputElement,
  TextFieldWithConfirmationProps
>(({ isDirty, onBlur, onSubmit, ...props }, ref) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);

  return (
    <TextField
      ref={ref}
      suffix={
        isLoading ? (
          <Icon name="spinnerSolid" animation="rotate" color="tertiary" />
        ) : isSuccess ? (
          <Icon name="check" color="success" />
        ) : undefined
      }
      onBlur={async (event) => {
        if (isDirty) {
          setIsLoading(true);
          try {
            await onSubmit();
          } finally {
            setIsSuccess(true);
            setTimeout(() => {
              setIsSuccess(false);
            }, 1000);
            setIsLoading(false);
          }
        }
        return onBlur(event);
      }}
      {...props}
    />
  );
});

TextFieldWithConfirmation.displayName = 'TextFieldWithConfirmation';
