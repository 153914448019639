import { Box } from 'ui/components/Box';
import { Loader } from 'ui/components/Loader';

import { fullPageLoaderContainerStyles } from '/components/common/FullPageLoader.css';

export const FullPageLoader = () => {
  return (
    <Box className={fullPageLoaderContainerStyles}>
      <Loader />
    </Box>
  );
};
