import { useAtom } from 'jotai';

import { providerSynchronizationAtom } from '/contexts/providerSynchronization';
import { firstVisitedPageAtom } from '/contexts/HistoryProvider';
import { isOnboardingAtom } from '/contexts/onboarding';
import { advisorAtom } from '/contexts/advisor';
import { showAssetLimitationModalPaywallAtom } from '/contexts/paywall';
import { impersonatedClerkTicketAtom } from '/contexts/impersonate';
import {
  cashflowCategoryTabPreferenceAtom,
  cashflowTabPreferenceAtom,
} from '/contexts/cashflow';
import {
  dismissedTransactionBannerAtom,
  dismissedWaltioBannerAtom,
  dismissedWaltioTopBannerAtom,
  yearlyBannerCloseTimestampAtom,
  lifeInsuranceBannerCloseTimestampAtom,
} from '/contexts/banners';
import { portfolioBenchmarkAtom } from '/contexts/portfolioBenchmark';
import { portfolioOverviewSelectPreferenceAtom } from '/contexts/portfolioOverview';
import { portfolioCategoriesAtom } from '/contexts/portfolioCategories';
import {
  crowdlendingsDistributionTypeFilterAtom,
  cryptosDistributionTypeFilterAtom,
  distributionChartTypeAtom,
  investmentsDistributionTypeFilterAtom,
  otherAssetsDistributionTypeFilterAtom,
  realEstatesDistributionTypeFilterAtom,
} from '/contexts/distribution';
import {
  checkingAccountsTabPreferenceAtom,
  creditAccountsTabPreferenceAtom,
  investmentAccountsTabPreferenceAtom,
  investmentAccountsWrapperTabPreferenceAtom,
  savingAccountsTabPreferenceAtom,
} from '/contexts/tabPreferences';
import { signupValuesAtom } from '/contexts/signup';
import { portfolioAssetIsStackedChartAtom } from '/contexts/portfolioAsset';
import { feedbackAtom } from '/contexts/feedback';
import {
  assetCategoryFilterAtom,
  liabilityCategoryFilterAtom,
} from '/contexts/portfolioAssetsAndLiabilities';
import { moversCategoriesAtom, moversSortFilterAtom } from '/contexts/movers';
import { membershipIdAtom, organizationIdAtom } from '/contexts/organization';
import { dashboardCategoriesAtom } from '/contexts/dashboard';
import { investDepositPaymentModeAtom } from '/contexts/invest';
import { financialProjectionAtom } from '/contexts/simulation';
import { lifeInsuranceModalCloseTimestampAtom } from '/contexts/modals';

/** This component is used to preload storage atoms and prevent flicker on storage hydration  */
export const PreloadStorageAtoms = () => {
  // organization state
  useAtom(organizationIdAtom);
  useAtom(membershipIdAtom);

  // dashboard graph
  useAtom(dashboardCategoriesAtom);

  // distribution chart
  useAtom(distributionChartTypeAtom);

  // movers
  useAtom(moversCategoriesAtom);
  useAtom(moversSortFilterAtom);

  // portfolio categories
  useAtom(assetCategoryFilterAtom);
  useAtom(liabilityCategoryFilterAtom);
  useAtom(portfolioCategoriesAtom);

  // portfolio asset details
  useAtom(crowdlendingsDistributionTypeFilterAtom);
  useAtom(cryptosDistributionTypeFilterAtom);
  useAtom(investmentsDistributionTypeFilterAtom);
  useAtom(otherAssetsDistributionTypeFilterAtom);
  useAtom(portfolioAssetIsStackedChartAtom);
  useAtom(realEstatesDistributionTypeFilterAtom);

  // portfolio Tab preference
  useAtom(checkingAccountsTabPreferenceAtom);
  useAtom(creditAccountsTabPreferenceAtom);
  useAtom(savingAccountsTabPreferenceAtom);
  useAtom(investmentAccountsTabPreferenceAtom);
  useAtom(investmentAccountsWrapperTabPreferenceAtom);
  useAtom(cashflowTabPreferenceAtom);
  useAtom(cashflowCategoryTabPreferenceAtom);

  // portfolio Select preference
  useAtom(portfolioOverviewSelectPreferenceAtom);

  // signup
  useAtom(signupValuesAtom);

  // impersonate
  useAtom(impersonatedClerkTicketAtom);

  // user feedback
  useAtom(feedbackAtom);

  // provider synchronization
  useAtom(providerSynchronizationAtom);

  // history
  useAtom(firstVisitedPageAtom);

  // Banners
  useAtom(lifeInsuranceBannerCloseTimestampAtom);
  useAtom(yearlyBannerCloseTimestampAtom);
  useAtom(dismissedTransactionBannerAtom);
  useAtom(dismissedWaltioBannerAtom);
  useAtom(dismissedWaltioTopBannerAtom);

  // Modals
  useAtom(lifeInsuranceModalCloseTimestampAtom);

  // onboarding
  useAtom(isOnboardingAtom);

  // advisor
  useAtom(advisorAtom);

  // paywalls
  useAtom(showAssetLimitationModalPaywallAtom);

  // portfolio benchmark
  useAtom(portfolioBenchmarkAtom);

  // invest
  useAtom(investDepositPaymentModeAtom);

  // Portfolio simulation
  useAtom(financialProjectionAtom);

  return null;
};
