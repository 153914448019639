import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../../config';
import { useApiConfig, useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import { Startup, StartupFormData } from '../../../types';

export const useEditStartupInvestment = (
  startupId: number,
  investmentId: number
) => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    API_ROUTES.startups
      .byId(startupId)
      .investments.byId(investmentId)
      .put({ organizationId, membershipId }),
    async (url: string, { arg: body }: { arg: StartupFormData }) =>
      fetchApi<Startup>(url, {
        body,
        method: 'PUT',
        ...apiConfiguration,
      })
  );
};
