import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  LoansSummaryResponse,
  NotSharingLinkProps,
  SharingLinkProps,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { useApi } from '../../useApi';

export type UsePortfolioLoansProps = NotSharingLinkProps | SharingLinkProps;

export const usePortfolioLoans = ({
  sharingLinkId,
  accessCode,
}: UsePortfolioLoansProps = {}) => {
  const { organizationId, membershipId } = useFinaryApiConfig();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });

  return useApi<LoansSummaryResponse>(
    API_ROUTES.portfolio.loans.get({
      organizationId,
      membershipId,
      params,
    }),
    undefined,
    !!sharingLinkId
  );
};
