import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  NotSharingLinkProps,
  PortfolioTimeseries,
  SharingLinkProps,
  TimeseriesType,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { usePeriodDisplayMode } from '../../configuration';
import { useApi } from '../../useApi';

export type UsePortfolioCryptosTimeseriesBaseProps = {
  type?: TimeseriesType;
};

export type UsePortfolioCryptosTimeseriesWithoutSharingProps =
  UsePortfolioCryptosTimeseriesBaseProps & NotSharingLinkProps;

export type UsePortfolioCryptosTimeseriesWithSharingProps =
  UsePortfolioCryptosTimeseriesBaseProps & SharingLinkProps;

export type UsePortfolioCryptosTimeseriesProps =
  | UsePortfolioCryptosTimeseriesWithoutSharingProps
  | UsePortfolioCryptosTimeseriesWithSharingProps;

export const usePortfolioCryptosTimeseries = ({
  type = 'sum',
  sharingLinkId,
  accessCode,
}: UsePortfolioCryptosTimeseriesProps) => {
  const { membershipId, organizationId } = useFinaryApiConfig();
  const { data: periodDisplayMode } = usePeriodDisplayMode(!!sharingLinkId);

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('timeseries_type', type);
  params.append('period', periodDisplayMode);

  return useApi<PortfolioTimeseries>(
    API_ROUTES.portfolio.cryptos.timeseries.get({
      membershipId,
      organizationId,
      params,
    }),
    { keepPreviousData: true },
    !!sharingLinkId
  );
};
