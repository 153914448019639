import { useFinaryApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import { HoldingsAccount, HoldingsAccountOwnership } from '../../types';
import { usePutApi } from '../useApi';

export type UpdateHoldingsAccountBody = {
  repartition: HoldingsAccountOwnership[];
};

/**
 * This hook allow to pass the id to the trigger function and not to the hook itself.
 */
export const useUpdateHoldingsAccountsOwnership = () => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const put = usePutApi<HoldingsAccount, UpdateHoldingsAccountBody>();

  return {
    trigger: (id: string, body: UpdateHoldingsAccountBody) =>
      put(
        API_ROUTES.holdingsAccounts
          .byId(id)
          .ownership.put({ organizationId, membershipId, viewAsFamily: true }),
        body
      ),
  };
};
