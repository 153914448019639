import { atomWithStorage } from 'jotai/utils';

export type CheckingAccountsTabOptionValue = 'activity' | 'assets';

export const checkingAccountsTabPreferenceAtom = atomWithStorage<
  CheckingAccountsTabOptionValue | undefined
>('checkingAccountsTabPreference', undefined);

export type CreditAccountsTabOptionValue = 'activity' | 'assets';

export const creditAccountsTabPreferenceAtom = atomWithStorage<
  CreditAccountsTabOptionValue | undefined
>('creditAccountsTabPreference', undefined);

export type SavingsAccountsTabOptionValue = 'activity' | 'assets';

export const savingAccountsTabPreferenceAtom = atomWithStorage<
  SavingsAccountsTabOptionValue | undefined
>('savingAccountsTabPreference', undefined);

export type InvestmentAccountsTabOptionValue = 'activity' | 'assets';

export const investmentAccountsTabPreferenceAtom = atomWithStorage<
  InvestmentAccountsTabOptionValue | undefined
>('investmentAccountsTabPreference', undefined);

export type InvestmentAccountsWrapperTabOptionValue =
  | 'activity'
  | 'investments';

export const investmentAccountsWrapperTabPreferenceAtom = atomWithStorage<
  InvestmentAccountsWrapperTabOptionValue | undefined
>('investmentAccountsWrapperTabPreference', undefined);
