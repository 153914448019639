import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import { ApiGetProps, PortfolioFees } from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { useApi } from '../../useApi';

export type UsePortfolioInvestmentsByAccountFeesBaseProps = {
  accountId: string;
};

export type UsePortfolioInvestmentsByAccountFeesProps =
  UsePortfolioInvestmentsByAccountFeesBaseProps & ApiGetProps;

export const usePortfolioInvestmentsByAccountFees = ({
  accountId,
  skip,
  sharingLinkId,
  accessCode,
}: UsePortfolioInvestmentsByAccountFeesProps) => {
  const { membershipId, organizationId } = useFinaryApiConfig();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('new_format', 'true');

  return useApi<PortfolioFees>(
    API_ROUTES.portfolio.investments.accountById(accountId).fees.get({
      membershipId,
      organizationId,
      params,
    }),
    {
      keepPreviousData: true,
    },
    !!sharingLinkId,
    skip
  );
};
