import * as RadixDropdown from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';
import React from 'react';

import { Box } from '../Box';
import { Icon } from '../Icon';
import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from '../Polymorphic';
import { Typography } from '../Typography';

import {
  dropdownItemIndicatorStyles,
  dropdownItemLabelStyles,
  dropdownItemStyles,
  DropdownItemVariants,
} from './DropdownItem.css';

export type CheckboxItemProps = DropdownItemVariants &
  Pick<RadixDropdown.MenuCheckboxItemProps, 'checked' | 'onCheckedChange'>;

export type CheckboxItemComponentProps<C extends React.ElementType> =
  PolymorphicComponentPropWithRef<C, CheckboxItemProps>;

export type CheckboxItemComponent = <C extends React.ElementType = 'button'>(
  props: CheckboxItemComponentProps<C>
) => React.ReactNode | null;

export const CheckboxItem: CheckboxItemComponent & { displayName?: string } =
  React.forwardRef(
    <C extends React.ElementType = 'button'>(
      {
        as,
        className,
        checked,
        children,
        onCheckedChange,
        state,
        ...componentProps
      }: CheckboxItemComponentProps<C>,
      ref?: PolymorphicRef<C>
    ) => {
      const [checkedState, setCheckedState] = React.useState<boolean>();
      const isChecked = checked !== undefined ? checked : checkedState;
      const Component = as || 'button';
      return (
        <RadixDropdown.CheckboxItem
          asChild
          checked={isChecked}
          onSelect={(event) => event.preventDefault()}
          onCheckedChange={(value) => {
            setCheckedState(value);
            onCheckedChange?.(value);
          }}
        >
          <Component
            ref={ref}
            className={classNames(
              dropdownItemStyles({
                state: isChecked ? 'active' : state,
              }),
              className
            )}
            {...componentProps}
          >
            <Typography
              variant="bodyMedium"
              className={dropdownItemLabelStyles}
            >
              {children}
            </Typography>
            <Box className={dropdownItemIndicatorStyles}>
              <RadixDropdown.ItemIndicator>
                <Icon name="check" size="small" />
              </RadixDropdown.ItemIndicator>
            </Box>
          </Component>
        </RadixDropdown.CheckboxItem>
      );
    }
  );

CheckboxItem.displayName = 'CheckboxItem';
