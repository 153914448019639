import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

import { theme } from '../../styles/theme.css';
import { Box, BoxProps } from '../Box';

export type SkeletonProps = Pick<
  IContentLoaderProps,
  | 'width'
  | 'height'
  | 'radius'
  | 'backgroundColor'
  | 'foregroundColor'
  | 'className'
> & { boxProps?: BoxProps };

export const Skeleton = ({
  width,
  height,
  radius,
  backgroundColor = theme.color.surface.overlay,
  foregroundColor = theme.color.surface.disabled,
  boxProps,
  ...props
}: SkeletonProps) => (
  <Box display="inline-flex" data-testid="skeleton" {...boxProps}>
    <ContentLoader
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
      {...props}
    >
      <rect x="0" y="0" rx={radius} ry={radius} width={width} height={height} />
    </ContentLoader>
  </Box>
);
