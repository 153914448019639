import { useTranslation } from 'next-i18next';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Box } from 'ui/components/Box';
import { Button } from 'ui/components/Button';
import { Form } from 'ui/components/Form';
import { SplitField } from 'ui/components/TextField';
import { theme } from 'ui/styles/theme.css';

import { PreboardingSidePanel } from '/components/Preboarding/PreboardingSidePanel';
import { FinaryLogo } from '/components/layout/FinaryLogo';
import { SharingContext } from '/contexts/SharingProvider';
import { FINARY_API } from '/contexts/api';
import { useIsSharing } from '/hooks/sharing/useIsSharing';

export const SharingAccessCode = () => {
  return (
    <Box style={{ width: '100%', height: '100%' }}>
      <Box
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        overflowY="auto"
        overflowX="hidden"
        flex={1}
        style={{
          height: '100%',
          maxHeight: '100dvh',
        }}
      >
        <Box
          margin="s7"
          flexDirection="column"
          paddingHorizontal="s5"
          style={{
            maxWidth: 524,
            width: '100%',
            paddingBottom: theme.spacing.s6,
          }}
        >
          <FinaryLogo style={{ marginBottom: theme.spacing.s10 }} />
          <SharingAccessCodeForm />
        </Box>
      </Box>

      <PreboardingSidePanel />
    </Box>
  );
};

export type SharingAccessCodeFormValues = {
  code: string;
};

export type OtpMode = 'otp' | 'backup_code';

const SharingAccessCodeForm = () => {
  const { t } = useTranslation(['common']);
  const [error, setError] = React.useState<boolean>();
  const [invalidCode, setInvalidCode] = React.useState<string>();
  const { setAccessCode } = React.useContext(SharingContext);
  const { sharingLinkId } = useIsSharing();
  const form = useForm<SharingAccessCodeFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  // Handle form submission
  const submitForm: SubmitHandler<SharingAccessCodeFormValues> = async ({
    code,
  }) => {
    const res = await fetch(
      FINARY_API +
        `/users/me/sharing_links/${sharingLinkId}/validate?access_code=${code}`
    );
    if (res.status === 200) {
      setAccessCode(code);
    } else {
      if (res.status === 401) {
        setInvalidCode(code);
      }
      setError(true);
    }
  };

  return (
    <Form form={form} onSubmit={submitForm}>
      <Box
        flexDirection="column"
        gap="s7"
        style={{ maxWidth: 524, width: '100%' }}
      >
        <Box gap="s3" flexDirection="column">
          <SplitField
            autoFocus
            autoSubmit
            split={[3, 3]}
            label={t('common:sharing.access_code.title')}
            subLabel={t('common:sharing.access_code.description')}
            {...form.register('code', {
              validate: (value) =>
                /.{6}/.test(value) ||
                t('common:sharing.access_code.invalid_format'),
            })}
            error={
              error
                ? invalidCode === form.watch('code')
                  ? t('common:sharing.access_code.invalid_code')
                  : t('common:sharing.access_code.generic_error')
                : undefined
            }
          />
        </Box>
        <Box marginTop="s2">
          <Button
            size="large"
            disabled={!form.watch('code')?.match(/.{6}/)}
            style={{ flex: 1 }}
            isLoading={form.formState.isSubmitting}
          >
            {t('common:sharing.access_code.submit')}
          </Button>
        </Box>
      </Box>
    </Form>
  );
};
