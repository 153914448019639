import type { PortfolioBenchmark } from 'core-api';
import { atomWithStorage } from 'jotai/utils';

export type PortfolioBenchmarkAtom = PortfolioBenchmark & {
  color: string;
};

export const portfolioBenchmarkAtom = atomWithStorage<
  PortfolioBenchmarkAtom | undefined
>('portfolioBenchmark', undefined);
