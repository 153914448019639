import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import * as React from 'react';

import { Box } from '../Box';
import {
  CheckboxItem,
  Dropdown,
  DropdownControl,
  DropdownHeader,
  DropdownProps,
  RadioGroup,
  RadioItem,
} from '../Dropdown';
import { Skeleton } from '../Skeleton';

export type SelectOption<T> = {
  key: string;
  label: React.ReactNode;
  value: T;
  textValue?: string;
};

export type SelectOptionGroup<T> = {
  options: SelectOption<T>[];
  label?: React.ReactNode;
};

type OptionValue<T> = SelectOption<T>['value'];

export type BaseSelectProps<T extends string> = {
  children: React.ReactNode;
  options: (SelectOption<T> | SelectOptionGroup<T>)[];
  disabled?: boolean;
  isLoading?: boolean;
} & Omit<
  RadixDropdownMenu.DropdownMenuContentProps,
  'onChange' | 'defaultValue'
> &
  Pick<DropdownProps, 'modal'>;

export type MultiSelectProps<T extends string> = BaseSelectProps<T> & {
  multi: true;
  defaultValue?: T[];
  getControlLabel?: (selected: T[]) => string;
  onChange?: (selected: T[]) => void | Promise<unknown>;
  value?: T[];
};

export type SingleSelectProps<T extends string> = BaseSelectProps<T> & {
  defaultValue?: OptionValue<T>;
  multi?: false;
  onChange?: (selected: OptionValue<T>) => void | Promise<unknown>;
  value?: OptionValue<T>;
};

export type SelectProps<T extends string> =
  | MultiSelectProps<T>
  | SingleSelectProps<T>;

export const SELECT_LOADER_ITEMS = [...new Array(5)].map((_, i) => (
  <Box key={`skeleton-${i}`} style={{ height: '36px' }} padding="s3">
    <Skeleton height={20} width="160px" radius={4} />
  </Box>
));

const MultiSelect = <T extends string>({
  defaultValue,
  value,
  options,
  children,
  isLoading,
  onChange,
  getControlLabel,
  ...dropdownContentProps
}: MultiSelectProps<T>) => {
  const initialState = [...new Set(defaultValue)];
  const [selectedState, setSelectedState] = React.useState<T[]>(initialState);

  const selected = defaultValue ? selectedState : value;

  const mergedOptions = (
    (options[0] as SelectOptionGroup<T> | undefined)?.options
      ? (options as SelectOptionGroup<T>[]).flatMap((group) => group.options)
      : options
  ) as SelectOption<T>[];

  const items = isLoading
    ? SELECT_LOADER_ITEMS
    : mergedOptions.map((option) => (
        <CheckboxItem
          key={option.key}
          checked={selected?.includes(option.value)}
          onCheckedChange={(checked) =>
            setSelectedState((prevState) => {
              const state = value || prevState;
              const map = new Map(state.map((selected) => [selected, true]));
              map.set(option.value, checked);
              const selected = Array.from(map).filter(
                ([_key, selected]) => selected
              );
              const keys = selected.map(([key]) => key);
              onChange?.(keys);
              return keys;
            })
          }
        >
          {option.label}
        </CheckboxItem>
      ));

  const handleClick: React.MouseEventHandler = (event) => {
    event.preventDefault();
    const newSelected = selected?.length
      ? []
      : mergedOptions.map((option) => option.value);
    onChange?.(newSelected);
    setSelectedState(newSelected);
  };

  const itemsWithControl = [
    <DropdownControl key="control" onClick={handleClick}>
      {getControlLabel?.(selected ?? [])}
    </DropdownControl>,
    ...items,
  ];

  return (
    <Dropdown
      side="bottom"
      align="end"
      items={[getControlLabel ? itemsWithControl : items]}
      {...dropdownContentProps}
    >
      {children}
    </Dropdown>
  );
};

const SingleSelect = <T extends string>({
  defaultValue,
  value,
  options,
  children,
  isLoading,
  onChange,
  ...dropdownContentProps
}: SingleSelectProps<T>) => {
  const [selectedState, setSelectedState] = React.useState<T | undefined>(
    defaultValue
  );

  const selected = defaultValue ? selectedState : value;

  const groups = (
    (options[0] as SelectOptionGroup<T> | undefined)?.options
      ? options
      : [{ options: options }]
  ) as SelectOptionGroup<T>[];

  return (
    <Dropdown
      side="bottom"
      align="end"
      items={[
        isLoading
          ? SELECT_LOADER_ITEMS
          : groups.map(({ options, label }) => [
              <RadioGroup key="select" value={selected}>
                {label ? <DropdownHeader>{label}</DropdownHeader> : null}
                {options.map((option) => (
                  <RadioItem
                    textValue={option.textValue}
                    key={option.key}
                    value={option.value}
                    checked={selected === option.value}
                    onSelect={() => {
                      setSelectedState(option.value);
                      onChange?.(option.value);
                    }}
                  >
                    {option.label}
                  </RadioItem>
                ))}
              </RadioGroup>,
            ]),
      ]}
      onCloseAutoFocus={(event) => event.preventDefault()}
      {...dropdownContentProps}
    >
      {children}
    </Dropdown>
  );
};

export const Select = <T extends string>({
  multi,
  ...selectProps
}: SelectProps<T>) => {
  if (selectProps.value && selectProps.defaultValue) {
    throw new Error(
      'Select Component should be controlled or uncontrolled, please specify `defaultValue`, or `value`, but not both'
    );
  }

  if (multi) {
    return <MultiSelect {...(selectProps as MultiSelectProps<T>)} />;
  } else {
    return <SingleSelect {...(selectProps as SingleSelectProps<T>)} />;
  }
};
