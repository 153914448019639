import classNames from 'classnames';
import * as React from 'react';

import { Icon, IconName, IconSize } from '../Icon';
import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from '../Polymorphic';

import { iconButtonStyles, IconButtonVariants } from './IconButton.css';

type IconButtonBaseProps = {
  icon: IconName;
  iconClassName?: string;
} & IconButtonVariants;

export type IconButtonProps<C extends React.ElementType = 'button'> =
  PolymorphicComponentPropWithRef<C, IconButtonBaseProps>;

type IconButtonComponent = <C extends React.ElementType = 'button'>(
  props: IconButtonProps<C>
) => React.ReactNode | null;

const MAP_ICON_SIZE: Record<string, IconSize> = {
  large: 'large',
  medium: 'medium',
  small: 'small',
  xsmall: 'small',
};

export const IconButton: IconButtonComponent & { displayName?: string } =
  React.forwardRef(
    <C extends React.ElementType = 'button'>(
      {
        as,
        variant,
        icon,
        size = 'xsmall',
        className,
        onClick,
        iconClassName,
        ...componentProps
      }: IconButtonProps<C>,
      ref?: PolymorphicRef<C>
    ) => {
      const Component = as || 'button';
      const iconSize = MAP_ICON_SIZE[size ?? 'small'];
      const [loading, setLoading] = React.useState(false);
      const mountedRef = React.useRef(false);

      React.useEffect(() => {
        // This is for preventing setstate on unmounted component
        mountedRef.current = true;

        return () => {
          mountedRef.current = false;
        };
      }, []);

      const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
        if (onClick && !loading) {
          const res = onClick(e);

          if (res !== null && (res as unknown) instanceof Promise) {
            // This is to prevent icon blink if the loading time is really small
            if (mountedRef.current) {
              setLoading(true);
            }
            (res as unknown as Promise<void>).finally(() => {
              if (mountedRef.current) {
                setLoading(false);
              }
            });
          }
        }
      };

      return (
        <Component
          ref={ref}
          onClick={loading ? undefined : handleClick}
          className={classNames(iconButtonStyles({ size, variant }), className)}
          {...componentProps}
        >
          <Icon
            name={loading ? 'spinnerSolid' : icon}
            animation={loading ? 'rotate' : undefined}
            className={iconClassName}
            size={iconSize}
            style={{ padding: 1 }}
          />
        </Component>
      );
    }
  );

IconButton.displayName = 'IconButton';
