import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../config';
import { useApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import { User } from '../../types';

export const useDeleteCompany = (id?: string) => {
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    id ? API_ROUTES.companies.byId(id).delete() : null,
    async (url: string) =>
      fetchApi<User>(url, {
        method: 'DELETE',
        ...apiConfiguration,
      })
  );
};
