import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../../config';
import { useApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';

export const useDeleteCrowdlending = (assetId?: string) => {
  const apiConfiguration = useApiConfig();
  const { organizationId, membershipId } = apiConfiguration;

  return useSWRMutation(
    assetId
      ? API_ROUTES.crowdlendings.byId(assetId).delete({
          organizationId,
          membershipId,
        })
      : null,
    async (url: string) =>
      fetchApi<null>(url, {
        method: 'DELETE',
        ...apiConfiguration,
      })
  );
};
