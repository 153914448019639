import useSWR, { SWRResponse } from 'swr';
import useSWRInfinite, { SWRInfiniteResponse } from 'swr/infinite';

import { fetchApi, FetchApiError } from '../config';
import { useApiConfig } from '../providers';

export const DEFAULT_PAGINATED_PAGE_SIZE = 50;

export type UseApiConfig<Data> = Parameters<
  typeof useSWR<Data, FetchApiError>
>['2'];

export const useApi = <Data>(
  key: string | null,
  config?: UseApiConfig<Data>,
  isSharing?: boolean,
  skip?: boolean
): SWRResponse<Data, FetchApiError> & { isInitialLoading: boolean } => {
  const apiConfiguration = useApiConfig();

  const result = useSWR<Data, FetchApiError>(
    (apiConfiguration.getToken || isSharing) && !skip ? key : null,
    () => fetchApi(key, apiConfiguration),
    config
  );

  return {
    ...result,
    isInitialLoading: !result.data && !result.error && !skip,
  };
};

export type PaginatedResponseNewFormat<R, T = Record<string, never>> = {
  collection: R[];
  infos: {
    max_page: number;
    total: number;
  } & T;
};

export type PaginatedConfigNewFormat<Data, Infos> = Parameters<
  typeof useSWRInfinite<PaginatedResponseNewFormat<Data, Infos>, FetchApiError>
>['2'];

export type UsePaginatedApiReturnNewFormat<Data, Infos> = Omit<
  SWRInfiniteResponse<PaginatedResponseNewFormat<Data, Infos>, FetchApiError>,
  'data' | 'size' | 'setSize'
> & {
  dataByPage: SWRInfiniteResponse<
    PaginatedResponseNewFormat<Data, Infos>,
    FetchApiError
  >['data'];
  fetchMore: () => void;
  isInitialLoading: boolean;
  isLastPage: boolean;
  isLoadingMore: boolean;
  data?: PaginatedResponseNewFormat<Data, Infos>['collection'];
  infos?: PaginatedResponseNewFormat<Data, Infos>['infos'];
  isEmpty?: boolean;
};

/**
 * The previous format is returning only the data which is inconvenient as we don't have any informations about the collection
 * Thanks to this new format we can know the maximumn number of pages, total of elements and eventually additional infos if needed
 */
export const usePaginatedApiNewFormat = <Data, Infos>(
  getKey: (index: number) => string,
  config: PaginatedConfigNewFormat<Data, Infos> = undefined,
  pageSize: number = DEFAULT_PAGINATED_PAGE_SIZE,
  isSharing?: boolean,
  skip?: boolean
): UsePaginatedApiReturnNewFormat<Data, Infos> => {
  const apiConfiguration = useApiConfig();
  const result = useSWRInfinite<
    PaginatedResponseNewFormat<Data, Infos>,
    FetchApiError
  >(
    (index: number) =>
      apiConfiguration.getToken || isSharing ? getKey(index) : null,
    skip ? null : (key) => fetchApi(key, apiConfiguration),
    config
  );
  const {
    data,
    isLoading,
    isValidating,
    error,
    size,
    setSize,
    ...resultProps
  } = result;
  const isEmpty = !data || !data?.[0] || !data?.[0]?.collection.length;
  const lastLoadedPageCollection = data?.[data?.length - 1]?.collection ?? [];
  const isLastPage =
    isEmpty || (!!data && lastLoadedPageCollection.length < pageSize);

  return {
    ...resultProps,
    /**
     * The `isLoading` is not updated by SWR when fetching more (only when revalidation the whole list)
     * So we check that the size has been updated and that we don't have the data yet
     */
    isLoadingMore:
      isLoading ||
      (size > 1 && !!data && typeof data[size - 1] === 'undefined'),
    isLoading,
    isValidating,
    error,
    /**
     * SWR returns an array of fetch response values of each page
     * In the way we use the data it makes more sense to have it flattened.
     * We still keep it in `dataByPage` in case we need to display it by page only
     */
    data: data?.reduce<Data[]>((acc, dataPage) => {
      return [...acc, ...dataPage.collection];
    }, []),
    dataByPage: data,
    infos: data?.[data?.length - 1]?.infos,
    isInitialLoading: !data && !error,
    isLastPage,
    isEmpty,
    fetchMore: () => {
      /**
       * We only want to enable the fetchMore in case :
       * - There's already data loaded
       * - We are not currently loading a page
       * - There's not a request or revalidation loading
       * - We know that we didn't get the last element of the list
       */
      if (!!data && !isLoading && !isValidating && !isLastPage) {
        setSize(size + 1);
      }
    },
  };
};

export type PaginatedConfig<Data> = Parameters<
  typeof useSWRInfinite<Data[], FetchApiError>
>['2'];

export type UsePaginatedApiReturn<Data> = Omit<
  SWRInfiniteResponse<Data[], FetchApiError>,
  'data' | 'size' | 'setSize'
> & {
  dataByPage: SWRInfiniteResponse<Data[], FetchApiError>['data'];
  fetchMore: () => void;
  isInitialLoading: boolean;
  isLastPage: boolean;
  isLoadingMore: boolean;
  data?: Data[];
};

export const usePaginatedApi = <Data>(
  getKey: (index: number) => string,
  config: PaginatedConfig<Data> = undefined,
  pageSize: number = DEFAULT_PAGINATED_PAGE_SIZE,
  isSharing?: boolean,
  skip?: boolean
): UsePaginatedApiReturn<Data> => {
  const apiConfiguration = useApiConfig();

  const result = useSWRInfinite<Data[], FetchApiError>(
    (index: number) =>
      apiConfiguration.getToken || isSharing ? getKey(index) : null,
    skip ? null : (key) => fetchApi(key, apiConfiguration),
    config
  );
  const {
    data,
    isLoading,
    isValidating,
    error,
    size,
    setSize,
    ...resultProps
  } = result;
  const isEmpty = !data || !data?.[0] || !data?.[0]?.length;
  const lastLoadedPage = data?.[data?.length - 1] ?? [];
  const isLastPage = isEmpty || (!!data && lastLoadedPage.length < pageSize);

  return {
    ...resultProps,
    /**
     * The `isLoading` is not updated by SWR when fetching more (only when revalidation the whole list)
     * So we check that the size has been updated and that we don't have the data yet
     */
    isLoadingMore:
      isLoading ||
      (size > 1 && !!data && typeof data[size - 1] === 'undefined'),
    isLoading,
    isValidating,
    error,
    /**
     * SWR returns an array of fetch response values of each page
     * In the way we use the data it makes more sense to have it flattened.
     * We still keep it in `dataByPage` in case we need to display it by page only
     */
    data: data?.flat(),
    dataByPage: data,
    isInitialLoading: !data && !error,
    isLastPage,
    fetchMore: () => {
      /**
       * We only want to enable the fetchMore in case :
       * - There's already data loaded
       * - We are not currently loading a page
       * - There's not a request or revalidation loading
       * - We know that we didn't get the last element of the list
       */
      if (!!data && !isLoading && !isValidating && !isLastPage) {
        setSize(size + 1);
      }
    },
  };
};

export const usePublicApi = <Data>(
  key: string | null,
  config?: Parameters<typeof useSWR<Data, FetchApiError>>['2']
): SWRResponse<Data, FetchApiError> & { isInitialLoading: boolean } => {
  const apiConfiguration = useApiConfig();

  const result = useSWR<Data, FetchApiError>(
    key,
    () => fetchApi(key, apiConfiguration),
    config
  );

  return {
    ...result,
    isInitialLoading: !result.data && !result.error,
  };
};

export const usePostApi = <Data, Body = undefined>(key: string | null) => {
  const apiConfiguration = useApiConfig();
  return (...args: Body extends undefined ? [] : [Body]) =>
    fetchApi<Data>(apiConfiguration.getToken ? key : null, {
      ...apiConfiguration,
      method: 'POST',
      body: args[0] ?? {},
    });
};

export const usePutApi = <Data, Body>() => {
  const apiConfiguration = useApiConfig();
  return (key: string | null, body?: Body) =>
    fetchApi<Data>(apiConfiguration.getToken ? key : null, {
      ...apiConfiguration,
      method: 'PUT',
      body: body ?? {},
    });
};
