import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../../config';
import { useApiConfig, useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import { SCPIAccount, ScpiBodyData } from '../../../types';

export const useCreateScpi = () => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    API_ROUTES.scpis.post({ organizationId, membershipId }),
    async (url: string, { arg }: { arg: ScpiBodyData }) =>
      fetchApi<SCPIAccount>(url, {
        body: arg,
        method: 'POST',
        ...apiConfiguration,
      })
  );
};
