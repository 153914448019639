import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  MarkedFilterOption,
  NotSharingLinkProps,
  SharingLinkProps,
  Transaction,
  TransactionParentCategory,
  TransactionParams,
  TransactionsAssetCategory,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { usePaginatedApi, DEFAULT_PAGINATED_PAGE_SIZE } from '../../useApi';

export type UseTransactionsProps = (NotSharingLinkProps | SharingLinkProps) &
  Omit<TransactionParams, 'account_id' | 'transaction_category_id'> & {
    accountIds?: string[];
    assetCategory?: TransactionsAssetCategory;
    keepPreviousData?: boolean;
    marked?: MarkedFilterOption;
    pageSize?: number;
    skip?: boolean;
    transactionCategoryIds?: (number | string)[];
  };

export const useTransactions = ({
  sharingLinkId,
  accessCode,
  assetCategory,
  pageSize = DEFAULT_PAGINATED_PAGE_SIZE,
  dateRange,
  query,
  type,
  value,
  comparator,
  accountIds,
  institution_id,
  transactionCategoryIds,
  query_array,
  marked,
  skip,
  keepPreviousData = true,
}: UseTransactionsProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const params = createSharingLinkParams({ sharingLinkId, accessCode });

  const result = usePaginatedApi<Transaction>(
    (index: number) => {
      params.set('page', String(index + 1)); // API starts to page 1, not 0
      params.set('per_page', String(pageSize));
      if (marked !== 'all') {
        params.set('marked', marked === 'marked' ? 'true' : 'false');
      }
      if (query) {
        params.set('query', query);
      }
      if (query_array) {
        params.set('query_array', query_array.join(','));
      }
      if (type) {
        params.set('type', type);
      }
      if (institution_id) {
        params.set('institution_id', institution_id);
      }
      if (comparator && value) {
        params.set('comparator', comparator);
        params.set('value', String(value));
      }
      if (accountIds?.length) {
        params.set('account_id', accountIds.join(','));
      }
      if (transactionCategoryIds?.length) {
        params.set('transaction_category_id', transactionCategoryIds.join(','));
      }
      if (dateRange?.start_date && dateRange?.end_date) {
        params.set('period', dateRange.period);
        params.set('start_date', dateRange.start_date);
        params.set('end_date', dateRange.end_date);
      }

      return (
        assetCategory
          ? API_ROUTES.portfolio[assetCategory].transactions
          : API_ROUTES.transactions
      ).get({
        organizationId,
        membershipId,
        params,
      });
    },
    { keepPreviousData },
    pageSize,
    !!sharingLinkId,
    skip
  );

  return {
    ...result,
    mutateDeleteTransactionById: (id: number) =>
      result.mutate((transactionsPages) =>
        transactionsPages?.map((transactions) =>
          transactions.filter((transaction) => transaction.id !== id)
        )
      ),
    mutateDeleteTransactionByIds: (ids: number[]) =>
      result.mutate((transactionsPages) =>
        transactionsPages?.map((transactions) =>
          transactions.filter((transaction) => !ids.includes(transaction.id))
        )
      ),
    mutateTransactionById: (id: number, newData: Partial<Transaction>) =>
      result.mutate((transactionPages) =>
        transactionPages?.map((transactions) =>
          transactions.map((transaction) =>
            transaction.id === id ? { ...transaction, ...newData } : transaction
          )
        )
      ),
    mutateTransactionsByIds: (ids: number[], newData: Partial<Transaction>) =>
      result.mutate((transactionPages) =>
        transactionPages?.map((transactions) =>
          transactions.map((transaction) =>
            ids.includes(transaction.id)
              ? { ...transaction, ...newData }
              : transaction
          )
        )
      ),
    mutateTransactionWithUpdatedCategory: (
      updatedCategory: TransactionParentCategory
    ) => {
      result.mutate((transactionPages) =>
        transactionPages?.map((transactions) =>
          transactions.map((transaction) =>
            transaction.category.id === updatedCategory?.id
              ? { ...transaction, category: updatedCategory }
              : transaction
          )
        )
      );
    },
  };
};
