import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import { LoanFormData } from '../../../types';
import { usePutApi } from '../../useApi';

/**
 * This hook allow to pass the loan id to the trigger function and not to the hook itself.
 * It is useful when you want to update multiples loans.
 */
export const useRawPutLoan = () => {
  const put = usePutApi();
  const { organizationId, membershipId } = useFinaryApiConfig();

  return {
    trigger: (
      loanId: number | string,
      body: Partial<
        LoanFormData & {
          qualified: true;
          real_estate_id?: number | null;
          user_scpi_id?: number | null;
        }
      >
    ) =>
      put(
        API_ROUTES.loans.byId(loanId).put({ organizationId, membershipId }),
        body
      ),
  };
};
