import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import { ApiGetProps, PortfolioAccount } from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { usePeriodDisplayMode } from '../../configuration';
import { useApi } from '../../useApi';

export const usePortfolioFondsEurosAccounts = ({
  sharingLinkId,
  accessCode,
  skip,
}: ApiGetProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const { data: periodDisplayMode } = usePeriodDisplayMode(!!sharingLinkId);

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('period', periodDisplayMode);

  return useApi<PortfolioAccount[]>(
    API_ROUTES.portfolio.fonds_euro.accounts.get({
      organizationId,
      membershipId,
      params,
    }),
    undefined,
    !!sharingLinkId,
    skip
  );
};
