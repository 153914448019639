import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../../config';
import { useApiConfig, useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import { Loan, LoanFormData } from '../../../types';

export const useCreateLoan = () => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    API_ROUTES.loans.post({ organizationId, membershipId }),
    async (
      url: string,
      {
        arg,
      }: {
        arg: LoanFormData & {
          loan_type: 'loan' | 'mortgage' | 'consumercredit' | 'revolvingcredit';
        };
      }
    ) =>
      fetchApi<Loan>(url, {
        body: { ...arg, qualified: true },
        method: 'POST',
        ...apiConfiguration,
      })
  );
};
