import { atom, useAtom } from 'jotai';

import { WealthDisplayMode } from '../../types';

import { useUIConfiguration } from './useUIConfiguration';

const wealthDisplayModeAtom = atom<WealthDisplayMode>('gross');

export const useWealthDisplayMode = (isSharing?: boolean) => {
  const [sharingDisplayMode, setSharingDisplayMode] = useAtom(
    wealthDisplayModeAtom
  );
  const { data: configuration, update: updateUIConfiguration } =
    useUIConfiguration();

  return {
    data: isSharing ? sharingDisplayMode : configuration.wealth_display_mode,
    update: (wealthDisplayMode: WealthDisplayMode) =>
      isSharing
        ? setSharingDisplayMode(wealthDisplayMode)
        : updateUIConfiguration({ wealth_display_mode: wealthDisplayMode }),
  };
};
