import { Institution } from '../types';

const NO_SECRET_KEY_INSTITUTIONS = ['bitpanda', 'justmining'];
const HAS_API_KEY_INSTITUTION = ['binance', 'kraken'];
const PASSPHRASE_INSTITUTIONS = ['coinbase-pro', 'kucoin'];
const IMPORT_FILE_INSTITUTIONS = ['crypto-com', 'swissborg'];

const ETHEREUM_ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/;

export const isAddressEthereum = (address: string) =>
  ETHEREUM_ADDRESS_REGEX.test(address);

export const cryptoExchangeHasSecretKeyField = ({
  slug,
}: Pick<Institution, 'slug'>) => !NO_SECRET_KEY_INSTITUTIONS.includes(slug);

export const isCryptoAccountWithApiKey = ({
  slug,
}: Pick<Institution, 'slug'>) => HAS_API_KEY_INSTITUTION.includes(slug);

export const cryptoExchangeHasPassphraseField = ({
  slug,
}: Pick<Institution, 'slug'>) => PASSPHRASE_INSTITUTIONS.includes(slug);

export const isCryptoExchangeWithImport = ({
  slug,
}: Pick<Institution, 'slug'>) => IMPORT_FILE_INSTITUTIONS.includes(slug);
