import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../config';
import { useApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import { User } from '../../types';

export type UpdateFamilyMemberParams = {
  firstname: string;
  lastname?: string;
};

export const useUpdateFamilyMember = (id?: string) => {
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    id ? API_ROUTES.family.members.byId(id).put() : null,
    async (url: string, { arg }: { arg: UpdateFamilyMemberParams }) =>
      fetchApi<User>(url, {
        method: 'PUT',
        body: arg,
        ...apiConfiguration,
      })
  );
};
