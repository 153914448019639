import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  NotSharingLinkProps,
  OtherAssetsSummaryResponse,
  SharingLinkProps,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { usePeriodDisplayMode } from '../../configuration';
import { useApi } from '../../useApi';

export type UsePortfolioOtherAssetsProps =
  | NotSharingLinkProps
  | SharingLinkProps;

export const usePortfolioOtherAssets = ({
  sharingLinkId,
  accessCode,
}: UsePortfolioOtherAssetsProps = {}) => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const { data: periodDisplayMode } = usePeriodDisplayMode(!!sharingLinkId);

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('period', periodDisplayMode);

  return useApi<OtherAssetsSummaryResponse>(
    API_ROUTES.portfolio.other_assets.get({
      organizationId,
      membershipId,
      params,
    }),
    undefined,
    !!sharingLinkId
  );
};
