import { API_ROUTES } from '../../../routes';
import { RawSCPI } from '../../../types';
import { useApi } from '../../useApi';
import { useUser } from '../../users';

export const useMostPopularScpis = (limit = 20) => {
  const { data: user } = useUser();
  const params = new URLSearchParams();
  if (limit) {
    params.append('limit', limit.toString());
  }
  if (user?.country) {
    params.append('country', user.country);
  }

  return useApi<RawSCPI[]>(API_ROUTES.scpis.mostPopular(params), {
    keepPreviousData: true,
  });
};
