import { API_ROUTES } from '../../routes';
import { Capability } from '../../types';
import { FinaryConnectField } from '../../types/finary-connect';
import { useApi } from '../useApi';

export type FinaryConnectProviderResponse = {
  capabilities: Capability[];
  countries: [];
  credentials_format: {
    description: string;
    fields: FinaryConnectField[];
  };
  id: string;
  name: string;
  slug: string;
  version: number;
};

export const useFinaryConnectProvider = (
  correlationId: string | undefined | null
) => {
  return useApi<FinaryConnectProviderResponse>(
    correlationId
      ? API_ROUTES.connect.providers.byId(correlationId).get()
      : null
  );
};
