import { Sprinkles, sprinkles } from '../styles/sprinkles.css';

export const getSprinklesProps = (props: Record<string, unknown>) => {
  const sprinklesProps: Record<string, unknown> = {};
  const otherProps: Record<string, unknown> = {};

  for (const key in props) {
    if (isSprinklesProperty(key)) {
      sprinklesProps[key] = props[key];
    } else {
      otherProps[key] = props[key];
    }
  }

  return { sprinklesProps, otherProps };
};

const isSprinklesProperty = (key: string): key is keyof Sprinkles => {
  return sprinkles.properties.has(key as keyof Sprinkles);
};
