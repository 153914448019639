import { BankAccountSummary, PortfolioAccount, Total } from '../types';

/**
 * Merge a BankAccountSummary institutions with the given PortfolioAccounts
 * grouped by institution AND institution connection id
 * @param bankAccountSummary
 * @param accounts
 */
export const mergeBankAccountWithAccounts = (
  bankAccountSummary: BankAccountSummary | undefined,
  accounts: PortfolioAccount[] | undefined
) => {
  const grouped = new Map<
    string,
    {
      accounts: PortfolioAccount[];
      wrapper: PortfolioAccount;
    }
  >();

  accounts?.forEach((datum) => {
    if (datum.institution) {
      const key = `${datum.institution.id}:${
        datum.institution_connection?.id ?? ''
      }`;
      const previousAccounts: PortfolioAccount[] =
        grouped.get(key)?.accounts ?? [];
      grouped.set(key, {
        wrapper: datum,
        accounts: [...previousAccounts, datum],
      });
    }
  });

  const reconciliated = bankAccountSummary?.data?.map((datum) => {
    const key = `${datum.institution.id}:${
      datum.institution_connection?.id ?? ''
    }`;
    return {
      wrapper: datum,
      accounts: grouped.get(key)?.accounts ?? [],
    };
  });

  return reconciliated;
};

/**
 * Returns a total out of a given BankAccountSummary
 */
export const bankAccountSummaryToTotal = (
  summary: BankAccountSummary | undefined
): Total | undefined =>
  summary
    ? {
        amount: summary.display_total,
        display_upnl_difference: null,
        display_upnl_percent: null,
        upnl: null,
        upnl_percent: null,
      }
    : undefined;
