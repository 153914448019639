import { useFinaryApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import {
  GeographicalDiversificationSuggestion,
  ApiGetProps,
} from '../../types';
import { createSharingLinkParams } from '../../utils';
import { useApi } from '../useApi';

export const useGeographicalDiversificationSuggestion = ({
  skip,
  sharingLinkId,
  accessCode,
}: ApiGetProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });

  return useApi<GeographicalDiversificationSuggestion[]>(
    API_ROUTES.portfolio.geographical.suggestion.get({
      organizationId,
      membershipId,
      params,
    }),
    { keepPreviousData: true },
    !!sharingLinkId,
    skip
  );
};
