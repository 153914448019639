import { useFinaryApiConfig } from '../providers';
import { API_ROUTES } from '../routes';
import {
  ApiGetProps,
  BankAccountCategoryType,
  BankAccountType,
} from '../types';
import { createSharingLinkParams } from '../utils';

import { useApi } from './useApi';

export type UseBankAccountTypesProps = ApiGetProps & {
  byOrganization?: boolean;
  feesScanner?: boolean;
  type?: BankAccountCategoryType;
};

export const useBankAccountTypes = ({
  type,
  feesScanner,
  byOrganization,
  sharingLinkId,
  accessCode,
}: UseBankAccountTypesProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const params = createSharingLinkParams({ sharingLinkId, accessCode });

  if (type) {
    params.append('type', type);
  }
  if (feesScanner) {
    params.append('fees_scanner', 'true');
    params.append('with_fees', 'true');
  }

  return useApi<BankAccountType[]>(
    byOrganization
      ? API_ROUTES.bankAccountTypes.byOrganization.get({
          organizationId,
          membershipId,
          params,
        })
      : API_ROUTES.bankAccountTypes.get(params),
    undefined,
    !!sharingLinkId
  );
};
