import * as React from 'react';

export type SidebarState = 'expanded' | 'collapsed' | undefined;

export type SidebarSubnavState = boolean | undefined;

export type UIState = {
  currency: string;
  isSecretModeEnabled: boolean;
  locale: string;
  sidebar: SidebarState;
  sidebarSubnav: Map<string, SidebarSubnavState>;
};

export type UIActions = {
  setSidebar: (state: SidebarState) => void;
  setSidebarSubnav: (id: string, state: SidebarSubnavState) => void;
  setUserCurrency: (currency: string) => void;
  setUserLocale: (locale: string) => void;
  toggleSecretMode: (newValue?: boolean) => void;
};

const initialState: UIState = {
  isSecretModeEnabled: false,
  locale: 'en',
  currency: 'EUR',
  sidebar: undefined,
  sidebarSubnav: new Map(),
};

const initialActions: UIActions = {
  toggleSecretMode: () => undefined,
  setUserLocale: () => undefined,
  setUserCurrency: () => undefined,
  setSidebar: () => undefined,
  setSidebarSubnav: () => undefined,
};

const UIStateContext = React.createContext<UIState>(initialState);
const UIActionsContext = React.createContext<UIActions>(initialActions);

type UIProviderProps = {
  children: React.ReactNode;
  defaultLocale?: string;
};

// TODO - Refactor to extract Sidebar and SidebarSubnav in another context
//  because they are related only to the web app
export const UIProvider = ({
  children,
  defaultLocale,
}: UIProviderProps): React.ReactElement => {
  const [uiState, setUIState] = React.useState({
    ...initialState,
    locale: defaultLocale ?? initialState.locale,
  });

  // Watch out for referential identity, when nothing changes,
  // you should always return the previous state
  const actions: UIActions = React.useMemo(
    () => ({
      // Toggle to the next value
      toggleSecretMode: (newValue?: boolean) => {
        setUIState((prev) => ({
          ...prev,
          isSecretModeEnabled: newValue ?? !prev.isSecretModeEnabled,
        }));
      },
      // Set the user locale if it has changed
      setUserLocale: (userLocale: string) => {
        setUIState((prev) =>
          prev.locale !== userLocale ? { ...prev, locale: userLocale } : prev
        );
      },
      // Set the user currency if it has changed
      setUserCurrency: (userCurrency: string) => {
        setUIState((prev) =>
          prev.currency !== userCurrency
            ? { ...prev, currency: userCurrency }
            : prev
        );
      },
      // Set the sidebar state if it has changed
      setSidebar: (state: SidebarState) => {
        setUIState((prev) =>
          prev.sidebar !== state ? { ...prev, sidebar: state } : prev
        );
      },
      // Update our record for the opened / closed subnavs
      setSidebarSubnav: (id: string, state: SidebarSubnavState) => {
        setUIState((prev) => {
          const { sidebarSubnav } = prev;
          const prevStateValue = sidebarSubnav.get(id);
          const isNewValue = prevStateValue !== state;
          if (isNewValue) {
            if (state === undefined) {
              sidebarSubnav.delete(id);
            } else {
              sidebarSubnav.set(id, state);
            }
            return { ...prev, sidebarSubnav: new Map(sidebarSubnav) };
          }
          return prev;
        });
      },
    }),
    []
  );

  return (
    <UIStateContext.Provider value={uiState}>
      <UIActionsContext.Provider value={actions}>
        {children}
      </UIActionsContext.Provider>
    </UIStateContext.Provider>
  );
};

export function useUIState(): UIState {
  const context = React.useContext(UIStateContext);
  if (context === undefined) {
    throw new Error('useUIState must be used within an UIProvider');
  }
  return context;
}

export function useUIActions(): UIActions {
  const context = React.useContext(UIActionsContext);
  if (context === undefined) {
    throw new Error('useUIActions must be used within an UIProvider');
  }
  return context;
}
