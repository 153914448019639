import { useFinaryApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import {
  ApiGetProps,
  CashflowDateRangeReturn,
  DateRangePriod,
} from '../../types';
import { createSharingLinkParams } from '../../utils';
import { useApi } from '../useApi';

export type UseCashflowDateRangeProps = ApiGetProps & {
  period: DateRangePriod;
};

export const useCashflowDateRange = ({
  sharingLinkId,
  accessCode,
  period,
  skip,
}: UseCashflowDateRangeProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const params = createSharingLinkParams({ sharingLinkId, accessCode });

  params.set('period', period);

  return useApi<CashflowDateRangeReturn>(
    API_ROUTES.cashflow.dateRange.get({
      organizationId,
      membershipId,
      params,
    }),
    { keepPreviousData: true },
    !!sharingLinkId,
    skip
  );
};
