import { API_ROUTES } from '../../../routes';
import { useApi } from '../../useApi';

export type RealEstateAutocompleteResult = {
  address: string;
  place_id: string;
};

export const useRealEstatesAddressAutocomplete = (query: string) => {
  const params = new URLSearchParams();
  params.append('query', query);

  return useApi<RealEstateAutocompleteResult[]>(
    API_ROUTES.real_estates.autocomplete(params),
    { keepPreviousData: true, revalidateOnFocus: false },
    undefined,
    query.length === 0
  );
};
