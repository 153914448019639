import { useFinaryApiConfig } from '../providers';
import { API_ROUTES } from '../routes';
import { ApiGetProps, LoansAmortizationResponse } from '../types';
import { createSharingLinkParams } from '../utils';

import { useApi } from './useApi';

export type UseLoansAmortizationProps = {
  selectedLoans: string[];
  stepId?: string;
} & ApiGetProps;

export const useLoansAmortization = ({
  selectedLoans,
  sharingLinkId,
  accessCode,
  stepId,
}: UseLoansAmortizationProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('ids', selectedLoans.join(','));

  if (stepId) {
    params.append('step_id', stepId);
  }

  return useApi<LoansAmortizationResponse>(
    API_ROUTES.loans.amortization.get({
      organizationId,
      membershipId,
      params,
    }),
    {
      keepPreviousData: true,
    },
    !!sharingLinkId
  );
};
