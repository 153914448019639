import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  PortfolioAccount,
  TransactionsAssetCategory,
  TransactionDateRange,
  ApiGetProps,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { useApi } from '../../useApi';

export type UseAccountsWithTransactionsBaseProps = {
  assetCategory?: TransactionsAssetCategory;
  dateRange?: TransactionDateRange;
  transactionCategoryId?: string | number;
};

export type UseAccountsWithTransactionsProps = ApiGetProps &
  UseAccountsWithTransactionsBaseProps;

export const useAccountsWithTransactions = ({
  sharingLinkId,
  accessCode,
  assetCategory,
  dateRange,
  transactionCategoryId,
  skip = false,
}: UseAccountsWithTransactionsProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const params = createSharingLinkParams({ sharingLinkId, accessCode });

  params.append('with_transactions', 'true');

  if (assetCategory) {
    params.append('asset_category', assetCategory);
  }
  if (transactionCategoryId) {
    params.set('transaction_category_id', String(transactionCategoryId));
  }
  if (dateRange?.start_date && dateRange?.end_date) {
    params.set('period', dateRange.period);
    params.set('start_date', dateRange.start_date);
    params.set('end_date', dateRange.end_date);
  }

  const response = useApi<PortfolioAccount[]>(
    (assetCategory
      ? API_ROUTES.portfolio[assetCategory].accounts
      : API_ROUTES.holdingsAccounts
    ).get({
      organizationId,
      membershipId,
      params,
    }),
    { keepPreviousData: true },
    !!sharingLinkId,
    skip
  );

  return {
    ...response,
    data: response.data?.filter((account) => !!account?.has_transactions),
  };
};
