import { useFinaryApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import {
  NotSharingLinkProps,
  SharingLinkProps,
  CashflowDistributionParams,
  CashflowDistributionReturn,
} from '../../types';
import { createSharingLinkParams } from '../../utils';
import { useApi } from '../useApi';

export type UseCashflowDistributionProps = (
  | NotSharingLinkProps
  | SharingLinkProps
) &
  CashflowDistributionParams;

export const useCashflowDistribution = ({
  sharingLinkId,
  accessCode,
  type,
  dateRange,
}: UseCashflowDistributionProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const params = createSharingLinkParams({ sharingLinkId, accessCode });

  params.set('type', type);

  if (dateRange.start_date && dateRange.end_date) {
    params.set('period', dateRange.period);
    params.set('start_date', dateRange.start_date);
    params.set('end_date', dateRange.end_date);
  }

  return useApi<CashflowDistributionReturn>(
    dateRange.start_date && dateRange.end_date
      ? API_ROUTES.cashflow.distribution.get({
          organizationId,
          membershipId,
          params,
        })
      : null,
    { keepPreviousData: false },
    !!sharingLinkId
  );
};
