import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../config';
import { useApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import { NotSharingLinkProps, SharingLinkProps, User } from '../../types';
import { useUser } from '../users';

type UIConfigurationParams = Partial<User['ui_configuration']>;

export type UseUIConfigurationProps = NotSharingLinkProps | SharingLinkProps;

export const useUIConfiguration = ({
  sharingLinkId,
  accessCode,
}: UseUIConfigurationProps = {}) => {
  const apiConfiguration = useApiConfig();

  const {
    data: user,
    mutate,
    isLoading,
  } = useUser({ sharingLinkId, accessCode });

  // PUT
  // TODO try to abstract the useSWRMutation with useApiMutation
  // eg: useMutationApi<User>(API_ROUTES.users.put, { method: 'PUT', body: { ... } })
  const { trigger, isMutating } = useSWRMutation(
    API_ROUTES.uiConfiguration.put(),
    async (url: string, { arg: body }: { arg: UIConfigurationParams }) => {
      return mutate(
        fetchApi<User>(url, {
          body,
          method: 'PUT',
          ...apiConfiguration,
        }),
        {
          optimisticData: user
            ? {
                ...user,
                ui_configuration: { ...user.ui_configuration, ...body },
              }
            : undefined,
        }
      );
    }
  );

  return {
    data: {
      period_display_mode: user?.ui_configuration?.period_display_mode ?? 'all',
      wealth_display_mode:
        user?.ui_configuration?.wealth_display_mode ?? 'gross',
      ...user?.ui_configuration,
    },
    isLoading,
    update: trigger,
    isMutating: isMutating,
  };
};
