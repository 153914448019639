export const FINARY_CONNECT_CONNECTION_STATE_ERRORS = [
  'INSTITUTION_ERROR',
  'WRONG_PASSWORD',
  'PROVIDER_ERROR',
  'WRONG_2FA_CODE',
  'TIMEOUT_2FA_CODE',
  'TIMEOUT_EXTERNAL_AUTH',
  'AUTH_REQUIRED',
  'SCA_REQUIRED',
  'ACTION_NEEDED',
];
