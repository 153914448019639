import { HoldingsAccount, Total } from '../types';

export const mapHoldingsAccountToTotal = (
  holdingsAccount: Pick<
    HoldingsAccount,
    | 'display_balance'
    | 'display_upnl_difference'
    | 'upnl_difference'
    | 'display_upnl_percent'
    | 'upnl_percent'
  >
): Total => ({
  amount: holdingsAccount.display_balance,
  display_upnl_difference: holdingsAccount.display_upnl_difference,
  display_upnl_percent: holdingsAccount.display_upnl_percent,
  upnl: holdingsAccount.upnl_difference,
  upnl_percent: holdingsAccount.upnl_percent,
});

export const isHoldingAccountManual = (
  account: Pick<HoldingsAccount, 'institution_connection'>
) => !account.institution_connection?.correlation_id;

export const getHoldingAccountLogoUrl = (account: HoldingsAccount) => {
  if (account.scpis.length || account.real_estates.length) {
    if (!isHoldingAccountManual(account)) {
      return account.institution?.logo_url ?? account.logo_url;
    }
  }
  return account.logo_url;
};
