import { FinaryApiDate } from './api';

export enum SubscriptionStatus {
  free = 'free',
  trial = 'trial',
  premium = 'premium',
  lite = 'lite',
  trial_lite = 'trial_lite',
}

export enum SubscriptionPlatform {
  stripe = 'stripe',
  app_store = 'app_store',
  play_store = 'play_store',
}

export enum SubscriptionRenewalPeriod {
  month = 'month',
  year = 'year',
  week = 'week',
  day = 'day',
}

export type Subscription = {
  subscription_cancel_at: FinaryApiDate;
  subscription_current_period_end_at: FinaryApiDate;
  subscription_customer_id: string;
  subscription_payment_failed: boolean;
  subscription_platform: SubscriptionPlatform;
  subscription_price_with_tax_included: number;
  subscription_price_without_tax_included: number;
  subscription_renewal_period: SubscriptionRenewalPeriod;
  subscription_status: SubscriptionStatus;
};

export type SubscriptionDetails = Subscription & {
  last_non_free_subscription_details: Subscription;
};
