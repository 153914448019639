import * as RadixDropdown from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';
import React from 'react';

import { Box } from '../Box';
import { Icon } from '../Icon';
import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from '../Polymorphic';
import { Typography } from '../Typography';

import {
  dropdownItemIndicatorStyles,
  dropdownItemLabelStyles,
  dropdownItemStyles,
  DropdownItemVariants,
} from './DropdownItem.css';

export type RadioItemProps = DropdownItemVariants &
  Pick<RadixDropdown.MenuRadioItemProps, 'value' | 'onSelect' | 'textValue'> & {
    checked?: boolean;
  };

export type RadioItemComponentProps<C extends React.ElementType> =
  PolymorphicComponentPropWithRef<C, RadioItemProps>;

export type RadioItemComponent = <C extends React.ElementType = 'button'>(
  props: RadioItemComponentProps<C>
) => React.ReactNode | null;

export const RadioItem: RadioItemComponent & { displayName?: string } =
  React.forwardRef(
    <C extends React.ElementType = 'button'>(
      {
        as,
        className,
        children,
        onSelect,
        state,
        value,
        textValue,
        checked,
        ...componentProps
      }: RadioItemComponentProps<C>,
      ref?: PolymorphicRef<C>
    ) => {
      const Component = as || 'button';
      return (
        <RadixDropdown.RadioItem
          value={value}
          textValue={textValue}
          asChild
          onSelect={(event) => {
            onSelect?.(event);
          }}
        >
          <Component
            ref={ref}
            className={classNames(
              dropdownItemStyles({ state: checked ? 'active' : state }),
              className
            )}
            {...componentProps}
          >
            <Typography
              variant="bodyMedium"
              className={dropdownItemLabelStyles}
            >
              {children}
            </Typography>
            <Box className={dropdownItemIndicatorStyles}>
              <RadixDropdown.ItemIndicator>
                <Icon name="check" size="small" />
              </RadixDropdown.ItemIndicator>
            </Box>
          </Component>
        </RadixDropdown.RadioItem>
      );
    }
  );

RadioItem.displayName = 'RadioItem';

export const RadioGroup = RadixDropdown.RadioGroup;
