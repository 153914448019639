import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import { ApiGetProps, LoanStep } from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { useApi } from '../../useApi';

export type UseLoanStepsProps = {
  id: number | undefined;
} & ApiGetProps;

export const useLoanSteps = ({
  id,
  sharingLinkId,
  accessCode,
  skip,
}: UseLoanStepsProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const params = createSharingLinkParams({ sharingLinkId, accessCode });

  return useApi<LoanStep[]>(
    id
      ? API_ROUTES.loans.byId(id).steps.get({
          organizationId,
          membershipId,
          params,
        })
      : null,
    undefined,
    !!sharingLinkId,
    skip || !id
  );
};
