import type { ProvidersAction } from 'core-api';
import { atomWithStorage } from 'jotai/utils';

/**
 * This atom is used to store the current provider synchronization happening.
 */
export const providerSynchronizationAtom = atomWithStorage<
  | {
      action: ProvidersAction;
      logo_url: string | undefined | null;
    }
  | undefined
>('providerSynchronization', undefined);
