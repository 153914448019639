import { AnalyticsBrowser } from '@segment/analytics-next';
import type {
  ApiProductPlan,
  User,
  QuestionnaireOnboardingOrganizationNeeds,
  TransactionParams,
  TransactionsAssetCategory,
  DateRangePriod,
  CashflowType,
  Transaction,
  TransactionCategory,
  LoanCategory,
} from 'core-api';
import { InvestFrequency } from 'core-api/types/invest';
import { JSONObject } from 'utils';

import { LoansQualificationStep } from '/types/assets/loan';

const SEGMENT_KEY = process.env.NEXT_PUBLIC_SEGMENT_KEY as string;

export const INTERNAL_ERROR = 'internal_error' as const;

export const PROVIDER_ERROR = 'provider_error' as const;

export const INVALID_PARAMETERS = 'invalid_parameters' as const;

export type SegmentError =
  | typeof INTERNAL_ERROR
  | typeof PROVIDER_ERROR
  | typeof INVALID_PARAMETERS;

export type ActivityRangeChangeSource = 'activity' | 'category';

export type FeedbackContext =
  | 'geographical_diversification'
  | 'sector_diversification';

export type InsightTypes =
  | 'fees'
  | 'geo diversification'
  | 'monthly recap'
  | 'sector diversification'
  | 'dividends';

const TRIAL_CLICK_ORIGIN_FOOTER = [
  'activity_category_list',
  'insights_dividends_drawer',
  'insights_fees_drawer',
  'insights_geographical_drawer',
  'insights_leaderboard_drawer',
  'insights_sector_drawer',
  'insights_dividends_drawer',
  'insights_leaderboard_drawer',
  'wealth_statement_pdf_export',
  'transactions_list',
] as const;

export type TrialClickOriginFooter = (typeof TRIAL_CLICK_ORIGIN_FOOTER)[number];

const TRIAL_CLICK_ORIGIN_COMMUNITY = [
  'community_banner',
  'community_sidebar_CTA',
  'community_header_CTA',
] as const;

export const TRIAL_CLICK_ORIGIN = [
  ...TRIAL_CLICK_ORIGIN_FOOTER,
  ...TRIAL_CLICK_ORIGIN_COMMUNITY,
  'add_account_member_ownwership',
  'add_company_member_ownwership',
  'edit_repartition_with_company_ownership',
  'select_company_view',
  'select_organization_view',
  'activity_top_card',
  'asset_limitation',
  'asset_limitation_manual',
  'header_cta',
  'insights_leaderboard_inverstor',
  'cashflow_category_sankey_chart',
  'insights_leaderboard_ranking',
  'insights_monthly_recap',
  'insights_monte_carlo',
  'insights_top_card',
  'investment_account_insights_header',
  'investment_details_insights_header',
  'loans_insights_capacity',
  'loans_insights_duration',
  'loans_insights_header',
  'loans_insights_leverage',
  'loans_insights_monthly_payment',
  'loans_insights_taegs',
  'onboarding_skip_bank',
  'onboarding',
  'asset_limitation_paywall_modal',
  'portfolio_diversification_card',
  'real_estate_details_sm_price',
  'real_etates_insights_header',
  'scpis_details_insights_header',
  'scpis_details_ror',
  'settings_header_cta',
  'settings_header_cta',
  'settings_profiles_add_member',
  'settings_profiles_add_member',
  'settings_profiles_member_action',
  'settings_profiles_member_action',
  'settings_subscription_cta',
  'sidebar_cta',
  'startup_insights_header',
  'startup_insights',
  'settings_header_cta',
  'settings_profiles_add_member',
  'settings_profiles_member_action',
  'settings_profiles_add_company',
  'settings_profiles_company_action',
  'transaction_add_category',
  'cashflow_sankey_chart',
  'cashflow_set_target',
  'cashflow_subcategories_tab',
  'cashflow_transaction_list',
  'cashflow_out_chart',
  'cashflow_category_list',
  'transaction_rule_modal',
  'transaction_edit_cta',
  'transaction_change_category',
  'transaction_add_category',
  'transaction_include_analysis',
  'transaction_toggle_marked',
  'transaction_filter_search',
  'waltio_banner_save_with_plus',
  'invest_bronze_advantages_cta',
  'portfolio_benchmark',
] as const;

export type TrialClickOrigin = (typeof TRIAL_CLICK_ORIGIN)[number];

export type BenchmarkContextOrigin = 'stock_and_funds';

export type Event =
  | {
      event: 'Trial Clicked';
      properties: {
        trial_clicked_origin: TrialClickOrigin;
      };
    }
  | { event: 'Web Insights Monte Carlo Relaunch'; properties?: never }
  | {
      event: 'Web Insights Card Clicked';
      properties: {
        web_insights_card_clicked_type: InsightTypes;
      };
    }
  | {
      event: 'Web Insights Panel CTA';
      properties: {
        web_insights_panel_cta_origin: InsightTypes;
      };
    }
  | {
      event: 'Web user feedback';
      properties: {
        web_feedback_page: string;
        web_feedback_reply: 'up' | 'down';
        web_feedback_origin?: FeedbackContext;
      };
    }
  | {
      event: 'Web Login Success';
      properties?: never;
    }
  | {
      event: 'Web Signup Success';
      properties: { email?: User['email'] };
    }
  | {
      event: 'Web Login Failed';
      properties: {
        web_login_failed_error: SegmentError;
        sentryEventId?: string;
      };
    }
  | {
      event: 'Web Signup Failed';
      properties: {
        web_signup_failed_error: SegmentError;
      };
    }
  | {
      event: 'Web Onboarding Questionnaire Level';
      properties: {
        web_onboarding_questionnaire_investor_level: string;
      };
    }
  | {
      event: 'Web Onboarding Questionnaire Country';
      properties: {
        web_onboarding_questionnaire_country: string;
      };
    }
  | {
      event: 'Web Onboarding Questionnaire Goal';
      properties: {
        web_onboarding_questionnaire_objective_type: string;
      };
    }
  | {
      event: 'Web Onboarding Questionnaire Organizations';
      properties: {
        web_onboarding_questionnaire_organizations_needs: QuestionnaireOnboardingOrganizationNeeds;
      };
    }
  | {
      event: 'Web Onboarding Questionnaire Source';
      properties: {
        web_onboarding_questionnaire_source: string;
      };
    }
  | {
      event: 'Web Onboarding Investor Goal Track Clicked';
      properties?: never;
    }
  | {
      event: 'Web Onboarding Investor Goal Budget Clicked';
      properties?: never;
    }
  | {
      event: 'Web Onboarding Investor Goal Crypto Clicked';
      properties?: never;
    }
  | {
      event: 'Web Chart Export';
      properties: {
        web_chart_export_type:
          | 'tree_map'
          | 'pie_chart'
          | 'cashflow_category_sankey_chart'
          | 'cashflow_sankey_chart';
      };
    }
  | {
      event: 'Web Add Asset Success';
      properties: {
        web_add_asset_success_addition_type: string;
      };
    }
  | {
      event: 'Web Add Asset Failed';
      properties: {
        web_add_asset_failed_error: string;
      };
    }
  | {
      event: 'Trial Checkout Started';
      properties: {
        trial_checkout_started_plan: ApiProductPlan;
      };
    }
  | {
      event: 'Add Asset Holding Account Edit';
      properties?: never;
    }
  | {
      event: 'Plus Checkout Started';
      properties: {
        plus_checkout_started_plan: Extract<
          ApiProductPlan,
          'annual' | 'monthly'
        >;
      };
    }
  | {
      event: 'Lite Checkout Started';
      properties?: never;
    }
  | {
      event: 'Pro Checkout Started';
      properties?: never;
    }
  | {
      event: 'Web shortcut used';
      properties: {
        web_shortcut: 'cmdk' | 'ctrlk';
      };
    }
  | {
      event: 'Web Loan Qualification Success';
      properties?: never;
    }
  | {
      event: 'Web Sharing Icon Clicked';
      properties: {
        sharing_clicked_origin: string;
      };
    }
  | {
      event: 'Web Sharing Link Generated';
      properties: {
        sharing_link_generated_assets: string[];
        sharing_link_generated_hidden_data: boolean;
        sharing_link_generated_security_code: boolean;
      };
    }
  | {
      event: 'Web Signup From Sharing';
      properties?: never;
    }
  | {
      event: 'Web Yearly Upgrade Banner';
      properties?: never;
    }
  | {
      event: 'Web Subscription Payment Failed';
      properties: {
        web_subscription_payment_failed_plan:
          | ApiProductPlan
          | 'weekly'
          | 'daily';
      };
    }
  | {
      event: 'Web Subscription Expired';
      properties: {
        web_subscription_expired_plan: ApiProductPlan | 'weekly' | 'daily';
      };
    }
  | {
      event: 'Web Wealth Statement Document Created';
      properties?: never;
    }
  | {
      event: 'Web Wealth Statement Page Viewed';
      properties: {
        web_wealth_statement_origin: 'sidebar_tools' | 'header' | 'tab_change';
      };
    }
  | {
      event: 'Web Advisor Test CTA Clicked';
      properties?: never;
    }
  | {
      event: 'Web Advisor Post Stripe Return';
      properties?: {
        web_advisor_post_stripe_return: 'success' | 'cancel' | 'fail';
      };
    }
  | {
      event: 'Web Invest Banner Clicked';
      properties?: never;
    }
  | {
      event: 'Web Invest Banner Closed';
      properties?: never;
    }
  | {
      event: 'Web Life Insurance Banner Clicked';
      properties?: never;
    }
  | {
      event: 'Web Life Insurance Banner Closed';
      properties?: never;
    }
  | {
      event: 'Web Life Insurance Modal Clicked';
      properties?: never;
    }
  | {
      event: 'Web Life Insurance Modal Closed';
      properties?: never;
    }
  | {
      event: 'Web Life Insurance Action Card Clicked';
      properties?: never;
    }
  | {
      event: 'Web Invest Life Insurance Clicked';
      properties?: never;
    }
  | {
      event: 'Web Transaction Filters Apply Tapped';
      properties: TransactionParams;
    }
  | {
      event: 'Web Transaction category change';
      properties: {
        new_category: string;
        previous_category: string;
      };
    }
  | {
      event: 'Web Add Asset Institution Multiple Accounts Link Tapped';
      properties?: never;
    }
  | {
      event: 'Web Add Asset Institution Manual Sync Link Tapped';
      properties?: never;
    }
  | {
      event: 'Web Add Asset Institution Manual Sync Help Link Tapped';
      properties?: never;
    }
  | {
      event: 'Web Add Asset Institution Powens Help Link Tapped';
      properties?: never;
    }
  | {
      event: 'Web Transaction subcategory change';
      properties: {
        new_subcategory: string;
        previous_subcategory?: string;
      };
    }
  | {
      event: 'Web Cashflow Filters Apply Tapped';
      properties: {
        account_ids?: string[];
        category_ids?: number[];
      };
    }
  | {
      event: 'Web Transaction edit category name';
      properties: {
        new: TransactionCategory;
        previous: TransactionCategory;
      };
    }
  | {
      event: 'Web Transaction create custom category';
      properties: {
        name: string;
      };
    }
  | {
      event: 'Web Transaction create custom subcategory';
      properties: {
        name: string;
        parent_name: string;
      };
    }
  | {
      event: 'Web Tap on a transaction';
      properties: {
        account_id: string;
        value: string;
        source?: TransactionsAssetCategory;
      };
    }
  | {
      event: 'Web Track transaction tab on Wrapper level';
      properties: {
        source: TransactionsAssetCategory;
        account_id?: string;
      };
    }
  | {
      event: 'Web Referral Link Shared';
      properties: {
        web_referral_link_shared_type: 'full_link' | 'code';
      };
    }
  | {
      event: 'Web Cashflow Tapped';
      properties: {
        tab: CashflowType;
      };
    }
  | {
      event: 'Web Cashflow Drilled';
      properties: {
        categoryName: string;
        origin:
          | 'cashflow_out_chart'
          | 'cashflow_sankey_chart'
          | 'cashflow_list';
      };
    }
  | {
      event: 'Web Cashflow Subcategory Drilled';
      properties: {
        categoryName: string;
        origin:
          | 'cashflow_category_pie_chart'
          | 'cashflow_category_sankey_chart'
          | 'cashflow_category_list';
      };
    }
  | {
      event: 'Web Sidebar Cashflow tapped';
      properties?: never;
    }
  | {
      event: 'Web Transaction Edited';
      properties?: {
        date?: string;
        name?: string;
      };
    }
  | {
      event: 'Web Smart Rule Created';
      properties: {
        new_cat: string;
        words: string;
        initial_cat?: string;
        transaction?: Transaction;
      };
    }
  | {
      event: 'Web Transaction MultiSelect Set';
      properties: {
        transaction_count: number;
        include_in_analysis?: boolean;
        is_custom_category?: boolean;
        marked?: boolean;
        new_category_name?: string;
      };
    }
  | {
      event: 'Web Transaction Set Include In Analysis';
      properties: {
        include_in_analysis?: boolean;
      };
    }
  | {
      event: 'Web Transaction Set Marked';
      properties: {
        marked?: boolean;
      };
    }
  | {
      event: 'Web Budget Create Target';
      properties: {
        category: string;
        should_reach_target: boolean;
        target: number;
      };
    }
  | {
      event: 'Web Budget Edit Target';
      properties: {
        category: string;
        should_reach_target: boolean;
        target: number;
      };
    }
  | {
      event: 'Web Budget Delete Target';
      properties: {
        category: string;
        should_reach_target: boolean;
        target: number;
      };
    }
  | {
      event: 'Web Budget Target Tapped';
      properties?: never;
    }
  | {
      event: 'Web Budget Subcategory Tab Tapped';
      properties?: never;
    }
  | {
      event: 'Web Cashflow Range Changed';
      properties: {
        period: DateRangePriod;
        source: ActivityRangeChangeSource;
      };
    }
  | {
      event: 'Web Tools Wealth Simulator Clicked';
      properties?: never;
    }
  | {
      event: 'Web Tools Budget Calculator Clicked';
      properties?: never;
    }
  | {
      event: 'Web Tools Blog Clicked';
      properties?: never;
    }
  | {
      event: 'Web Tools Crypto Screener Clicked';
      properties?: never;
    }
  | {
      event: 'Web Tools Compound Interest Clicked';
      properties?: never;
    }
  | {
      event: 'Web Light Mode Settings change';
      properties?: never;
    }
  | {
      event: 'Web Dark Mode Settings change';
      properties?: never;
    }
  | {
      event: 'Web System Mode Settings change';
      properties?: never;
    }
  | {
      event: 'Web Notification Center Clicked';
      properties?: never;
    }
  | {
      event: 'Web Newsfeed Card Tapped';
      properties?: {
        web_newsfeed_card_tapped_type: string;
      };
    }
  | {
      event: 'Web Newsfeed Card Closed';
      properties?: {
        web_newsfeed_card_closed_type: string;
      };
    }
  | {
      event: 'Web Waltio Banner Clicked';
      properties: {
        origin: 'cryptos' | 'account_wrapper' | 'top_banner' | 'sidebar_tools';
      };
    }
  | {
      event: 'Web Waltio Banner Closed';
      properties: {
        origin: 'cryptos' | 'account_wrapper' | 'top_banner';
      };
    }
  | {
      event: 'Web Waltio Helpdesk 99 Clicked';
      properties?: never;
    }
  | {
      event: 'Web Waltio Link Clicked';
      properties?: never;
    }
  | {
      event: 'Web Post Subscription Landing Viewed';
      properties?: never;
    }
  | {
      event: 'Web Post Subscription Synchro Viewed';
      properties?: never;
    }
  | {
      event: 'Web Post Subscription Budget Viewed';
      properties?: never;
    }
  | {
      event: 'Web Post Subscription Fees Viewed';
      properties?: never;
    }
  | {
      event: 'Web Post Subscription Dividends Viewed';
      properties?: never;
    }
  | {
      event: 'Web Post Subscription Diversification Viewed';
      properties?: never;
    }
  | {
      event: 'Web Post Subscription Invest Viewed';
      properties?: never;
    }
  | {
      event: 'Web Post Subscription Exit Viewed';
      properties?: never;
    }
  | {
      event: 'Web Post Subscription Support Viewed';
      properties?: never;
    }
  | {
      event: 'Web Post Subscription Holding Viewed';
      properties?: never;
    }
  | {
      event: 'Web Synchronizations Center Clicked';
      properties?: never;
    }
  | {
      event: 'Web Benchmark Selected';
      properties: {
        benchmark: string;
        page: BenchmarkContextOrigin;
      };
    }
  | {
      event: 'Web Invest Deposit Clicked';
      properties: {
        origin:
          | 'header'
          | 'fiat_drawer'
          | 'fiat_menu_action'
          | 'balance_section'
          | 'balance_empty_state'
          | 'trade_insufficient_balance'
          | 'vip_status_drawer';
      };
    }
  | {
      event: 'Web Invest Buy Clicked';
      properties: {
        origin:
          | 'trading_section_chips'
          | 'position_menu_action'
          | 'position_drawer';
      };
    }
  | {
      event: 'Web Invest Sell Clicked';
      properties: {
        origin:
          | 'trading_section_chips'
          | 'position_menu_action'
          | 'position_drawer';
      };
    }
  | {
      event: 'Web Invest Swap Clicked';
      properties: {
        origin:
          | 'trading_section_chips'
          | 'position_menu_action'
          | 'position_drawer';
      };
    }
  | {
      event: 'Web Invest Trading Preview Clicked';
      properties: {
        source_asset_id: string | undefined;
        target_asset_id: string | undefined;
      };
    }
  | {
      event: 'Web Invest Trading Summary Clicked';
      properties: {
        source_asset_id: string | undefined;
        target_asset_id: string | undefined;
      };
    }
  | {
      event: 'Web Invest Savings Plan Clicked';
      properties?: never;
    }
  | {
      event: 'Web Invest Vip Status Clicked';
      properties: {
        origin:
          | 'header'
          | 'saving_plan_drawer'
          | 'trading_section_summary'
          | 'saving_plan_summary';
      };
    }
  | {
      event: 'Web Invest Trading Success Continue Clicked';
      properties?: never;
    }
  | {
      event: 'Web Invest Trading Success View Transactions Clicked';
      properties?: never;
    }
  | {
      event: 'Web Invest KYC Business Clicked';
      properties?: never;
    }
  | {
      event: 'Web Invest Onboarding Step Viewed';
      properties: {
        id: string;
      };
    }
  | {
      event: 'Web Invest KYC Started';
      properties?: never;
    }
  | {
      event: 'Web Invest Crypto Transactions Export Clicked';
      properties?: never;
    }
  | {
      event: 'Web Invest Crypto Accounting Report Export Clicked';
      properties?: never;
    }
  | {
      event: 'Web Fees Complete Contract Modal Open';
      properties?: never;
    }
  | {
      event: 'Web Fees Incomplete Contract Click';
      properties?: never;
    }
  | {
      event: 'Web Fees Explanation Video Click';
      properties?: never;
    }
  | {
      event: 'Web Manual Fees Edition';
      properties?: never;
    }
  | {
      event: 'Web Fees Finary Contract Waiting List Click';
      properties?: never;
    }
  | {
      event: 'Web Loans Qualification Flow Opened';
      properties?: never;
    }
  | {
      event: 'Web Loans Qualification Flow Success';
      properties?: {
        category: LoanCategory;
      };
    }
  | {
      event: 'Web Loans Qualification Flow Step Accessed';
      properties: {
        step?: LoansQualificationStep;
      };
    }
  | {
      event: 'Web Loans Step Selected';
      properties: {
        loanId: number;
        step?: string | null;
      };
    }
  | {
      event: 'Web Invest Most Popular Plan Selected';
      properties: {
        planId: string;
      };
    }
  | {
      event: 'Web Invest Most Popular Plan Drawer Viewed';
      properties: {
        planId: string;
      };
    }
  | {
      event: 'Web Invest Most Popular Plan Learn More Clicked';
      properties: {
        assetId: string;
        planId: string;
      };
    }
  | {
      event: 'Web Invest Savings Plan Preview Changes Clicked';
      properties?: never;
    }
  | {
      event: 'Web Invest Savings Plan Created';
      properties: {
        assetIds: string[];
        frequency: InvestFrequency;
        total: number;
      };
    }
  | {
      event: 'Web Invest Savings Plan Edited';
      properties: {
        assetIds: string[];
        frequency: InvestFrequency;
        total: number;
      };
    }
  | {
      event: 'Web Invest Savings Plan Paused';
      properties?: never;
    }
  | {
      event: 'Web Invest Savings Plan Resumed';
      properties?: never;
    }
  | {
      event: 'Web Invest Savings Plan Deleted';
      properties?: never;
    }
  | {
      event: 'Web Invest Deposit Credit Card Success';
      properties: {
        web_invest_deposit_credit_card_amount: string;
      };
    }
  | {
      event: 'Web Invest Deposit Credit Card Failed';
      properties: {
        web_invest_deposit_credit_card_error?: string;
      };
    }
  | {
      event: 'Web Session Revoked';
      properties: {
        device_type?: string;
      };
    };

const segmentBrowser = new AnalyticsBrowser();

const init = () => segmentBrowser.load({ writeKey: SEGMENT_KEY });

const identify = (user: User, additionalProperties: JSONObject = {}) =>
  segmentBrowser.identify(
    user.slug,
    { email: user.email, ...additionalProperties },
    { Intercom: { user_hash: user.intercom_secure_hash } }
  );

const track = (event: Event) =>
  segmentBrowser.track(event.event, event.properties);

const page = () => segmentBrowser.page('WEBAPP');

const reset = segmentBrowser.reset;

export const analytics = {
  init,
  identify,
  track,
  page,
  reset,
};
