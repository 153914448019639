import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  NotSharingLinkProps,
  RealEstate,
  SharingLinkProps,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { useApi } from '../../useApi';

export type UseRealEstatesByIdBaseProps = {
  realEstateId: string | number | undefined;
};

export type UseRealEstatesByIdWithoutSharingProps =
  UseRealEstatesByIdBaseProps & NotSharingLinkProps;

export type UseRealEstatesByIdWithSharingProps = UseRealEstatesByIdBaseProps &
  SharingLinkProps;

export type UseRealEstatesByIdProps =
  | UseRealEstatesByIdWithoutSharingProps
  | UseRealEstatesByIdWithSharingProps;

export const useRealEstatesById = ({
  realEstateId,
  sharingLinkId,
  accessCode,
}: UseRealEstatesByIdProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });

  return useApi<RealEstate>(
    realEstateId
      ? API_ROUTES.real_estates.byId(realEstateId).get({
          organizationId,
          membershipId,
          params,
        })
      : null,
    undefined,
    !!sharingLinkId
  );
};
