import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../../config';
import { useApiConfig, useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import { CrowdlendingAccount, CurrencyCode } from '../../../types';

export type CreateCrowdlendingParams = {
  account: {
    id: string;
  };
  currency: {
    code: CurrencyCode;
  };
  current_price: number;
  name: string;
  annual_yield?: number | null;
  initial_investment?: number | null;
  month_duration?: number | null;
  start_date?: Date | string;
};

export const useCreateCrowdlending = () => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    API_ROUTES.crowdlendings.post({ organizationId, membershipId }),
    async (url: string, { arg }: { arg: CreateCrowdlendingParams }) =>
      fetchApi<CrowdlendingAccount>(url, {
        body: arg,
        method: 'POST',
        ...apiConfiguration,
      })
  );
};
