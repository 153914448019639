import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  HoldingsAccount,
  NotSharingLinkProps,
  SharingLinkProps,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { usePeriodDisplayMode } from '../../configuration';
import { useApi } from '../../useApi';

export type UsePortfolioRealEstatesByAccountBaseProps = {
  accountId: string | undefined;
};

export type UsePortfolioRealEstatesByAccountWithoutSharingProps =
  UsePortfolioRealEstatesByAccountBaseProps & NotSharingLinkProps;

export type UsePortfolioRealEstatesByAccountWithSharingProps =
  UsePortfolioRealEstatesByAccountBaseProps & SharingLinkProps;

export type UsePortfolioRealEstatesByAccountProps =
  | UsePortfolioRealEstatesByAccountWithoutSharingProps
  | UsePortfolioRealEstatesByAccountWithSharingProps;

export const usePortfolioRealEstatesByAccount = ({
  accountId,
  sharingLinkId,
  accessCode,
}: UsePortfolioRealEstatesByAccountProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const { data: periodDisplayMode } = usePeriodDisplayMode(!!sharingLinkId);

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('period', periodDisplayMode);

  return useApi<HoldingsAccount>(
    accountId
      ? API_ROUTES.portfolio.real_estates.accountById(accountId).get({
          organizationId,
          membershipId,
          params,
        })
      : null,
    undefined,
    !!sharingLinkId
  );
};
