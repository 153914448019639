import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  NotSharingLinkProps,
  RealEstatesDistributionResponse,
  SharingLinkProps,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { useApi } from '../../useApi';

export type RealEstatesDistributionType = 'type' | 'name';

export type UsePortfolioRealEstatesDistributionBaseProps = {
  type: RealEstatesDistributionType;
};

export type UsePortfolioRealEstatesDistributionWithoutSharingProps =
  UsePortfolioRealEstatesDistributionBaseProps & NotSharingLinkProps;

export type UsePortfolioRealEstatesDistributionWithSharingProps =
  UsePortfolioRealEstatesDistributionBaseProps & SharingLinkProps;

export type UsePortfolioRealEstatesDistributionProps =
  | UsePortfolioRealEstatesDistributionWithoutSharingProps
  | UsePortfolioRealEstatesDistributionWithSharingProps;

export const usePortfolioRealEstatesDistribution = ({
  type,
  sharingLinkId,
  accessCode,
}: UsePortfolioRealEstatesDistributionProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('type', type);

  return useApi<RealEstatesDistributionResponse>(
    API_ROUTES.portfolio.real_estates.distribution.get({
      organizationId,
      membershipId,
      params,
    }),
    { keepPreviousData: true },
    !!sharingLinkId
  );
};
