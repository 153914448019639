import { atomWithStorage } from 'jotai/utils';

import type { FeedbackContext } from '/services/analytics';

export const feedbackAtom = atomWithStorage<Record<FeedbackContext, boolean>>(
  'feedbacks',
  {
    geographical_diversification: false,
    sector_diversification: false,
  }
);
