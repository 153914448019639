import {
  AllocationResponse,
  DiversificationType,
  GeographicalDiversificationResult,
  GeographicalDiversificationSuggestion,
  NotSharingLinkProps,
  SectorDiversificationResult,
  SectorDiversificationSuggestion,
  SharingLinkProps,
} from '../../types';

import { useGeographicalDiversification } from './useGeographicalDiversification';
import { useGeographicalDiversificationAllocation } from './useGeographicalDiversificationAllocation';
import { useGeographicalDiversificationSuggestion } from './useGeographicalDiversificationSuggestion';
import { usePortfolioSectorDiversification } from './useSectorDiversification';
import { useSectorDiversificationAllocation } from './useSectorDiversificationAllocation';
import { useSectorDiversificationSuggestion } from './useSectorDiversificationSuggestion';

export type UseDiversificationSidePanelOverviewResult = {
  isLoading: boolean;
  allocation?: AllocationResponse;
  diversification?:
    | GeographicalDiversificationResult
    | SectorDiversificationResult;
  suggestions?:
    | GeographicalDiversificationSuggestion[]
    | SectorDiversificationSuggestion[];
};

export type UseDiversificationSidePanelOverviewBaseProps = {
  type: DiversificationType;
};

export type UseDiversificationSidePanelOverviewWithoutSharingProps =
  UseDiversificationSidePanelOverviewBaseProps & NotSharingLinkProps;

export type UseDiversificationSidePanelOverviewWithSharingProps =
  UseDiversificationSidePanelOverviewBaseProps & SharingLinkProps;

export type UseDiversificationSidePanelOverviewProps =
  | UseDiversificationSidePanelOverviewWithoutSharingProps
  | UseDiversificationSidePanelOverviewWithSharingProps;

export const useDiversificationSidePanelOverview = ({
  type,
  sharingLinkId,
  accessCode,
}: UseDiversificationSidePanelOverviewProps): UseDiversificationSidePanelOverviewResult => {
  // Diversification
  const {
    data: geographicalDiversification,
    isLoading: isGeographicalDiversificationLoading,
  } = useGeographicalDiversification({
    skip: type !== 'geographical',
    sharingLinkId,
    accessCode,
  });
  const {
    data: sectorDiversification,
    isLoading: isSectorDiversificationLoading,
  } = usePortfolioSectorDiversification({
    skip: type !== 'sector',
    sharingLinkId,
    accessCode,
  });

  // Suggestion
  const {
    data: geographicalSuggestion,
    isLoading: isGeographicalSuggestionLoading,
  } = useGeographicalDiversificationSuggestion({
    skip: type !== 'geographical',
    sharingLinkId,
    accessCode,
  });
  const { data: sectorSuggestion, isLoading: isSectorSuggestionLoading } =
    useSectorDiversificationSuggestion({
      skip: type !== 'sector',
      sharingLinkId,
      accessCode,
    });

  // Allocation
  const {
    data: geographicalAllocation,
    isLoading: isGeographicalAllocationLoading,
  } = useGeographicalDiversificationAllocation({
    skip: type !== 'geographical',
    sharingLinkId,
    accessCode,
  });
  const { data: sectorAllocation, isLoading: isSectorAllocationLoading } =
    useSectorDiversificationAllocation({
      skip: type !== 'sector',
      sharingLinkId,
      accessCode,
    });

  return {
    isLoading:
      isGeographicalAllocationLoading ||
      isSectorAllocationLoading ||
      isSectorSuggestionLoading ||
      isGeographicalSuggestionLoading ||
      isGeographicalDiversificationLoading ||
      isSectorDiversificationLoading,
    suggestions: geographicalSuggestion ?? sectorSuggestion,
    diversification: geographicalDiversification ?? sectorDiversification,
    allocation: geographicalAllocation ?? sectorAllocation,
  };
};
