import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  UseFormReturn,
} from 'react-hook-form';

export type FormProps<T extends FieldValues> = {
  form: UseFormReturn<T>;
  onSubmit?: SubmitHandler<T>;
} & Omit<React.ComponentProps<'form'>, 'onSubmit'>;

export const Form = <T extends FieldValues>({
  form,
  onSubmit,
  children,
  ...props
}: FormProps<T>) => (
  <FormProvider {...form}>
    <form
      {...props}
      onSubmit={onSubmit ? form.handleSubmit(onSubmit) : undefined}
    >
      {children}
    </form>
  </FormProvider>
);
