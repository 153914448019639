import { AssetCategory, AssetCategoryNewFormat } from '../../types';
import { newAssetCategoryToOldFormat } from '../../utils/assetCategory';

import { useSyncStatus } from './useSyncStatus';

export const useSyncingAssetCategories = <T extends boolean = false>(
  newFormat?: T
) => {
  const { data, isLoading, isInitialLoading, isValidating } = useSyncStatus();

  const syncingCategories: Set<AssetCategoryNewFormat> = new Set();
  let oneKnownIsSyncing = false;

  if (data?.real_estates === false) {
    oneKnownIsSyncing = true;
    syncingCategories.add('real_estates');
  }
  if (data?.investment_accounts === false) {
    oneKnownIsSyncing = true;
    syncingCategories.add('investments');
  }
  if (data?.cryptos === false) {
    oneKnownIsSyncing = true;
    syncingCategories.add('cryptos');
  }
  if (data?.checking_accounts === false) {
    oneKnownIsSyncing = true;
    syncingCategories.add('checkings');
  }
  if (data?.credit_accounts === false) {
    oneKnownIsSyncing = true;
    syncingCategories.add('credits');
  }
  if (data?.savings_accounts === false) {
    oneKnownIsSyncing = true;
    syncingCategories.add('savings');
  }
  if (data?.bank_connections === false) {
    if (!oneKnownIsSyncing) {
      syncingCategories.add('startups');
    }
    // Other possible categories are covered by the other flags
    // syncingCategories.add('investments');
    // syncingCategories.add('checkings');
    // syncingCategories.add('savings');
    // syncingCategories.add('credits');
  }

  const loadedData = newFormat
    ? syncingCategories
    : new Set(
        [...syncingCategories].map((category) =>
          newAssetCategoryToOldFormat(category)
        )
      );

  return {
    data: data
      ? (loadedData as T extends true
          ? Set<AssetCategoryNewFormat>
          : Set<AssetCategory>)
      : undefined,
    isLoading,
    isInitialLoading,
    isValidating,
  };
};
