import { useFinaryApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import {
  NotSharingLinkProps,
  SharingLinkProps,
  CashflowAvailableMoneyReturn,
  TransactionDateRange,
} from '../../types';
import { createSharingLinkParams } from '../../utils';
import { useApi } from '../useApi';

export type UseCashflowAvailableMoneyProps = (
  | NotSharingLinkProps
  | SharingLinkProps
) & {
  dateRange: TransactionDateRange;
};

export const useCashflowAvailableMoney = ({
  sharingLinkId,
  accessCode,
  dateRange,
}: UseCashflowAvailableMoneyProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const params = createSharingLinkParams({ sharingLinkId, accessCode });

  if (dateRange.start_date && dateRange.end_date) {
    params.set('period', dateRange.period);
    params.set('start_date', dateRange.start_date);
    params.set('end_date', dateRange.end_date);
  }

  return useApi<CashflowAvailableMoneyReturn>(
    dateRange.start_date && dateRange.end_date
      ? API_ROUTES.cashflow.availableMoney.get({
          organizationId,
          membershipId,
          params,
        })
      : null,
    { keepPreviousData: false },
    !!sharingLinkId
  );
};
