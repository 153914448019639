import { API_ROUTES } from '../routes';
import { DividendsResponse } from '../types';

import { usePutApi } from './useApi';

export type UpdateDividendPayments = {
  amount?: number;
  hidden?: boolean;
  received_at?: string;
};

export const useUpdateDividendPayments = () => {
  const put = usePutApi<DividendsResponse, UpdateDividendPayments>();

  return {
    trigger: (id: number, body: UpdateDividendPayments) =>
      put(API_ROUTES.dividendPayments.byId(id).put(), body),
  };
};
