/**
 * @type {import('next-i18next').UserConfig}
 */
const i18nextConfig = {
  debug: false,
  i18n: {
    defaultLocale: 'en',
    locales: ['en', 'fr', 'de'],
    localeDetection: false,
  },
  react: {
    transWrapTextNodes: 'span', // Prevent react from crashing in some cases when using Trans component and Google Translate https://github.com/i18next/react-i18next/issues/1323#issue-902897267
  },
  fallbackLng: {
    default: ['en'],
  },
  localePath:
    // workaround about Vercel issue
    // https://github.com/i18next/next-i18next/issues/1552#issuecomment-981156476
    typeof window === 'undefined'
      ? // eslint-disable-next-line @typescript-eslint/no-var-requires
        require('path').resolve('./public/locales')
      : '/locales',

  reloadOnPrerender: process.env.NODE_ENV === 'development',
};

module.exports = i18nextConfig;
