import { useFinaryApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import { Capability, InstitutionWithProviders } from '../../types';
import { useApi } from '../useApi';

export const useInstitutionsAutocomplete = (
  name: string,
  capabilities?: Capability[],
  provider?: string
) => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const params = new URLSearchParams();
  params.append('name', name);
  if (capabilities) {
    params.append('capability', capabilities.join(','));
  }
  if (provider) {
    params.append('provider', provider);
  }

  return useApi<InstitutionWithProviders[]>(
    API_ROUTES.institutions.autocomplete.get({
      params,
      organizationId,
      membershipId,
    }),
    {
      keepPreviousData: true,
    }
  );
};
