import { ORGANIZATION_VIEW_ID } from '../routes';
import { Company, Membership } from '../types';

import { getUserDisplayFullName, getUserDisplayName } from './user';

export const getMemberDisplayFullName = (membership?: Membership) => {
  if (membership?.member_type === 'User') {
    return getUserDisplayFullName(membership.member);
  }

  return (membership?.member as Company)?.name;
};

export const getMemberDisplayName = (membership?: Membership) => {
  if (membership?.member_type === 'User') {
    return getUserDisplayName(membership.member) ?? '';
  }

  return (membership?.member as Company)?.name;
};

export const isAllOrganizationMembershipId = (membershipId?: string | null) =>
  membershipId === ORGANIZATION_VIEW_ID;
