import { ResponseError } from '../config';

export const isApiResponseError = (error: unknown): error is ResponseError => {
  return !!error && error instanceof ResponseError;
};

export const isSpecificInvalidParameterApiError = (
  error: unknown,
  parameterName: string
): boolean => {
  if (isApiResponseError(error)) {
    const { code, parameters } = error.body.error;

    return code === 'INVALID_PARAMETERS' && parameters?.[0] === parameterName;
  }

  return false;
};

export const getInvalidParameterFieldFromApiError = (
  error: unknown
): string | undefined => {
  if (
    isApiResponseError(error) &&
    error.body.error.code === 'INVALID_PARAMETERS'
  ) {
    return error.body.error.parameters?.[0];
  }

  return undefined;
};
