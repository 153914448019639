import * as React from 'react';
import { useSharingLinkPublicInfoById } from 'core-api/hooks/sharing/useSharingLinkPublicInfoById';
import { useRouter } from 'next/router';

import { APP_ROUTE, sharingRoute } from '/utils/url';
import { useIsSharing } from '/hooks/sharing/useIsSharing';

export const useSharingAuthorization = () => {
  const { sharingLinkId } = useIsSharing();
  const { isLoading, displayCashflowFeature } =
    useSharingLinkPublicInfoById(sharingLinkId);
  const router = useRouter();

  React.useEffect(() => {
    if (!isLoading) {
      if (!displayCashflowFeature && router.pathname.includes('cashflow')) {
        router.push(sharingRoute(APP_ROUTE.dashboard(), sharingLinkId));
      }
    }
  }, [isLoading, displayCashflowFeature, router, sharingLinkId]);
};
