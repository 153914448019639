import { Asset, AssetCategory, AssetCategoryNewFormat } from '../types';

export const ASSET_CATEGORIES: Asset[] = [
  'checking_accounts',
  'commodities',
  'crowdlendings',
  'cryptos',
  'fonds_euro',
  'investment_accounts',
  'other_assets',
  'real_estates',
  'savings_accounts',
  'startups',
];

export const FINANCIAL_ASSETS_CATEGORIES: (
  | AssetCategory
  | AssetCategoryNewFormat
)[] = [
  'savings',
  'savings_accounts',
  'investments',
  'investment_accounts',
  'cryptos',
  'fonds_euro',
  'commodities',
];
