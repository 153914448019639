import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../../config';
import { useApiConfig, useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import { Loan, LoanFormData } from '../../../types';

export const useEditLoan = (loanId: number) => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    API_ROUTES.loans.byId(loanId).put({ organizationId, membershipId }),
    async (url: string, { arg }: { arg: LoanFormData }) =>
      fetchApi<Loan>(url, {
        body: { ...arg, qualified: true },
        method: 'PUT',
        ...apiConfiguration,
      })
  );
};
