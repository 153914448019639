import { useFinaryApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import { ApiGetProps, PortfolioInsights } from '../../types';
import { createSharingLinkParams } from '../../utils';
import { useApi } from '../useApi';

export const usePortfolioInsights = ({
  sharingLinkId,
  accessCode,
}: ApiGetProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });

  return useApi<PortfolioInsights>(
    API_ROUTES.portfolio.insights.get({
      organizationId,
      membershipId,
      params,
    }),
    { keepPreviousData: true },
    !!sharingLinkId
  );
};
