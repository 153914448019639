export const groupBy = <T>(
  arr: T[],
  getGroupKey: (item: T) => string | number
) => {
  return arr.reduce<Record<string, T[]>>((acc, curr) => {
    const groupKey = getGroupKey(curr);

    return { ...acc, [groupKey]: [...(acc[groupKey] ?? []), curr] };
  }, {});
};
