import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../config';
import { useApiConfig, useFinaryApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';

export const useDeleteInstitutionConnection = (institutionId?: string) => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    institutionId
      ? API_ROUTES.institution_connections
          .byId(institutionId)
          .delete({ organizationId, membershipId })
      : null,
    async (url: string) =>
      fetchApi(url, {
        method: 'DELETE',
        ...apiConfiguration,
      })
  );
};
