import { fetchApi } from '../../config';
import { useApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import { FinaryConnectSynchronization } from '../../types/finary-connect';

/** This hook doesn't use swr because we pass a dynamic id to the fetch call */
export const useFetchFinaryConnectSynchronization = () => {
  const apiConfiguration = useApiConfig();

  return (connectionId: string, synchronizationId: string) =>
    fetchApi<FinaryConnectSynchronization>(
      API_ROUTES.connect.connections
        .byId(connectionId)
        .synchronizations.byId(synchronizationId)
        .get(),
      {
        method: 'GET',
        ...apiConfiguration,
      }
    );
};
