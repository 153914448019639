import * as React from 'react';

import { Box } from '../Box';
import { Icon } from '../Icon';

export const DropdownFilterLabel = ({
  from,
  to,
}: {
  from: React.ReactNode;
  to: React.ReactNode;
}) => {
  return (
    <Box gap="s2" alignItems="center">
      <span>{from}</span>
      <Icon name="arrowRight" size="small" />
      <span>{to}</span>
    </Box>
  );
};
