import { Range } from '../types';

export const rangeList: Range[] = [
  { key: '1d', rangeInDays: 1 },
  { key: '1w', rangeInDays: 7 },
  { key: '1m', rangeInDays: 30 },
  { key: 'ytd', rangeInDays: null },
  { key: '1y', rangeInDays: 365 },
  { key: 'all', rangeInDays: null },
];
