import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  NotSharingLinkProps,
  SharingLinkProps,
  ConnectionWithInstitutionAndAccounts,
  TransactionsCashAssetCategory,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { useApi } from '../../useApi';

export type UseInstitutionAccountsWithTransactionsBaseProps =
  | {
      assetCategory: TransactionsCashAssetCategory;
      connectionId: string;
      institutionId: string;
      skip?: boolean;
    }
  | {
      skip: true;
      assetCategory?: TransactionsCashAssetCategory;
      connectionId?: string;
      institutionId?: string;
    };

export type UseInstitutionAccountsWithTransactionsProps = (
  | NotSharingLinkProps
  | SharingLinkProps
) &
  UseInstitutionAccountsWithTransactionsBaseProps;

export const useInstitutionAccountsWithTransactions = ({
  sharingLinkId,
  accessCode,
  institutionId,
  connectionId,
  assetCategory,
  skip,
}: UseInstitutionAccountsWithTransactionsProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const params = createSharingLinkParams({ sharingLinkId, accessCode });

  if (connectionId) {
    params.append('institution_connection_id', connectionId);
  }

  const response = useApi<ConnectionWithInstitutionAndAccounts>(
    !skip && !!institutionId && !!assetCategory
      ? API_ROUTES.portfolio[assetCategory].institutionById(institutionId).get({
          organizationId,
          membershipId,
          params,
        })
      : null,
    { keepPreviousData: true },
    !!sharingLinkId
  );

  return {
    ...response,
    data: response?.data?.accounts?.filter(
      (account) => !!account?.has_transactions
    ),
  };
};
