import classNames from 'classnames';
import * as React from 'react';
import {
  FieldValues,
  FormState,
  useFormContext,
  UseFormRegisterReturn,
} from 'react-hook-form';
import { isDefined, isValidDate } from 'utils';

import { theme } from '../../styles/theme.css';
import { Box } from '../Box';
import { Icon } from '../Icon';
import { IconButton } from '../IconButton';
import { Tooltip, TooltipProps } from '../Tooltip';
import { Typography } from '../Typography';

import {
  textFieldContainerStyles,
  textFieldInputDatePlaceholderStyles,
  textFieldInputDisabledStyles,
  textFieldInputStyles,
  textFieldLabelActiveStyles,
  textFieldLabelStyles,
} from './TextField.css';

export type TextFieldProps = UseFormRegisterReturn &
  Pick<
    React.ComponentProps<'input'>,
    | 'className'
    | 'type'
    | 'placeholder'
    | 'step'
    | 'autoFocus'
    | 'value'
    | 'onFocus'
    | 'onPaste'
    | 'spellCheck'
    | 'readOnly'
    | 'inputMode'
    | 'autoComplete'
    | 'autoCorrect'
    | 'pattern'
  > & {
    name: string;
    error?: React.ReactNode;
    hint?: React.ReactNode | ((hasFocus: boolean) => React.ReactNode);
    /** Allows to keep the default style of the input even if it's disabled */
    inert?: boolean;
    label?: React.ReactNode;
    optionalLabel?: string;
    prefix?: React.ReactNode;
    style?: React.CSSProperties;
    success?: React.ReactNode;
    suffix?: React.ReactNode;
    tooltip?: Pick<
      TooltipProps,
      'title' | 'description' | 'size' | 'usePortal'
    >;
    warning?: React.ReactNode;
  };

export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      hint,
      success,
      warning,
      error,
      label,
      className,
      optionalLabel,
      tooltip,
      prefix,
      suffix,
      style,
      inert,
      required = false,
      ...props
    },
    ref
  ) => {
    const labelRef = React.useRef<HTMLLabelElement>(null);
    const [hasFocus, setHasFocus] = React.useState(false);

    const form = useFormContext();
    const { defaultValues } = form.formState;

    const fieldError = form.getFieldState(props.name, form.formState)?.error;
    const hasDefaultValue =
      typeof defaultValues?.[props.name] === 'string'
        ? isDefined(defaultValues?.[props.name]) &&
          defaultValues?.[props.name] !== ''
        : isDefined(defaultValues?.[props.name]);
    const hasValue =
      typeof form.watch(props.name) === 'string'
        ? isDefined(form.watch(props.name)) && form.watch(props.name) !== ''
        : isDefined(form.watch(props.name));
    const hasPlaceholder = !!props.placeholder;
    const hasPrefix = !!prefix;
    const isDateInput = props.type === 'date';
    const isDateInputValueValid = isValidDate(form.watch(props.name));

    // Toggle label active class depending on input value
    const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (
      event
    ) => {
      const hasValue = event.target.value.length > 0;
      labelRef.current?.classList.toggle(
        textFieldLabelActiveStyles,
        hasValue || hasPlaceholder || isDateInput
      );
      props.onChange(event);
    };

    const onFocus = (event: React.FocusEvent<HTMLInputElement, Element>) => {
      props.onFocus?.(event);
      setHasFocus(true);
    };

    const onBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
      props.onBlur?.(event);
      setHasFocus(false);
    };

    return (
      <Box flexDirection="column" style={style}>
        <Box
          className={classNames(
            textFieldContainerStyles({ error: !!(fieldError || error) }),
            className
          )}
          data-disabled={props.disabled && !inert}
        >
          {label || optionalLabel || tooltip ? (
            <Typography
              as="label"
              ref={labelRef}
              htmlFor={props.name}
              className={classNames(
                textFieldLabelStyles,
                hasPlaceholder ||
                  hasDefaultValue ||
                  hasValue ||
                  hasPrefix ||
                  isDateInput
                  ? textFieldLabelActiveStyles
                  : undefined
              )}
              color="tertiary"
            >
              {label}
              {!required && optionalLabel ? (
                <Typography variant="bodySmall" color="tertiary">
                  {optionalLabel}
                </Typography>
              ) : null}
              {tooltip ? (
                <Tooltip
                  size={tooltip.size || 'small'}
                  title={tooltip.title}
                  description={tooltip.description}
                  usePortal={tooltip.usePortal}
                  contentProps={{
                    side: 'top',
                    sideOffset: 4,
                    style: { maxWidth: 220 },
                  }}
                >
                  <IconButton
                    size="xsmall"
                    icon="infoCircle"
                    color={theme.color.text.tertiary}
                    type="button"
                  />
                </Tooltip>
              ) : null}
            </Typography>
          ) : undefined}
          <Box flexDirection="row" alignItems="center">
            <Box flexDirection="row" alignItems="center" gap="s3" flexGrow={1}>
              {prefix ?? null}
              <input
                // set max date to 9999-12-31 to avoid 6 digits in year field
                max={isDateInput ? '9999-12-31' : undefined}
                {...props}
                ref={ref}
                id={props.name}
                required={required}
                onChange={handleInputChange}
                className={classNames(textFieldInputStyles, {
                  [textFieldInputDisabledStyles]: props.disabled && !inert,
                  [textFieldInputDatePlaceholderStyles]:
                    isDateInput && !isDateInputValueValid && !hasFocus,
                })}
                placeholder={props.placeholder}
                onFocus={onFocus}
                onBlur={onBlur}
                onWheel={(event) => {
                  if (props.type === 'number') {
                    event.currentTarget.blur();
                  }
                }}
              />
            </Box>
            {suffix ?? null}
          </Box>
        </Box>
        {hint && !success && !warning && !(fieldError || error) ? (
          <HintMessage
            message={typeof hint === 'function' ? hint(hasFocus) : hint}
          />
        ) : null}
        {success && !warning && !(fieldError || error) ? (
          <SuccessMessage message={success} />
        ) : null}
        {warning && !(fieldError || error) ? (
          <WarningMessage message={warning} />
        ) : null}
        {error ? (
          <ErrorMessage message={error} />
        ) : fieldError ? (
          <FieldErrorMessage error={fieldError} />
        ) : null}
      </Box>
    );
  }
);
type ErrorMessageProps = {
  error: FormState<FieldValues>['errors'][string];
};

export const FieldErrorMessage = ({ error }: ErrorMessageProps) => {
  if (typeof error?.message === 'string') {
    const message = (
      <>
        <Icon name="exclamationCircle" color="error" />
        <Typography variant="bodySmall" paddingTop="s1" color="error">
          {error.message}
        </Typography>
      </>
    );
    return <InputMessage message={message} />;
  }

  return null;
};

export const ErrorMessage = ({ message }: InputMessageProps) => {
  const formattedMessage = (
    <>
      <Icon name="exclamationCircle" color="error" />
      <Typography variant="bodySmall" paddingTop="s1" color="error">
        {message}
      </Typography>
    </>
  );
  return <InputMessage message={formattedMessage} />;
};

export const HintMessage = ({ message }: InputMessageProps) => {
  const formattedMessage = (
    <Typography variant="bodySmall" paddingTop="s1" color="secondary">
      {message}
    </Typography>
  );
  return <InputMessage message={formattedMessage} />;
};

export const WarningMessage = ({ message }: InputMessageProps) => {
  const formattedMessage = (
    <>
      <Icon name="exclamationCircle" color="warning" />
      <Typography variant="bodySmall" paddingTop="s1" color="warning">
        {message}
      </Typography>
    </>
  );
  return <InputMessage message={formattedMessage} />;
};

export const SuccessMessage = ({ message }: InputMessageProps) => {
  const formattedMessage = (
    <>
      <Icon name="circleCheck" color="success" />
      <Typography variant="bodySmall" paddingTop="s1" color="secondary">
        {message}
      </Typography>
    </>
  );
  return <InputMessage message={formattedMessage} />;
};

export type InputMessageProps = {
  message: React.ReactNode;
};

export const InputMessage = ({ message }: InputMessageProps) => {
  return (
    <Box flexDirection="row" marginTop="s4" gap="s3" role="alert">
      {message}
    </Box>
  );
};
TextField.displayName = 'TextField';
