import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  TransactionParentCategory,
  NotSharingLinkProps,
  SharingLinkProps,
  TransactionDateRange,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { useApi } from '../../useApi';

export type UseTransactionCategoriesProps = (
  | NotSharingLinkProps
  | SharingLinkProps
) & {
  accountIds?: string[];
  dateRange?: TransactionDateRange;
  institutionId?: string;
  isIncludedInAnalysis?: boolean;
};

export const useTransactionCategories = ({
  sharingLinkId,
  accessCode,
  accountIds,
  institutionId,
  dateRange,
  isIncludedInAnalysis,
}: UseTransactionCategoriesProps = {}) => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const params = createSharingLinkParams({ sharingLinkId, accessCode });

  if (accountIds) {
    params.append('account_id', accountIds.join(','));
  }

  if (institutionId) {
    params.append('institution_id', institutionId);
  }

  if (isIncludedInAnalysis) {
    params.append('included_in_analysis', 'true');
  }

  if (dateRange?.start_date && dateRange?.end_date) {
    params.set('period', dateRange.period);
    params.set('start_date', dateRange.start_date);
    params.set('end_date', dateRange.end_date);
  }

  return useApi<TransactionParentCategory[]>(
    API_ROUTES.transactionCategories.get({
      organizationId,
      membershipId,
      params,
    }),
    { keepPreviousData: true },
    !!sharingLinkId
  );
};
