import { useRouter } from 'next/router';
import * as React from 'react';

import { SharingContext } from '/contexts/SharingProvider';

export const useIsSharing = () => {
  const { accessCode } = React.useContext(SharingContext);
  const router = useRouter();
  const { sharing_link_id } = router.query;

  return {
    isSharing: !!sharing_link_id,
    sharingLinkId: sharing_link_id as string | undefined,
    accessCode,
  };
};
