import { OtherAssetCategory } from '../types';

export const OTHER_ASSET_CATEGORIES: OtherAssetCategory[] = [
  'real_estate_crowdfunding',
  'g3f',
  'art',
  'car',
  'private_equity',
  'hedge_fund',
  'sneakers',
  'lending_p2p',
  'private_debt',
  'jewelry',
  'gems',
  'other',
  'gfi',
  'wine',
  'land',
  'fcpi',
];
