import {
  TransactionDateRange,
  NotSharingLinkProps,
  SharingLinkProps,
} from '../../types';

import { useCashflowAvailableMoney } from './useCashflowAvailableMoney';
import { useCashflowDistribution } from './useCashflowDistribution';

export type UseCashflowProps = (NotSharingLinkProps | SharingLinkProps) & {
  dateRange: TransactionDateRange;
};

export const useCashflow = ({
  sharingLinkId,
  accessCode,
  dateRange,
}: UseCashflowProps) => {
  const inResponse = useCashflowDistribution({
    type: 'in',
    dateRange,
    sharingLinkId,
    accessCode,
  });
  const outResponse = useCashflowDistribution({
    type: 'out',
    dateRange,
    sharingLinkId,
    accessCode,
  });
  const availableMoneyResponse = useCashflowAvailableMoney({
    dateRange,
    sharingLinkId,
    accessCode,
  });

  return {
    isLoading:
      inResponse?.isLoading ||
      outResponse?.isLoading ||
      availableMoneyResponse.isLoading,
    data: {
      in: inResponse.data?.distribution?.length ? inResponse.data : null,
      out: outResponse.data?.distribution?.length ? outResponse.data : null,
      available: availableMoneyResponse.data,
    },
    mutate: async () =>
      await Promise.all([
        inResponse.mutate(),
        outResponse.mutate(),
        availableMoneyResponse.mutate(),
      ]),
  };
};

export type UseCashflowReturn = ReturnType<typeof useCashflow>;
