import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  CrowdlendingsDistributionResponse,
  NotSharingLinkProps,
  SharingLinkProps,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { usePeriodDisplayMode } from '../../configuration';
import { useApi } from '../../useApi';

export type CrowdlendingsDistributionType = 'account' | 'name';

export type UsePortfolioCrowdlendingsDistributionBaseProps = {
  type?: CrowdlendingsDistributionType;
};

export type UsePortfolioCrowdlendingsDistributionWithoutSharingProps =
  UsePortfolioCrowdlendingsDistributionBaseProps & NotSharingLinkProps;

export type UsePortfolioCrowdlendingsDistributionWithSharingProps =
  UsePortfolioCrowdlendingsDistributionBaseProps & SharingLinkProps;

export type UsePortfolioCrowdlendingsDistributionProps =
  | UsePortfolioCrowdlendingsDistributionWithoutSharingProps
  | UsePortfolioCrowdlendingsDistributionWithSharingProps;

export const usePortfolioCrowdlendingsDistribution = ({
  type = 'account',
  sharingLinkId,
  accessCode,
}: UsePortfolioCrowdlendingsDistributionProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const { data: periodDisplayMode } = usePeriodDisplayMode(!!sharingLinkId);

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('type', type);
  params.append('period', periodDisplayMode);

  return useApi<CrowdlendingsDistributionResponse>(
    API_ROUTES.portfolio.crowdlendings.distribution.get({
      organizationId,
      membershipId,
      params,
    }),
    { keepPreviousData: true },
    !!sharingLinkId
  );
};
