import { ApiConfig } from '../providers';

export type ErrorCode =
  | 'REQUEST_UNAUTHORIZED'
  | 'INVALID_EMAIL'
  | 'INVALID_OTP_CODE'
  | 'INVALID_PARAMETERS';

export type FetchApiError = {
  error: { code: ErrorCode; parameters?: string[] };
  message: string;
  result: null;
};

export type FetchApiResponse<T> = {
  error: null;
  message: string;
  result: T;
};

export class ResponseError extends Error {
  status: number;
  response: Response;
  body: FetchApiError;

  constructor(status: number, response: Response, body: FetchApiError) {
    super(String(status));
    this.name = 'ResponseError';
    this.status = status;
    this.response = response;
    this.body = body;
  }
}

export type RequestConfig = {
  body?: Record<string, unknown> | FormData;
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
} & Omit<RequestInit, 'cache' | 'body'>;

export type FetchConfig = RequestConfig & ApiConfig;

export const fetchApi = async <T>(key: string | null, config: FetchConfig) => {
  const headers = await fetchApiHeaders(config);

  const optionsWithToken: RequestInit = {
    method: config.method,
    headers,
    body: config.body
      ? bodyIsFormData(config.body)
        ? config.body
        : JSON.stringify(config.body)
      : undefined,
  };

  const response = await fetch(`${config.api}${key}`, optionsWithToken);
  const responseContentType = response.headers.get('content-type');

  let formattedResponse = null;

  if (responseContentType?.includes('application/json')) {
    formattedResponse = await response.json();
  }

  if (!response.ok) {
    throw new ResponseError(response.status, response, formattedResponse);
  }

  return (formattedResponse as FetchApiResponse<T>)?.result;
};

export const fetchApiHeaders = async (config: FetchConfig) => {
  const token = await config.getToken?.();
  const headers = new Headers(config.headers);

  if (!bodyIsFormData(config.body) && !headers.has('Content-Type')) {
    headers.set('Content-Type', 'application/json');
  }

  headers.set('X-Finary-Client-Id', config.platform);
  headers.set('X-Client-Api-Version', '2'); // Needed for Invest API endpoints
  headers.set('Authorization', `Bearer ${token}`);

  return headers;
};

const bodyIsFormData = (body: unknown): body is FormData => {
  return body instanceof FormData;
};
