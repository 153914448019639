import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  DividendsResponse,
  NotSharingLinkProps,
  SharingLinkProps,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { useApi } from '../../useApi';

export type UsePortfolioRealEstatesByAccountDividendsBaseProps = {
  accountId: string | undefined;
};

export type UsePortfolioRealEstatesByAccountDividendsWithoutSharingProps =
  UsePortfolioRealEstatesByAccountDividendsBaseProps & NotSharingLinkProps;

export type UsePortfolioRealEstatesByAccountDividendsWithSharingProps =
  UsePortfolioRealEstatesByAccountDividendsBaseProps & SharingLinkProps;

export type UsePortfolioRealEstatesByAccountDividendsProps =
  | UsePortfolioRealEstatesByAccountDividendsWithoutSharingProps
  | UsePortfolioRealEstatesByAccountDividendsWithSharingProps;

export const usePortfolioRealEstatesByAccountDividends = ({
  accountId,
  sharingLinkId,
  accessCode,
}: UsePortfolioRealEstatesByAccountDividendsProps) => {
  const { membershipId, organizationId } = useFinaryApiConfig();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });

  return useApi<DividendsResponse>(
    accountId
      ? API_ROUTES.portfolio.real_estates.accountById(accountId).dividends.get({
          membershipId,
          organizationId,
          params,
        })
      : null,
    undefined,
    !!sharingLinkId
  );
};
