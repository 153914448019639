import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import { InvestmentsFeesResponse, ApiGetProps } from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { useApi } from '../../useApi';

export type UsePortfolioRealEstatesByAccountFeesBaseProps = {
  accountId: string;
};

export type UsePortfolioRealEstatesByAccountFeesProps =
  UsePortfolioRealEstatesByAccountFeesBaseProps & ApiGetProps;

export const usePortfolioRealEstatesByAccountFees = ({
  accountId,
  skip,
  sharingLinkId,
  accessCode,
}: UsePortfolioRealEstatesByAccountFeesProps) => {
  const { membershipId, organizationId } = useFinaryApiConfig();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });

  return useApi<InvestmentsFeesResponse>(
    API_ROUTES.portfolio.real_estates.accountById(accountId).fees.get({
      membershipId,
      organizationId,
      params,
    }),
    undefined,
    !!sharingLinkId,
    skip
  );
};
