import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../config';
import { useApiConfig, useFinaryApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import { HoldingsAccount, HoldingsAccountCreateBody } from '../../types';

export const useCreateHoldingsAccounts = () => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    API_ROUTES.holdingsAccounts.post({ organizationId, membershipId }),
    async (url: string, { arg }: { arg: HoldingsAccountCreateBody }) =>
      fetchApi<HoldingsAccount>(url, {
        method: 'POST',
        body: arg,
        ...apiConfiguration,
      })
  );
};
