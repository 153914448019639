import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../../config';
import { useApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import { BankData } from '../../../types';

export const useEditBankAccount = (assetId: string) => {
  const apiConfiguration = useApiConfig();
  const { organizationId, membershipId } = apiConfiguration;

  return useSWRMutation(
    API_ROUTES.bank_accounts.byId(assetId).put({
      organizationId,
      membershipId,
    }),
    async (url: string, { arg: body }: { arg: BankData }) =>
      fetchApi(url, {
        body,
        method: 'PUT',
        ...apiConfiguration,
      })
  );
};
