import useSWRMutation from 'swr/mutation';
import { CountryCode } from 'utils';

import { fetchApi } from '../../config';
import { useApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import { User } from '../../types';
import { CompanyType } from '../../types/company';

export type CreateCompanyParams = {
  country: CountryCode;
  name: string;
  company_type?: CompanyType;
  identification_number?: string;
  shareholders?: {
    membership_id: string;
    share: number;
  }[];
  tax_regime?: string;
};

export const useCreateCompany = () => {
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    API_ROUTES.companies.post(),
    async (url: string, { arg }: { arg: CreateCompanyParams }) =>
      fetchApi<User>(url, {
        method: 'POST',
        body: arg,
        ...apiConfiguration,
      })
  );
};
