import * as RadixDropdown from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';
import React from 'react';

import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from '../Polymorphic';
import { Typography } from '../Typography';

import {
  dropdownItemLabelStyles,
  dropdownItemStyles,
  DropdownItemVariants,
} from './DropdownItem.css';

export type DropdownItemProps = DropdownItemVariants;

export type DropdownItemComponentProps<C extends React.ElementType> =
  PolymorphicComponentPropWithRef<C, DropdownItemProps>;

export type DropdownItemComponent = <C extends React.ElementType = 'button'>(
  props: DropdownItemComponentProps<C>
) => React.ReactNode | null;

export const DropdownItem: DropdownItemComponent & { displayName?: string } =
  React.forwardRef(
    <C extends React.ElementType = 'button'>(
      {
        as,
        className,
        children,
        state,
        onSelect,
        ...componentProps
      }: DropdownItemComponentProps<C>,
      ref?: PolymorphicRef<C>
    ) => {
      const Component = as || 'button';
      return (
        <RadixDropdown.Item asChild onSelect={onSelect}>
          <Component
            ref={ref}
            className={classNames(dropdownItemStyles({ state }), className)}
            {...componentProps}
          >
            <Typography
              variant="bodyMedium"
              className={dropdownItemLabelStyles}
            >
              {children}
            </Typography>
          </Component>
        </RadixDropdown.Item>
      );
    }
  );

DropdownItem.displayName = 'DropdownItem';
