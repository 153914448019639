import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  NotSharingLinkProps,
  PortfolioTimeseries,
  SharingLinkProps,
  TimeseriesType,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { usePeriodDisplayMode } from '../../configuration';
import { useApi } from '../../useApi';

export type UsePortfolioOtherAssetsTimeseriesBaseProps = {
  normalized?: boolean;
  type?: TimeseriesType;
};

export type UsePortfolioOtherAssetsTimeseriesWithoutSharingProps =
  UsePortfolioOtherAssetsTimeseriesBaseProps & NotSharingLinkProps;

export type UsePortfolioOtherAssetsTimeseriesWithSharingProps =
  UsePortfolioOtherAssetsTimeseriesBaseProps & SharingLinkProps;

export type UsePortfolioOtherAssetsTimeseriesProps =
  | UsePortfolioOtherAssetsTimeseriesWithoutSharingProps
  | UsePortfolioOtherAssetsTimeseriesWithSharingProps;

export const usePortfolioOtherAssetsTimeseries = ({
  type = 'sum',
  normalized = false,
  sharingLinkId,
  accessCode,
}: UsePortfolioOtherAssetsTimeseriesProps) => {
  const { membershipId, organizationId } = useFinaryApiConfig();
  const { data: periodDisplayMode } = usePeriodDisplayMode(!!sharingLinkId);

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('timeseries_type', type);
  params.append('period', periodDisplayMode);

  // FIXME backend should allow sending 'false' as a value
  if (normalized) {
    params.append('normalized', normalized.toString());
  }

  return useApi<PortfolioTimeseries>(
    API_ROUTES.portfolio.other_assets.timeseries.get({
      membershipId,
      organizationId,
      params,
    }),
    { keepPreviousData: true },
    !!sharingLinkId
  );
};
