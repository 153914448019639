import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  NotSharingLinkProps,
  PortfolioTimeseries,
  SharingLinkProps,
  TimeseriesType,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { usePeriodDisplayMode } from '../../configuration';
import { useApi } from '../../useApi';

export type UsePortfolioSavingsTimeseriesBaseProps = {
  type?: TimeseriesType;
};

export type UsePortfolioSavingsTimeseriesWithoutSharingProps =
  UsePortfolioSavingsTimeseriesBaseProps & NotSharingLinkProps;

export type UsePortfolioSavingsTimeseriesWithSharingProps =
  UsePortfolioSavingsTimeseriesBaseProps & SharingLinkProps;

export type UsePortfolioSavingsTimeseriesProps =
  | UsePortfolioSavingsTimeseriesWithoutSharingProps
  | UsePortfolioSavingsTimeseriesWithSharingProps;

export const usePortfolioSavingsTimeseries = ({
  type = 'sum',
  sharingLinkId,
  accessCode,
}: UsePortfolioSavingsTimeseriesProps) => {
  const { membershipId, organizationId } = useFinaryApiConfig();
  const { data: periodDisplayMode } = usePeriodDisplayMode(!!sharingLinkId);

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('timeseries_type', type);
  params.append('period', periodDisplayMode);

  return useApi<PortfolioTimeseries>(
    API_ROUTES.portfolio.savings_accounts.timeseries.get({
      membershipId,
      organizationId,
      params,
    }),
    { keepPreviousData: true },
    !!sharingLinkId
  );
};
