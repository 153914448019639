import * as React from 'react';

import { Icon } from '../Icon';

import { TextField, TextFieldProps } from './TextField';

export const PasswordTextField = React.forwardRef<
  HTMLInputElement,
  Omit<TextFieldProps, 'suffix' | 'type' | 'ref'>
>((props, ref) => {
  const [visible, toggleVisible] = React.useReducer((state) => !state, false);

  const type = visible ? 'text' : 'password';

  return (
    <TextField
      ref={ref}
      {...props}
      type={type}
      suffix={
        <button type="button" onClick={toggleVisible}>
          <Icon size="large" name={type === 'password' ? 'eye' : 'eyeSlash'} />
        </button>
      }
    />
  );
});

PasswordTextField.displayName = 'PasswordTextField';
