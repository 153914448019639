import classNames from 'classnames';
import React from 'react';

import { Box } from '../Box';
import { Typography } from '../Typography';

import { dropdownHeaderStyles } from './DropdownHeader.css';
import { dropdownItemLabelStyles } from './DropdownItem.css';

export const DropdownHeader = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <Box className={classNames(dropdownHeaderStyles, className)}>
      <Typography variant="bodyMedium" className={dropdownItemLabelStyles}>
        {children}
      </Typography>
    </Box>
  );
};
