import * as React from 'react';
import { KeyedMutator } from 'swr';
import { areSetsEqual } from 'utils';

import { AssetCategory } from '../../types';

import { useSyncingAssetCategories } from './useSyncingAssetCategories';

export const useMutateWhenSyncIsDone = <T>(
  category: AssetCategory | undefined,
  mutate: KeyedMutator<T>
) => {
  const { data: syncingCategories } = useSyncingAssetCategories();
  const wasSyncingCategory = React.useRef<boolean>(false);
  const lastSyncingCategories = React.useRef<Set<unknown>>(new Set());

  if (category) {
    if (wasSyncingCategory.current) {
      mutate();
    }
    wasSyncingCategory.current = syncingCategories?.has(category) ?? false;
  } else if (syncingCategories?.size) {
    if (
      !areSetsEqual(
        syncingCategories ?? new Set(),
        lastSyncingCategories.current
      )
    ) {
      mutate();
    }
    lastSyncingCategories.current = syncingCategories;
  }
};
