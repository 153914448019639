import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  NotSharingLinkProps,
  SCPIAccount,
  SharingLinkProps,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { usePeriodDisplayMode } from '../../configuration';
import { useApi } from '../../useApi';

export type UseScpisByIdBaseProps = {
  scpiId: string | number | undefined;
};

export type UseScpisByIdWithoutSharingProps = UseScpisByIdBaseProps &
  NotSharingLinkProps;

export type UseScpisByIdWithSharingProps = UseScpisByIdBaseProps &
  SharingLinkProps;

export type UseScpisByIdProps =
  | UseScpisByIdWithoutSharingProps
  | UseScpisByIdWithSharingProps;

export const useScpisById = ({
  scpiId,
  sharingLinkId,
  accessCode,
}: UseScpisByIdProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const { data: periodDisplayMode } = usePeriodDisplayMode(!!sharingLinkId);

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('period', periodDisplayMode);

  return useApi<SCPIAccount>(
    scpiId
      ? API_ROUTES.scpis.byId(scpiId).get({
          organizationId,
          membershipId,
          params,
        })
      : null,
    undefined,
    !!sharingLinkId
  );
};
