import { InvestActivityLogEventType, InvestFrequency } from '../types/invest';

export const INVEST_DEFAULT_FIAT_CURRENCY = 'EUR';

export const INVEST_DEFAULT_CRYPTO = 'BTC';

export const INVEST_MAX_DIGIT_FIAT = 2;

export const INVEST_PLANS_FREQUENCIES: InvestFrequency[] = [
  'daily',
  'weekly',
  'every2_weeks',
  'monthly',
];

export const INVEST_VIP_STATUSES = {
  bronze: {
    amountRequired: 0,
  },
  silver: {
    amountRequired: 25_000,
  },
  gold: {
    amountRequired: 50_000,
  },
  platinum: {
    amountRequired: 100_000,
  },
};

export const INVEST_PLAN_EVENTS: InvestActivityLogEventType[] = [
  'plan_created',
  'plan_updated',
  'plan_deleted',
  'plan_allocations_updated',
  'plan_schedule_updated',
  'plan_paused',
  'plan_resumed',
];

export const INVEST_MIN_BUY_EUROS = 10;

export const INVEST_MIN_SELL_EUROS = 5;

/**
 * Ideally we'd like the backend to return those with the assets
 */
export const INVEST_ASSETS_PREDOMINANT_COLORS: Record<string, string> = {
  EUR: 'rgb(1,68,149)',
  BTC: 'rgb(244,148,28)',
  ETH: 'rgb(100,124,236)',
  SOL: 'rgb(55,197,191)',
  XRP: 'rgb(42,50,51)',
  ADA: 'rgb(20,52,164)',
  MATIC: 'rgb(132,68,228)',
  DOT: 'rgb(228,4,124)',
  LTC: 'rgb(52,92,156)',
  AVAX: 'rgb(235,68,68)',
  UNI: 'rgb(252,4,124)',
  LINK: 'rgb(182,200,243)',
  BCH: 'rgb(12,196,140)',
  AAVE: 'rgb(112,135,179)',
  USDT: 'rgb(188,224,213)',
  USDC: 'rgb(36,116,204)',
  GRT: 'rgb(100,68,236)',
  XLM: 'rgb(9,9,9)',
  SNX: 'rgb(20,4,85)',
  MKR: 'rgb(92,185,169)',
  ALGO: 'rgb(176,176,176)',
  FTM: 'rgb(20,180,236)',
  COMP: 'rgb(4,12,12)',
  FET: 'rgb(36,44,68)',
  SAND: 'rgb(4,150,248)',
  MANA: 'rgb(245,67,92)',
};

export const MAP_INVEST_ASSETS_TO_SCREENER_SLUG: Record<string, string> = {
  BTC: 'bitcoin',
  ETH: 'ethereum',
  SOL: 'solana',
  XRP: 'ripple',
  ADA: 'cardano',
  MATIC: 'matic-network',
  DOT: 'polkadot',
  LTC: 'litecoin',
  AVAX: 'avalanche-2',
  UNI: 'uniswap',
  LINK: 'chainlink',
  BCH: 'bitcoin-cash',
  AAVE: 'aave',
  USDT: 'tether',
  USDC: 'usd-coin',
  GRT: 'the-graph',
  XLM: 'stellar',
  SNX: 'havven',
  MKR: 'maker',
  ALGO: 'algorand',
  FTM: 'fantom',
  COMP: 'compound-governance-token',
  FET: 'fetch-ai',
  SAND: 'the-sandbox',
  MANA: 'decentraland',
};
