import { useOrganization } from 'core-api/hooks/useOrganization';
import type { UserMembership } from 'core-api/types';
import { isAllOrganizationMembershipId } from 'core-api/utils/membership';
import { useAtom } from 'jotai';

import { membershipIdAtom, organizationIdAtom } from '/contexts/organization';

export const useOrganizationState = () => {
  const { data: organizationData } = useOrganization();
  const [organizationId, setOrganizationId] = useAtom(organizationIdAtom);
  const [membershipId, setMembershipId] = useAtom(membershipIdAtom);

  const selectedMembership =
    organizationData?.memberships.find(({ id }) => id === membershipId) ??
    undefined;

  const isOrganizationOwner =
    selectedMembership?.member_type === 'User' &&
    selectedMembership.member.is_organization_owner;

  const organizationOwner = organizationData?.memberships.find(
    ({ member, member_type }) =>
      member_type === 'User' && member.is_organization_owner
  ) as UserMembership | undefined;

  return {
    ...organizationData,
    organizationId,
    membershipId: membershipId,
    membership: selectedMembership,
    isAllOrganization: isAllOrganizationMembershipId(membershipId),
    isOrganizationOwner,
    organizationOwner,
    setMembershipId,
    setOrganizationId,
  };
};
