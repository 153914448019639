import { HoldingsAccountOwnership } from '../types';

export const getOwnershipMaxShareToAdd = (
  ownershipRepartition: HoldingsAccountOwnership[] | undefined
) => {
  if (!ownershipRepartition?.length || ownershipRepartition.length === 1) {
    return 100;
  }

  return ownershipRepartition.reduce((acc, ownership) => {
    return acc - ownership.share * 100;
  }, 100);
};

export const getOwnershipMaxEditableShare = (
  membershipId: string,
  ownershipRepartition: HoldingsAccountOwnership[] | undefined
) => {
  if (ownershipRepartition?.length === 2) {
    return 100;
  }
  const otherOwnersShare =
    ownershipRepartition?.reduce((acc, curr) => {
      return acc + (curr.membership_id === membershipId ? 0 : curr.share);
    }, 0) ?? 0;

  return Number(((1 - otherOwnersShare) * 100).toFixed(2));
};

export const getOwnershipTotalShare = (
  ownershipRepartition: HoldingsAccountOwnership[] | undefined
) => {
  if (!ownershipRepartition || !ownershipRepartition.length) {
    return 0;
  }

  return Number(
    ownershipRepartition
      .reduce((acc, ownership) => {
        return acc + ownership.share;
      }, 0)
      .toFixed(4)
  );
};
