import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  AllocationResponse,
  NotSharingLinkProps,
  SharingLinkProps,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { useApi } from '../../useApi';

export type UsePortfolioInvestmentsSectorAllocationProps =
  | NotSharingLinkProps
  | SharingLinkProps;

export const usePortfolioInvestmentsSectorAllocation = ({
  sharingLinkId,
  accessCode,
}: UsePortfolioInvestmentsSectorAllocationProps) => {
  const { membershipId, organizationId } = useFinaryApiConfig();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });

  return useApi<AllocationResponse>(
    API_ROUTES.portfolio.investments.sectorAllocation.get({
      membershipId,
      organizationId,
      params,
    }),
    undefined,
    !!sharingLinkId
  );
};
