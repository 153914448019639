import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  CryptosDistributionResponse,
  NotSharingLinkProps,
  SharingLinkProps,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { usePeriodDisplayMode } from '../../configuration';
import { useApi } from '../../useApi';

export type InvestmentsDistributionType =
  | 'account'
  | 'stock'
  | 'type'
  | 'region'
  | 'country'
  | 'sector';

export type UsePortfolioInvestmentsDistributionBaseProps = {
  type: InvestmentsDistributionType;
};

export type UsePortfolioInvestmentsDistributionWithoutSharingProps =
  UsePortfolioInvestmentsDistributionBaseProps & NotSharingLinkProps;

export type UsePortfolioInvestmentsDistributionWithSharingProps =
  UsePortfolioInvestmentsDistributionBaseProps & SharingLinkProps;

export type UsePortfolioInvestmentsDistributionProps =
  | UsePortfolioInvestmentsDistributionWithoutSharingProps
  | UsePortfolioInvestmentsDistributionWithSharingProps;

export const usePortfolioInvestmentsDistribution = ({
  type = 'stock',
  sharingLinkId,
  accessCode,
}: UsePortfolioInvestmentsDistributionProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const { data: periodDisplayMode } = usePeriodDisplayMode(!!sharingLinkId);

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('type', type);
  params.append('period', periodDisplayMode);

  return useApi<CryptosDistributionResponse>(
    API_ROUTES.portfolio.investments.distribution.get({
      organizationId,
      membershipId,
      params,
    }),
    { keepPreviousData: true },
    !!sharingLinkId
  );
};
