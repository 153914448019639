import type { Asset, Liability } from 'core-api';
import { atomWithStorage } from 'jotai/utils';

export const assetCategoryFilterAtom = atomWithStorage<Asset[]>(
  'portfolioAssetCategoryFilter',
  []
);

export const liabilityCategoryFilterAtom = atomWithStorage<Liability[]>(
  'portfolioLiabilityCategoryFilter',
  []
);
