import { API_ROUTES } from '../../routes';
import { useApi } from '../useApi';

export type CurrencyAutocompleteResult = {
  code: string;
  correlation_id: string;
  external_logo_url: string;
  name: string;
};

export const useCurrenciesAutocomplete = (
  type: 'fiat' | 'crypto',
  query?: string
) => {
  const params = new URLSearchParams();
  params.append('type', type);
  if (query) {
    params.append('query', query);
  }

  return useApi<CurrencyAutocompleteResult[]>(
    API_ROUTES.currencies.autocomplete.get(params),
    {
      keepPreviousData: true,
    }
  );
};
