import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../../config';
import { useApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import { BankAccountType } from '../../../types';

export type CreateBankAccountParams = {
  balance: number;
  bank: { name: string };
  bank_account_type: BankAccountType;
  currency: { id: number };
  name: string;
  annual_yield?: number | null;
};

export const useCreateBankAccount = () => {
  const apiConfiguration = useApiConfig();
  const { organizationId, membershipId } = apiConfiguration;

  return useSWRMutation(
    API_ROUTES.bank_accounts.post({
      organizationId,
      membershipId,
    }),
    async (url: string, { arg: body }: { arg: CreateBankAccountParams }) =>
      fetchApi<{ slug: string }>(url, {
        body,
        method: 'POST',
        ...apiConfiguration,
      })
  );
};
