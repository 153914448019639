import { useUser } from '../../users';

import { useFlanksGetResetToken } from './useFlanksGetResetToken';

export type FlanksGetUrlData = {
  id: string;
  institution_provider_id: string;
  type: 'connection' | 'reconnection';
  provider_connection_id?: string;
  started_at?: string;
};

export type useFlanksGetUrlProps = {
  connectionToken: string;
  reconnectionToken: string;
};

export const useFlanksGetUrl = ({
  connectionToken,
  reconnectionToken,
}: useFlanksGetUrlProps) => {
  const { getFlanksResetToken } = useFlanksGetResetToken();
  const { data: user } = useUser();

  return {
    getFlanksUrl: async ({
      data,
      bankId,
    }: {
      bankId: string;
      data: FlanksGetUrlData;
    }) => {
      if (!user) {
        return;
      }
      const now = new Date().toISOString();

      // It is important to set 'started_at' both in the query params + in the data, for security reasons
      data.started_at = now;
      const encryptedData = await encryptFlanksExtraData(
        user.slug,
        JSON.stringify({ ...data, user_slug: user.slug })
      );
      const params: Record<string, string> = {
        extra: encryptedData,
        language: 'en',
        started_at: now,
        bank: bankId,
        token: data.type === 'connection' ? connectionToken : reconnectionToken,
      };

      if (data.type === 'reconnection' && data.provider_connection_id) {
        const tokens = await getFlanksResetToken(data.provider_connection_id);

        // 'reset_token' has priority over 'sca_token', because if it is present, it means that
        // credentials are probably no longer valid, not just that the access expired
        if (tokens.reset_token) {
          params.reset_token = tokens.reset_token;
        } else {
          params.sca_token = tokens.sca_token ?? '';
        }
      }
      const queryParams = new URLSearchParams(params).toString();

      return `https://platform.flanks.io/link?${queryParams}`;
    },
  };
};

const encryptFlanksExtraData = async (secret: string, data: string) => {
  const crypto = await import('crypto');
  const Buffer = await import('buffer').then((m) => m.Buffer);
  const hashedSecret = crypto.createHash('md5').update(secret).digest('hex');
  const iv = hashedSecret.slice(0, 16);
  const cipher = crypto.createCipheriv('aes-256-cbc', hashedSecret, iv);

  return Buffer.concat([cipher.update(data), cipher.final()]).toString(
    'base64'
  );
};
