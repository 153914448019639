export const AvailabilityIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4062 5.5C15.8516 5.5 16.25 5.89844 16.25 6.34375V13.6562C16.25 14.125 15.8516 14.5 15.4062 14.5H11.0938C10.625 14.5 10.25 14.125 10.25 13.6562V6.34375C10.25 5.89844 10.625 5.5 11.0938 5.5H15.4062ZM14.75 13V7H11.75V13H14.75ZM4.25 4V9.25H9.5V11.5H2.72656C1.90625 11.5 1.25 10.8438 1.25 10V9.625C1.25 9.4375 1.41406 9.25 1.625 9.25H2.75V3.625C2.75 3.01562 3.24219 2.52344 3.85156 2.5H12.125C12.7344 2.52344 13.25 3.01562 13.25 3.625V4.75H11.75V4H4.25Z"
        fill="#F1C086"
      />
    </svg>
  );
};
