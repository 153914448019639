import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  NotSharingLinkProps,
  SharingLinkProps,
  StartupInsights,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { useApi } from '../../useApi';

export type UsePortfolioStartupsInsightsProps =
  | NotSharingLinkProps
  | SharingLinkProps;

export const usePortfolioStartupsInsights = ({
  sharingLinkId,
  accessCode,
}: UsePortfolioStartupsInsightsProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });

  return useApi<StartupInsights>(
    API_ROUTES.portfolio.startups.insights.get({
      organizationId,
      membershipId,
      params,
    }),
    { keepPreviousData: true },
    !!sharingLinkId
  );
};
