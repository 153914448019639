import { CountryCode } from './countries';

export const DEFAULT_CURRENCY = { code: 'EUR' };

export const CURRENCIES: { code: string; countries: CountryCode[] }[] = [
  { code: 'EUR', countries: ['FR', 'DE', 'BE'] },
  { code: 'USD', countries: ['US'] },
  { code: 'CAD', countries: ['CA'] },
  { code: 'CHF', countries: ['CH'] },
  { code: 'GBP', countries: ['GB'] },
];
