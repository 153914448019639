import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  CryptosDistributionResponse,
  NotSharingLinkProps,
  SharingLinkProps,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { useApi } from '../../useApi';

export type StartupsDistributionType = 'name';

export type UsePortfolioStartupsDistributionBaseProps = {
  type?: StartupsDistributionType;
};

export type UsePortfolioStartupsDistributionWithoutSharingProps =
  UsePortfolioStartupsDistributionBaseProps & NotSharingLinkProps;

export type UsePortfolioStartupsDistributionWithSharingProps =
  UsePortfolioStartupsDistributionBaseProps & SharingLinkProps;

export type UsePortfolioStartupsDistributionProps =
  | UsePortfolioStartupsDistributionWithoutSharingProps
  | UsePortfolioStartupsDistributionWithSharingProps;

export const usePortfolioStartupsDistribution = ({
  type = 'name',
  sharingLinkId,
  accessCode,
}: UsePortfolioStartupsDistributionProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('type', type);

  return useApi<CryptosDistributionResponse>(
    API_ROUTES.portfolio.startups.distribution.get({
      organizationId,
      membershipId,
      params,
    }),
    { keepPreviousData: true },
    !!sharingLinkId
  );
};
