import {
  ApiGetProps,
  DividendsResponse,
  GeographicalDiversificationResult,
  PortfolioFees,
  SectorDiversificationResult,
} from '../../types';
import { usePortfolioFees } from '../portfolio/fees/usePortfolioFees';

import { useGeographicalDiversification } from './useGeographicalDiversification';
import { usePortfolioDividends } from './usePortfolioDividends';
import { usePortfolioSectorDiversification } from './useSectorDiversification';

type UseInsightResult = {
  isLoading: boolean;
  dividends?: DividendsResponse;
  fees?: PortfolioFees;
  geographicalDiversification?: GeographicalDiversificationResult;
  sectorDiversification?: SectorDiversificationResult;
};

export type UseInsightsCardsProps = ApiGetProps;

export const useInsightsCards = ({
  sharingLinkId,
  accessCode,
}: UseInsightsCardsProps): UseInsightResult => {
  const { data: dividends, isLoading: isLoadingDividends } =
    usePortfolioDividends({ sharingLinkId, accessCode });
  const {
    data: sectorDiversification,
    isLoading: isLoadingSectorDiversification,
  } = usePortfolioSectorDiversification({ sharingLinkId, accessCode });
  const {
    data: geographicalDiversification,
    isLoading: isLoadingGeographicalDiversification,
  } = useGeographicalDiversification({ sharingLinkId, accessCode });
  const { data: fees, isLoading: isFeesLoading } = usePortfolioFees({
    sharingLinkId,
    accessCode,
  });

  return {
    isLoading:
      isFeesLoading ||
      isLoadingDividends ||
      isLoadingSectorDiversification ||
      isLoadingGeographicalDiversification,
    fees,
    dividends,
    sectorDiversification,
    geographicalDiversification,
  };
};
