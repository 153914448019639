import type { CountryCode } from 'utils';

export enum QuestionnaireType {
  Onboarding = 'onboarding',
}

export type BaseQuestionnaire<T extends QuestionnaireType, A> = {
  questionnaire_attributes: A;
  questionnaire_type: T;
};

export type BaseQuestionnaireResponse<T extends QuestionnaireType, A> = {
  id: number;
  user_id: number;
} & BaseQuestionnaire<T, A>;

export type QuestionnaireOnboardingGoals =
  | 'track'
  | 'financial_advice'
  | 'plan_budget'
  | 'invest'
  | 'learn'
  | 'other';

export type QuestionnaireOnboardingOrganizationNeeds =
  | 'solo'
  | 'family'
  | 'holding'
  | 'all';

export type QuestionnaireOnboardingInvestorLevel =
  | 'beginner'
  | 'intermediate'
  | 'advanced'
  | 'expert';

export type QuestionnaireOnboardingSource =
  | 'friends'
  | 'blog'
  | 'social_network'
  | 'linkedIn'
  | 'twitter'
  | 'podcast'
  | 'youtube'
  | 'search'
  | 'tiktok'
  | 'other';

export type QuestionnaireOnboardingAttributes = {
  country: CountryCode;
  goals: QuestionnaireOnboardingGoals[];
  investor_level: QuestionnaireOnboardingInvestorLevel;
  organizations_needs: QuestionnaireOnboardingOrganizationNeeds;
  source: QuestionnaireOnboardingSource;
};

export type QuestionnaireOnboarding = BaseQuestionnaire<
  QuestionnaireType.Onboarding,
  QuestionnaireOnboardingAttributes
>;

export type QuestionnaireOnboardingResponse = BaseQuestionnaireResponse<
  QuestionnaireType.Onboarding,
  QuestionnaireOnboardingAttributes
>;
