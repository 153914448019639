import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  CryptoDistributionType,
  CryptosDistributionResponse,
  NotSharingLinkProps,
  SharingLinkProps,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { useApi } from '../../useApi';

export type UsePortfolioCryptosDistributionBaseProps = {
  type?: CryptoDistributionType;
};

export type UsePortfolioCryptosDistributionWithoutSharingProps =
  UsePortfolioCryptosDistributionBaseProps & NotSharingLinkProps;

export type UsePortfolioCryptosDistributionWithSharingProps =
  UsePortfolioCryptosDistributionBaseProps & SharingLinkProps;

export type UsePortfolioCryptosDistributionProps =
  | UsePortfolioCryptosDistributionWithoutSharingProps
  | UsePortfolioCryptosDistributionWithSharingProps;

export const usePortfolioCryptosDistribution = ({
  type = 'crypto',
  sharingLinkId,
  accessCode,
}: UsePortfolioCryptosDistributionProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('type', type);
  params.append('period', 'all');

  return useApi<CryptosDistributionResponse>(
    API_ROUTES.portfolio.cryptos.distribution.get({
      organizationId,
      membershipId,
      params,
    }),
    { keepPreviousData: true },
    !!sharingLinkId
  );
};
