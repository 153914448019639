export const SecurityIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.875 7V5.875C4.875 4.02344 6.375 2.5 8.25 2.5C10.1016 2.5 11.625 4.02344 11.625 5.875V7H12C12.8203 7 13.5 7.67969 13.5 8.5V13C13.5 13.8438 12.8203 14.5 12 14.5H4.5C3.65625 14.5 3 13.8438 3 13V8.5C3 7.67969 3.65625 7 4.5 7H4.875ZM6.375 7H10.125V5.875C10.125 4.84375 9.28125 4 8.25 4C7.19531 4 6.375 4.84375 6.375 5.875V7Z"
        fill="#F1C086"
      />
    </svg>
  );
};
