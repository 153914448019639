import { useFinaryApiConfig } from '../providers';
import { API_ROUTES } from '../routes';
import { ApiGetProps, SecurityAlternativesResponse } from '../types';
import { createSharingLinkParams } from '../utils';

import { useApi } from './useApi';

export type UseSecuritiesAlternativesProps = {
  securityId: string | number | null | undefined;
} & ApiGetProps;

export const useSecuritiesAlternatives = ({
  securityId,
  sharingLinkId,
  accessCode,
}: UseSecuritiesAlternativesProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });

  return useApi<SecurityAlternativesResponse>(
    securityId
      ? API_ROUTES.securities.alternatives.get(securityId)({
          organizationId,
          membershipId,
          params,
        })
      : null,
    undefined,
    !!sharingLinkId
  );
};
