import { SharingLinkProps } from '../types';

/**
 * Create or append the sharing link params to the given params
 */
export const createSharingLinkParams = (
  { sharingLinkId, accessCode }: SharingLinkProps,
  params?: URLSearchParams
) => {
  const newParams = params ?? new URLSearchParams();
  if (sharingLinkId) {
    newParams.set('sharing_link_id', sharingLinkId);
  }
  if (accessCode) {
    newParams.set('access_code', accessCode);
  }
  return newParams;
};
