import {
  AssetCategory,
  AssetCategoryNewFormat,
  HoldingsAccount,
  Liability,
  LiabilityNewFormat,
} from '../types';

export const newAssetCategoryToOldFormat = (category: AssetCategoryNewFormat) =>
  category
    .replace(/^investments$/, 'investment_accounts')
    .replace(/^savings$/, 'savings_accounts')
    .replace(/^credits$/, 'credit_accounts')
    .replace(/^checkings$/, 'checking_accounts') as AssetCategory;

export const oldAssetCategoryToNewFormat = (category: AssetCategory) =>
  category
    .replace(/^investment_accounts$/, 'investments')
    .replace(/^savings_accounts$/, 'savings')
    .replace(/^credit_accounts$/, 'credits')
    .replace(/^checking_accounts$/, 'checkings') as AssetCategoryNewFormat;

export const isAsset = (
  category: AssetCategory | AssetCategoryNewFormat
): category is AssetCategory | AssetCategoryNewFormat => {
  return !isLiability(category);
};

export const isLiability = (
  category: AssetCategory | AssetCategoryNewFormat
): category is Liability | LiabilityNewFormat => {
  return (
    isLiabilityOldFormat(category as AssetCategory) ||
    isLiabilityNewFormat(category as AssetCategoryNewFormat)
  );
};

export const isLiabilityOldFormat = (
  category: AssetCategory
): category is Liability => {
  return category === 'loans' || category === 'credit_accounts';
};

export const isLiabilityNewFormat = (
  category: AssetCategoryNewFormat
): category is LiabilityNewFormat => {
  return category === 'loans' || category === 'credits';
};

export const mapHoldingsAccountsToAssetsCategories = (
  accounts: HoldingsAccount[]
): Record<AssetCategory, HoldingsAccount[]> => {
  return accounts.reduce((acc, account) => {
    let category = account.category;

    if (!category) {
      if (
        account.bank_account_type?.name === 'crowdlending' ||
        account.manual_type === 'crowdlending'
      ) {
        category = 'crowdlendings';
      } else if (
        account.manual_type === 'scpi' ||
        account.manual_type === 'real_estate'
      ) {
        category = 'real_estates';
      } else if (account.bank_account_type?.name === 'credit_card') {
        category = 'credit_accounts';
      } else if (account.bank_account_type?.subtype === 'savings') {
        category = 'savings_accounts';
      } else if (account.manual_type === 'crypto') {
        category = 'cryptos';
      } else if (account.manual_type === 'stocks') {
        category = 'investment_accounts';
      } else if (account.manual_type === 'other') {
        category = 'other_assets';
      } else if (account.manual_type === 'startup') {
        category = 'startups';
      } else if (account.manual_type === 'loan') {
        category = 'loans';
      } else if (account.manual_type === 'commodities') {
        category = 'commodities';
      } else if (account.bank_account_type?.account_type === 'cash') {
        category = 'checking_accounts';
      } else if (
        account.bank_account_type?.account_type === 'investment' ||
        account.manual_type === 'stocks'
      ) {
        category = 'investment_accounts';
      } else if (
        account.bank_account_type?.account_type === 'loan' ||
        account.loans?.length > 0
      ) {
        category = 'loans';
      } else if (account.cryptos.length > 0) {
        category = 'cryptos';
      } else if (account.startups?.length > 0) {
        category = 'startups';
      } else {
        category = 'other_assets';
      }
    }

    const currentCategory = acc[category];

    if (currentCategory) {
      acc[category] = [...currentCategory, account];
    } else {
      acc[category] = [account];
    }

    return acc;
  }, {} as Record<AssetCategory, HoldingsAccount[]>);
};
