import {
  IconDefinition,
  IconName,
  IconPrefix,
} from '@fortawesome/fontawesome-svg-core';

export const finaryMark: IconDefinition = {
  icon: [
    // SVG viewbox width
    16,
    // SVG viewbox height
    16,
    // Aliases (not needed)
    [],
    // Unicode as hex value (not needed)
    '',
    // SVG path data
    'M5 2.375C2.23858 2.375 0 4.61358 0 7.375H11C13.7614 7.375 16 5.13642 16 2.375H5ZM5 8.625C2.23858 8.625 0 10.8636 0 13.625H5.28571C8.04714 13.625 10.2857 11.3864 10.2857 8.625H5Z',
  ],
  iconName: 'finary-mark' as IconName,
  prefix: 'finary-mark' as IconPrefix,
};
