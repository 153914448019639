/**
 * Checks whether the given timeseries spans a single year.
 * @param timeseries - The input timeseries containing date-value pairs.
 * @returns A boolean value indicating if the timeseries spans only one year.
 */
export const timeseriesAreInOneYearRange = (
  timeseries: [string, number][]
): boolean => {
  const first = timeseries[0];
  const last = timeseries[timeseries.length - 1];

  if (first && last) {
    return new Date(first[0]).getFullYear() === new Date(last[0]).getFullYear();
  }

  return false;
};

/**
 * Retrieves the timestamps of the unique years present in a given timeseries.
 * @param timeseries The input timeseries containing date-value pairs.
 * @returns An array of timestamps representing the unique years in the timeseries.
 */
export const getTimeseriesTicksByYears = (
  timeseries: [string, number][]
): number[] => {
  const yearsDone: Record<number, boolean> = {};
  const ticks: number[] = [];

  timeseries.forEach(([date]) => {
    if (!yearsDone[new Date(date).getFullYear()]) {
      ticks.push(new Date(date).getTime());
      yearsDone[new Date(date).getFullYear()] = true;
    }
  });

  return ticks;
};
