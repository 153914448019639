import { API_ROUTES } from '../../../routes';
import { useApi } from '../../useApi';

export type ScpiAutocompleteResult = {
  correlation_id: string;
  external_logo_url: string;
  name: string;
};

export const useScpisAutocomplete = (query?: string) => {
  const params = new URLSearchParams();
  if (query) {
    params.append('query', query);
  }

  return useApi<ScpiAutocompleteResult[]>(
    API_ROUTES.scpis.autocomplete(params),
    { keepPreviousData: true }
  );
};
