import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  AssetsDistributionResponse,
  NotSharingLinkProps,
  SharingLinkProps,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { usePeriodDisplayMode } from '../../configuration';
import { useApi } from '../../useApi';

import { InvestmentsDistributionType } from './usePortfolioInvestmentsDistribution';

export type UsePortfolioInvestmentsByAccountDistributionBaseProps = {
  accountId: string;
  type?: InvestmentsDistributionType;
};

export type UsePortfolioInvestmentsByAccountDistributionWithoutSharingProps =
  UsePortfolioInvestmentsByAccountDistributionBaseProps & NotSharingLinkProps;

export type UsePortfolioInvestmentsByAccountDistributionWithSharingProps =
  UsePortfolioInvestmentsByAccountDistributionBaseProps & SharingLinkProps;

export type UsePortfolioInvestmentsByAccountDistributionProps =
  | UsePortfolioInvestmentsByAccountDistributionWithoutSharingProps
  | UsePortfolioInvestmentsByAccountDistributionWithSharingProps;

export const usePortfolioInvestmentsByAccountDistribution = ({
  accountId,
  type = 'stock',
  sharingLinkId,
  accessCode,
}: UsePortfolioInvestmentsByAccountDistributionProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const { data: periodDisplayMode } = usePeriodDisplayMode(!!sharingLinkId);

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('type', type);
  params.append('period', periodDisplayMode);

  return useApi<AssetsDistributionResponse>(
    API_ROUTES.portfolio.investments.accountById(accountId).distribution.get({
      organizationId,
      membershipId,
      params,
    }),
    { keepPreviousData: true },
    !!sharingLinkId
  );
};
