import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  NotSharingLinkProps,
  PortfolioTimeseries,
  SharingLinkProps,
  TimeseriesType,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { usePeriodDisplayMode } from '../../configuration';
import { useApi } from '../../useApi';

export type UsePortfolioCheckingsTimeseriesBaseProps = {
  type?: TimeseriesType;
};

export type UsePortfolioCheckingsTimeseriesWithoutSharingProps =
  UsePortfolioCheckingsTimeseriesBaseProps & NotSharingLinkProps;

export type UsePortfolioCheckingsTimeseriesWithSharingProps =
  UsePortfolioCheckingsTimeseriesBaseProps & SharingLinkProps;

export type UsePortfolioCheckingsTimeseriesProps =
  | UsePortfolioCheckingsTimeseriesWithoutSharingProps
  | UsePortfolioCheckingsTimeseriesWithSharingProps;

export const usePortfolioCheckingsTimeseries = ({
  type = 'sum',
  sharingLinkId,
  accessCode,
}: UsePortfolioCheckingsTimeseriesProps) => {
  const { membershipId, organizationId } = useFinaryApiConfig();
  const { data: periodDisplayMode } = usePeriodDisplayMode(!!sharingLinkId);

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('timeseries_type', type);
  params.append('period', periodDisplayMode);

  return useApi<PortfolioTimeseries>(
    API_ROUTES.portfolio.checking_accounts.timeseries.get({
      membershipId,
      organizationId,
      params,
    }),
    { keepPreviousData: true },
    !!sharingLinkId
  );
};
