import { fetchApi } from '../../config';
import { useApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';

/** This hook doesn't use swr because we pass a dynamic id to the fetch call */
export const usePostFinaryConnectSynchronization2FaCode = () => {
  const apiConfiguration = useApiConfig();

  return (connectionId: string, synchronizationId: string, code2Fa: string) =>
    fetchApi<null>(
      API_ROUTES.connect.connections
        .byId(connectionId)
        .synchronizations.byId(synchronizationId)
        .set2FaCode.post(),
      {
        method: 'POST',
        body: {
          code_2fa: code2Fa,
        },
        ...apiConfiguration,
      }
    );
};
