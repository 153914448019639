import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../../config';
import { useApiConfig, useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import { RealEstate } from '../../../types';

export type CreateRealEstateParams = Pick<
  RealEstate,
  | 'building_type'
  | 'buying_price'
  | 'category'
  | 'ownership_percentage'
  | 'surface'
  | 'agency_fees'
  | 'balcony_area'
  | 'bathrooms_condition'
  | 'bathrooms_quality'
  | 'bought_at'
  | 'building_period'
  | 'description'
  | 'floor_number'
  | 'flooring_condition'
  | 'flooring_quality'
  | 'furnishing_fees'
  | 'garage_spaces'
  | 'garden_area'
  | 'general_condition'
  | 'general_quality'
  | 'has_lift'
  | 'has_pool'
  | 'has_sauna'
  | 'is_furnished'
  | 'is_new'
  | 'kitchen_condition'
  | 'kitchen_quality'
  | 'monthly_charges'
  | 'monthly_rent'
  | 'notary_fees'
  | 'number_of_bathrooms'
  | 'number_of_floors'
  | 'number_of_rooms'
  | 'parking_spaces'
  | 'renovation_fees'
  | 'rental_period'
  | 'rental_type'
  | 'user_estimated_value'
  | 'windows_condition'
  | 'windows_quality'
  | 'yearly_taxes'
> & {
  place_id: string;
};

export const useCreateRealEstate = () => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    API_ROUTES.real_estates.post({ organizationId, membershipId }),
    async (url: string, { arg }: { arg: CreateRealEstateParams }) =>
      fetchApi<RealEstate>(url, {
        body: arg,
        method: 'POST',
        ...apiConfiguration,
      })
  );
};
