import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  LoansDistributionResponse,
  NotSharingLinkProps,
  SharingLinkProps,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { useApi } from '../../useApi';

export type UsePortfolioLoansDistributionProps =
  | NotSharingLinkProps
  | SharingLinkProps;

export const usePortfolioLoansDistribution = ({
  sharingLinkId,
  accessCode,
}: UsePortfolioLoansDistributionProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('type', 'name');

  return useApi<LoansDistributionResponse>(
    API_ROUTES.portfolio.loans.distribution.get({
      organizationId,
      membershipId,
      params,
    }),
    { keepPreviousData: true },
    !!sharingLinkId
  );
};
