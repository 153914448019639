import * as React from 'react';

export type OverflowState = {
  overflows?: boolean;
  overflowsX?: boolean;
  overflowsY?: boolean;
};

export type useOverflowProps<T extends HTMLElement> = {
  // The ref for the element you want to watch overflow for
  ref: React.MutableRefObject<T | undefined | null>;
  // Use this flag to conditionally disable the hook
  disabled?: boolean;
};

/**
 * Returns the current `OverflowState` for the given ref once available
 * you can pass any additional deps you need to the hook
 */
export const useOverflow = <T extends HTMLElement>(
  { ref, disabled }: useOverflowProps<T>,
  deps: unknown[] = []
) => {
  const [overflows, setOverflows] = React.useState<OverflowState>({
    overflows: undefined,
    overflowsX: undefined,
    overflowsY: undefined,
  });

  React.useEffect(() => {
    const update = () => {
      if (ref.current) {
        const overflowsX =
          ref.current.scrollWidth - ref.current.offsetWidth > 2;
        const overflowsY =
          ref.current.scrollHeight - ref.current.offsetHeight > 2;
        const overflows = overflowsX || overflowsY;
        setOverflows((previous) => {
          if (
            previous.overflows === overflows &&
            previous.overflowsX === overflowsX &&
            previous.overflowsY === overflowsY
          ) {
            return previous;
          }
          return {
            overflows,
            overflowsX,
            overflowsY,
          };
        });
      }
    };

    if (ref.current && !disabled) {
      const observer = new ResizeObserver(update);
      observer.observe(ref.current);
      update();

      return () => observer.disconnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we allow adding other deps for thing such as combined refs
  }, [disabled, ref, ...deps]);

  return overflows;
};
