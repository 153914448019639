import NextLink from 'next/link';
import * as React from 'react';

import { Box } from '../Box';
import { Icon, IconName, IconProps } from '../Icon';

import { linkStyles, LinkVariants } from './Link.css';

type BaseLinkProps = {
  children: React.ReactNode;
  disabled?: boolean;
  iconSize?: IconProps['size'];
  id?: React.ComponentProps<'a'>['id'];
  isExternal?: boolean;
  isLoading?: boolean;
  prefixIcon?: IconName;
  rel?: React.ComponentProps<'a'>['rel'];
  style?: React.ComponentProps<'a'>['style'];
  suffixIcon?: IconName;
  target?: React.ComponentProps<'a'>['target'];
};

export type HrefLinkProps = {
  href: string;
  rel?: string;
  target?: string;
} & BaseLinkProps;

export type ButtonLinkProps = {
  href?: never;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
} & BaseLinkProps;

export type LinkProps = (HrefLinkProps | ButtonLinkProps) & LinkVariants;

export const Link = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  LinkProps
>(
  (
    {
      isExternal,
      isLoading,
      rel,
      target,
      children,
      prefixIcon,
      suffixIcon,
      size,
      variant,
      bordered,
      disabled,
      iconSize = 'small',
      ...props
    },
    ref
  ) => {
    const content = (
      <>
        {prefixIcon ? <Icon name={prefixIcon} size={iconSize} /> : null}
        {children}
        {isLoading ? (
          <Icon name="spinnerSolid" animation="rotate" size={iconSize} />
        ) : suffixIcon ? (
          <Icon name={suffixIcon} size={iconSize} />
        ) : null}
      </>
    );

    const className = linkStyles({ size, variant, bordered });

    const disabledProps = disabled
      ? {
          'aria-disabled': true,
          tabIndex: -1,
        }
      : undefined;

    if (props.href) {
      const isExternalLink = isExternal || props.href.startsWith('http');
      return (
        <Box
          as={isExternal ? 'a' : NextLink}
          ref={ref as React.ForwardedRef<HTMLAnchorElement>}
          className={className}
          target={
            typeof target !== 'undefined'
              ? target
              : isExternalLink
              ? '_blank'
              : undefined
          }
          rel={
            typeof rel !== 'undefined'
              ? rel
              : isExternalLink
              ? 'noopener noreferrer'
              : undefined
          }
          {...props}
          {...disabledProps}
          href={disabled ? '' : props.href}
        >
          {content}
        </Box>
      );
    }

    return (
      <Box
        as="button"
        type="button"
        ref={ref as React.ForwardedRef<HTMLButtonElement>}
        className={className}
        {...props}
        {...disabledProps}
      >
        {content}
      </Box>
    );
  }
);

Link.displayName = 'Link';
