import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../../config';
import { useApiConfig, useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import { Transaction } from '../../../types';

export type EditTransactionParams = {
  custom_category_id?: number;
  custom_subcategory_id?: number;
  display_date?: string;
  display_name?: string;
  include_in_analysis?: boolean;
  marked?: boolean;
};

export const useEditTransaction = (transactionId: number | undefined) => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    typeof transactionId === 'number'
      ? API_ROUTES.transactions
          .byId(transactionId)
          .put({ organizationId, membershipId })
      : null,
    async (url: string, { arg }: { arg: EditTransactionParams }) =>
      fetchApi<Transaction>(url, {
        body: arg,
        method: 'PUT',
        ...apiConfiguration,
      })
  );
};
