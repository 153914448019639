/**
 * Clipboard util
 * --------------
 * Be aware that browsers won't copy anything if you try to copy
 * too long after user interaction
 */
export const copy = (
  str: string,
  container: Element = document.body
): boolean => {
  const textarea = createTextArea(str);
  container.appendChild(textarea);
  selectTextAreaContent(textarea);
  const result = command('copy', undefined);
  container.removeChild(textarea);

  return result;
};

// Create a text area on which we'll execute the document command
const createTextArea = (str: string) => {
  const textarea = document.createElement('textarea');
  textarea.style.setProperty('position', 'fixed');
  textarea.style.setProperty('top', '100vh');
  textarea.style.setProperty('left', '100vw');
  textarea.style.setProperty('width', '1px');
  textarea.style.setProperty('height', '1px');
  textarea.style.setProperty('overflow', 'hidden');
  textarea.style.setProperty('opacity', '0');
  textarea.style.setProperty('pointer-events', 'none');
  textarea.contentEditable = 'true';
  textarea.readOnly = true;
  // textarea needs to have some initial value / content to prevent safari
  // from adding a shadow DOM to it with a div contenteditable
  textarea.textContent = str;
  textarea.value = str;

  return textarea;
};

// Select the textarea content for the copy command to use desired text
const selectTextAreaContent = (textarea: HTMLTextAreaElement) => {
  // handle iOS as a special case, see:
  // https://stackoverflow.com/questions/34045777/copy-to-clipboard-using-javascript-in-ios
  if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
    // create a selectable range
    const range = document.createRange();
    range.selectNodeContents(textarea);

    // select the range
    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);
    textarea.setSelectionRange(0, 999999);
  } else {
    // other browsers are easier
    textarea.select();
  }
};

// We use the legacy document.execCommand because modern Clipboard API
// requires user permission before copying anything
const command = (name: string, value?: string): boolean => {
  return document.execCommand(name, false, value);
};
