import { API_ROUTES } from '../../routes';
import { Crypto, Currency } from '../../types';
import { useApi } from '../useApi';
import { useUser } from '../users';

export const useMostPopularCurrencies = <T extends 'fiat' | 'crypto'>(
  type: T,
  limit = 20
) => {
  const { data: user } = useUser();
  const params = new URLSearchParams();
  params.append('type', type);
  params.append('limit', limit.toString());
  if (user?.country) {
    params.append('country', user.country);
  }

  return useApi<T extends 'fiat' ? Currency[] : Crypto[]>(
    API_ROUTES.currencies.mostPopular.get(params)
  );
};
