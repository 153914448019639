import { useFinaryApiConfig } from '../providers';
import { API_ROUTES } from '../routes';

import { usePostApi } from './useApi';

/**
 * This endpoint is used when an institution connection is not yet available.
 * When the institution gets available, it allows us to contact the user.
 */
export const useBankRequests = () => {
  const { organizationId, membershipId } = useFinaryApiConfig();

  return usePostApi<
    void,
    {
      bank: {
        slug: string;
      };
    }
  >(API_ROUTES.bankRequests.post({ organizationId, membershipId }));
};
