import type {
  CrowdlendingsDistributionType,
  CryptoDistributionType,
  InvestmentsDistributionType,
  OtherAssetsDistributionType,
  RealEstatesDistributionType,
} from 'core-api';
import { atomWithStorage } from 'jotai/utils';

export type DistributionChartType = 'pie' | 'treemap';

export const distributionChartTypeAtom = atomWithStorage<DistributionChartType>(
  'distributionChartType',
  'treemap'
);

export const investmentsDistributionTypeFilterAtom =
  atomWithStorage<InvestmentsDistributionType>(
    'investmentsDistributionTypeFilter',
    'stock'
  );

export const crowdlendingsDistributionTypeFilterAtom =
  atomWithStorage<CrowdlendingsDistributionType>(
    'crowdlendingsDistributionTypeFilter',
    'account'
  );

export const cryptosDistributionTypeFilterAtom =
  atomWithStorage<CryptoDistributionType>(
    'cryptosDistributionTypeFilter',
    'crypto'
  );

export const otherAssetsDistributionTypeFilterAtom =
  atomWithStorage<OtherAssetsDistributionType>(
    'otherAssetsDistributionTypeFilter',
    'name'
  );

export const realEstatesDistributionTypeFilterAtom =
  atomWithStorage<RealEstatesDistributionType>(
    'realEstatesDistributionTypeFilter',
    'name'
  );
