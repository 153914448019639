import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../config';
import { useApiConfig } from '../providers';
import { API_ROUTES } from '../routes';

export const useDeleteHistoricalData = (start: string, end: string) => {
  const apiConfiguration = useApiConfig();

  const params = new URLSearchParams({
    start,
    end,
  });

  return useSWRMutation(
    API_ROUTES.historicalData.delete(params),
    async (url: string) =>
      fetchApi(url, {
        method: 'DELETE',
        ...apiConfiguration,
      })
  );
};
