import { API_ROUTES } from '../../../routes';
import { PreciousMetal } from '../../../types';
import { useApi } from '../../useApi';

export type AutocompletePreciousMetal = Pick<
  PreciousMetal,
  | 'name'
  | 'currency'
  | 'current_price'
  | 'display_currency'
  | 'display_current_price'
  | 'correlation_id'
  | 'id'
  | 'image_url'
  | 'logo_url'
  | 'updated_at'
>;

export const usePreciousMetalsAutocomplete = (query: string) => {
  const params = new URLSearchParams();

  if (query) {
    params.append('query', query);
  }

  return useApi<AutocompletePreciousMetal[]>(
    API_ROUTES.precious_metals.autocomplete.get(params),
    {
      keepPreviousData: true,
    },
    undefined,
    !query
  );
};
