import { useFinaryApiConfig } from '../providers';
import { API_ROUTES } from '../routes';
import {
  AssetCategory,
  AssetListItem,
  AssetOrder,
  NotSharingLinkProps,
  SharingLinkProps,
} from '../types';
import { createSharingLinkParams } from '../utils';

import { usePeriodDisplayMode } from './configuration';
import { useApi } from './useApi';

export type UseMoversBaseProps = {
  categories?: AssetCategory[];
  sortFilter?: AssetOrder;
};

export type UseMoversWithoutSharingProps = UseMoversBaseProps &
  NotSharingLinkProps;

export type UseMoversWithSharingProps = UseMoversBaseProps & SharingLinkProps;

export type UseMoversProps =
  | UseMoversWithoutSharingProps
  | UseMoversWithSharingProps;

export const useMovers = ({
  categories,
  sortFilter,
  sharingLinkId,
  accessCode,
}: UseMoversProps) => {
  const { membershipId, organizationId } = useFinaryApiConfig();
  const { data: periodDisplayMode } = usePeriodDisplayMode();
  const categoriesFilter = categories?.join(',');

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('limit', '25');
  params.append('period', periodDisplayMode);
  if (sortFilter) {
    params.append('order', sortFilter);
  }
  if (categoriesFilter) {
    params.append('category', categoriesFilter);
  }

  return useApi<AssetListItem[]>(
    API_ROUTES.asset_list.get({
      membershipId,
      organizationId,
      params,
    }),
    { keepPreviousData: true },
    !!sharingLinkId
  );
};
