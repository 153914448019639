import * as React from 'react';

/**
 * Allow to use multiple refs combined in one (so you can forward an external
 * ref AND keep an internal one for you own events / DOM access / manipulation)
 * @param refs
 */
export const useCombinedRefs = <T,>(
  ...refs: (React.Ref<T> | undefined)[]
): React.RefCallback<T> => {
  return React.useCallback(
    (element: T) => {
      refs.forEach((ref) => {
        if (!ref) {
          return;
        }
        if (typeof ref === 'function') {
          ref(element);
        } else if (ref && typeof ref === 'object') {
          const mutableRef = ref as React.MutableRefObject<T>;
          mutableRef.current = element;
        }
      });
    },
    [refs]
  );
};
