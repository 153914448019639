import { useFinaryApiConfig } from '../providers';
import { API_ROUTES } from '../routes';
import { AssetCategory, NotSharingLinkProps, SharingLinkProps } from '../types';
import { createSharingLinkParams } from '../utils';

import { useApi } from './useApi';

export type UseAssetCategoriesBaseProps = {
  membershipId?: string;
  withCashCategories?: boolean;
  withLiabilitiesCategories?: boolean;
};

export type UseAssetCategoriesWithoutSharingProps =
  UseAssetCategoriesBaseProps & NotSharingLinkProps;

export type UseAssetCategoriesWithSharingProps = UseAssetCategoriesBaseProps &
  SharingLinkProps;

export type UseAssetCategoriesProps =
  | UseAssetCategoriesWithoutSharingProps
  | UseAssetCategoriesWithSharingProps;

export const useAssetCategories = (props?: UseAssetCategoriesProps) => {
  const {
    // include all cash categories
    withCashCategories = false,
    // if cash categories, should the return value include liabilities
    withLiabilitiesCategories = false,
    sharingLinkId,
    accessCode,
  } = props || {};
  const { membershipId, organizationId } = useFinaryApiConfig();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  if (withCashCategories) {
    params.set('with_cash_categories', 'true');
  }

  const { data, ...rest } = useApi<AssetCategory[]>(
    API_ROUTES.asset_list.categories.get({
      membershipId: props?.membershipId ?? membershipId,
      organizationId,
      params,
    }),
    { keepPreviousData: true },
    !!sharingLinkId
  );

  return {
    data: withLiabilitiesCategories
      ? data
      : data?.filter(
          (category) =>
            category !== 'loans' &&
            category !== 'credit_accounts' &&
            (category as string) !== 'credits' // TODO: remove this line when the API is normalized
        ),
    ...rest,
  };
};
