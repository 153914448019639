import { useFinaryApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import { CryptoAccountResponse } from '../../types';
import { usePostApi } from '../useApi';

export type CreateCryptoAccountParams = {
  exchange: string;
  address?: string;
  api_key?: string;
  blockchains?: string[];
  code?: string;
  name?: string;
  passphrase?: string;
  redirect_uri?: string;
  secret_key?: string;
  subaccount?: string;
};

export const useCreateCryptoAccount = () => {
  const { organizationId, membershipId } = useFinaryApiConfig();

  return usePostApi<CryptoAccountResponse, CreateCryptoAccountParams>(
    API_ROUTES.crypto_accounts.post({ organizationId, membershipId })
  );
};
