import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../config';
import { useApiConfig, useFinaryApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import { HoldingsAccount, HoldingsAccountUpdateBody } from '../../types';

export const useUpdateHoldingsAccounts = (id?: string) => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    id
      ? API_ROUTES.holdingsAccounts
          .byId(id)
          .put({ organizationId, membershipId })
      : null,
    async (url: string, { arg }: { arg: HoldingsAccountUpdateBody }) =>
      fetchApi<HoldingsAccount>(url, {
        body: arg,
        method: 'PUT',
        ...apiConfiguration,
      })
  );
};
