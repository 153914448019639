import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../../config';
import { useApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  CryptoAccount,
  Institution,
  NotSharingLinkProps,
  SharingLinkProps,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { usePeriodDisplayMode } from '../../configuration';
import { useApi } from '../../useApi';

export type UsePortfolioCryptosByAccountBaseProps = {
  accountId?: string;
};

export type UsePortfolioCryptosByAccountWithoutSharingProps =
  UsePortfolioCryptosByAccountBaseProps & NotSharingLinkProps;

export type UsePortfolioCryptosByAccountWithSharingProps =
  UsePortfolioCryptosByAccountBaseProps & SharingLinkProps;

export type UsePortfolioCryptosByAccountProps =
  | UsePortfolioCryptosByAccountWithoutSharingProps
  | UsePortfolioCryptosByAccountWithSharingProps;

export const usePortfolioCryptosByAccount = ({
  accountId,
  sharingLinkId,
  accessCode,
}: UsePortfolioCryptosByAccountProps) => {
  const apiConfiguration = useApiConfig();
  const { organizationId, membershipId } = apiConfiguration;
  const { data: periodDisplayMode } = usePeriodDisplayMode(!!sharingLinkId);

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('period', periodDisplayMode);

  const apiResult = useApi<CryptoAccount>(
    accountId
      ? API_ROUTES.portfolio.cryptos.accountById(accountId).get({
          organizationId,
          membershipId,
          params,
        })
      : null,
    undefined,
    !!sharingLinkId
  );

  const { trigger, isMutating } = useSWRMutation(
    accountId
      ? API_ROUTES.portfolio.cryptos
          .accountById(accountId)
          .put({ organizationId, membershipId })
      : null,
    async (
      url: string,
      {
        arg: body,
      }: {
        arg:
          | Partial<CryptoAccount>
          | {
              code: string;
              exchange: Institution | string;
              redirect_uri: string;
            };
      }
    ) => {
      return apiResult.mutate(
        fetchApi<CryptoAccount>(url, {
          body,
          method: 'PUT',
          ...apiConfiguration,
        })
      );
    }
  );

  return {
    ...apiResult,
    trigger,
    isMutating,
  };
};
