import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  NotSharingLinkProps,
  PortfolioTimeseries,
  SharingLinkProps,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { usePeriodDisplayMode } from '../../configuration';
import { useApi } from '../../useApi';

export type UsePortfolioRealEstatesByAccountTimeseriesBaseProps = {
  accountId: string | undefined;
  type?: 'all' | 'sum';
};

export type UsePortfolioRealEstatesByAccountTimeseriesWithoutSharingProps =
  UsePortfolioRealEstatesByAccountTimeseriesBaseProps & NotSharingLinkProps;

export type UsePortfolioRealEstatesByAccountTimeseriesWithSharingProps =
  UsePortfolioRealEstatesByAccountTimeseriesBaseProps & SharingLinkProps;

export type UsePortfolioRealEstatesByAccountTimeseriesProps =
  | UsePortfolioRealEstatesByAccountTimeseriesWithoutSharingProps
  | UsePortfolioRealEstatesByAccountTimeseriesWithSharingProps;

export const usePortfolioRealEstatesByAccountTimeseries = ({
  accountId,
  sharingLinkId,
  accessCode,
  type = 'sum',
}: UsePortfolioRealEstatesByAccountTimeseriesProps) => {
  const { membershipId, organizationId } = useFinaryApiConfig();
  const { data: periodDisplayMode } = usePeriodDisplayMode(!!sharingLinkId);

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('timeseries_type', type);
  params.append('period', periodDisplayMode);

  return useApi<PortfolioTimeseries>(
    accountId
      ? API_ROUTES.portfolio.real_estates
          .accountById(accountId)
          .timeseries.get({
            membershipId,
            organizationId,
            params,
          })
      : null,
    { keepPreviousData: true },
    !!sharingLinkId
  );
};
