import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../../config';
import { useApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';

export const useFlanksReconnection = () => {
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    API_ROUTES.flanks.reconnection.post(),
    async (url: string) =>
      fetchApi<void>(url, {
        method: 'POST',
        ...apiConfiguration,
      })
  );
};
