import { useFinaryApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import {
  DividendsResponse,
  NotSharingLinkProps,
  SharingLinkProps,
} from '../../types';
import { createSharingLinkParams } from '../../utils';
import { useApi } from '../useApi';

export type UsePortfolioDividendsProps = NotSharingLinkProps | SharingLinkProps;

export const usePortfolioDividends = ({
  sharingLinkId,
  accessCode,
}: UsePortfolioDividendsProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();

  const params = new URLSearchParams({
    with_real_estate: 'true',
  });

  const sharingLinkParams = createSharingLinkParams(
    { sharingLinkId, accessCode },
    params
  );

  return useApi<DividendsResponse>(
    API_ROUTES.portfolio.dividends.get({
      organizationId,
      membershipId,
      params: sharingLinkParams,
    }),
    { keepPreviousData: true },
    !!sharingLinkId
  );
};
