import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../../config';
import { useApiConfig, useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';

export const useDeleteTransactionCategory = (id: number) => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    API_ROUTES.transactionCategories
      .byId(id)
      .delete({ organizationId, membershipId }),
    async (url: string) =>
      fetchApi<null>(url, {
        method: 'DELETE',
        ...apiConfiguration,
      })
  );
};
