import { Watch } from '../types';

export const formatWatchName = (
  watch: Pick<Partial<Watch>, 'manufacturer' | 'name'>
) => {
  const { manufacturer, name } = watch;

  if (manufacturer && name) {
    return `${watch.manufacturer} ${watch.name}`;
  }

  return watch.name ?? '';
};
