import {
  BankProvider,
  InstitutionProvider,
  InstitutionWithProviders,
} from '../types';

export const getInstitutionEnabledProvider = (
  institution: InstitutionWithProviders
): InstitutionProvider | undefined =>
  institution.providers.find((provider) =>
    institution.slug === 'interactive-brokers'
      ? provider.provider.name === BankProvider.finaryConnect
      : provider.is_enabled
  );
