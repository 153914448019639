import * as React from 'react';

import { useApiConfig } from '../providers';

export const useOnNewOrganizationMembership = (
  callback: (membershipId: string | null) => void
) => {
  const { membershipId } = useApiConfig();

  React.useEffect(() => {
    callback(membershipId);
  }, [callback, membershipId]);
};
