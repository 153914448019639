import * as React from 'react';

import { useFinaryApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import {
  FinancialProjectionParams,
  FinancialProjectionResult,
  NotSharingLinkProps,
  SharingLinkProps,
} from '../../types';
import { createSharingLinkParams } from '../../utils';
import { useApi } from '../useApi';

export type UseFinancialProjectionProps =
  | NotSharingLinkProps
  | SharingLinkProps;

export const useFinancialProjection = ({
  sharingLinkId,
  accessCode,
}: UseFinancialProjectionProps) => {
  /**
   * This hook is used on "user demand" only ie on a button click
   * To allow this, we give access to the `fetchSimulation` method
   * This will allow to set the URL only on user click ie not get the API data
   * everytime the form (with the params) changes
   */
  const [url, setUrl] = React.useState<string | null>(null);
  const { organizationId, membershipId } = useFinaryApiConfig();
  const simulation = useApi<FinancialProjectionResult>(
    url,
    undefined,
    !!sharingLinkId
  );

  return {
    ...simulation,
    fetchSimulation: ({
      duration,
      monthlyContribution,
    }: FinancialProjectionParams) => {
      const params = createSharingLinkParams({ sharingLinkId, accessCode });

      params.append('monthly_contribution', String(monthlyContribution));
      params.append('duration', String(duration));

      setUrl(
        API_ROUTES.insights.financial_projection.get({
          organizationId,
          membershipId,
          params,
        })
      );
    },
  };
};
