import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  AllocationResponse,
  NotSharingLinkProps,
  SharingLinkProps,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { useApi } from '../../useApi';

export type UsePortfolioInvestmentsByAccountGeoAllocationBaseProps = {
  accountId: string;
};

export type UsePortfolioInvestmentsByAccountGeoAllocationWithoutSharingProps =
  UsePortfolioInvestmentsByAccountGeoAllocationBaseProps & NotSharingLinkProps;

export type UsePortfolioInvestmentsByAccountGeoAllocationWithSharingProps =
  UsePortfolioInvestmentsByAccountGeoAllocationBaseProps & SharingLinkProps;

export type UsePortfolioInvestmentsByAccountGeoAllocationProps =
  | UsePortfolioInvestmentsByAccountGeoAllocationWithoutSharingProps
  | UsePortfolioInvestmentsByAccountGeoAllocationWithSharingProps;

export const usePortfolioInvestmentsByAccountGeoAllocation = ({
  accountId,
  sharingLinkId,
  accessCode,
}: UsePortfolioInvestmentsByAccountGeoAllocationProps) => {
  const { membershipId, organizationId } = useFinaryApiConfig();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });

  return useApi<AllocationResponse>(
    API_ROUTES.portfolio.investments.accountById(accountId).geoAllocation.get({
      membershipId,
      organizationId,
      params,
    }),
    {
      keepPreviousData: true,
    },
    !!sharingLinkId
  );
};
