import classNames from 'classnames';
import * as React from 'react';

import { Box } from '../Box';
import { Icon, IconName } from '../Icon';
import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from '../Polymorphic';

import {
  buttonStyles,
  ButtonVariants,
  loadingButton,
  loadingButtonIcon,
} from './Button.css';

export type ButtonProps = ButtonVariants & {
  isLoading?: boolean;
  prefixIcon?: IconName;
  suffixIcon?: IconName;
};

export type ButtonPolymorphicProps<C extends React.ElementType> =
  PolymorphicComponentPropWithRef<C, ButtonProps>;

export type ButtonComponent = <C extends React.ElementType = 'button'>(
  props: ButtonPolymorphicProps<C>
) => React.ReactNode | null;

export const Button: ButtonComponent & { displayName?: string } =
  React.forwardRef(
    <C extends React.ElementType = 'button'>(
      {
        as,
        variant,
        size,
        prefixIcon,
        isLoading,
        suffixIcon,
        children,
        className,
        onClick,
        ...componentProps
      }: ButtonPolymorphicProps<C>,
      ref?: PolymorphicRef<C>
    ) => {
      const Component = as || 'button';
      const [localLoading, setLocalLoading] = React.useState(false);
      const mountedRef = React.useRef(false);

      React.useEffect(() => {
        // This is for preventing setstate on unmounted component
        mountedRef.current = true;

        return () => {
          mountedRef.current = false;
        };
      }, []);

      const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
        if (onClick && !localLoading) {
          const res = onClick(e);

          if (res !== null && (res as unknown) instanceof Promise) {
            // This is to prevent icon blink if the loading time is really small
            if (mountedRef.current) {
              setLocalLoading(true);
            }
            (res as unknown as Promise<void>).finally(() => {
              if (mountedRef.current) {
                setLocalLoading(false);
              }
            });
          }
        }
      };

      const loading = isLoading || localLoading;
      const gap = size === 'small' ? 's2' : 's3';

      return (
        <Component
          ref={ref}
          className={classNames(buttonStyles({ variant, size }), className)}
          onClick={loading ? undefined : handleClick}
          style={loading ? { pointerEvents: 'none' } : undefined}
          {...componentProps}
        >
          <Box
            alignItems="center"
            justifyContent="center"
            gap={gap}
            className={
              loading && !prefixIcon && !suffixIcon ? loadingButton : undefined
            }
          >
            {prefixIcon ? (
              loading ? (
                <Icon
                  name="spinnerSolid"
                  animation="rotate"
                  size={size}
                  style={{ marginTop: -8, marginBottom: -8 }}
                />
              ) : (
                <Icon
                  name={prefixIcon}
                  size={size}
                  style={{ marginTop: -8, marginBottom: -8 }}
                />
              )
            ) : null}

            <Box alignItems="center" justifyContent="center" gap={gap}>
              {children}
            </Box>

            {loading && !prefixIcon && !suffixIcon ? (
              // In case there's no prefix or suffix, display the loader on top of the child to keep the same size
              <Icon
                name="spinnerSolid"
                className={loadingButtonIcon}
                animation="rotate"
                size={size}
                style={{ marginTop: -8, marginBottom: -8 }}
              />
            ) : null}

            {suffixIcon ? (
              loading && !prefixIcon ? (
                <Icon
                  name="spinnerSolid"
                  animation="rotate"
                  size={size}
                  style={{ marginTop: -8, marginBottom: -8 }}
                />
              ) : (
                <Icon
                  name={suffixIcon}
                  size={size}
                  style={{ marginTop: -8, marginBottom: -8 }}
                />
              )
            ) : null}
          </Box>
        </Component>
      );
    }
  );

Button.displayName = 'Button';
