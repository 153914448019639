import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import { usePostApi } from '../../useApi';

export type PlaidCreateLinkTokenResponse = {
  expiration: string;
  link_token: string;
  request_id: string;
};

export const usePlaidCreateLinkToken = () => {
  const { organizationId, membershipId } = useFinaryApiConfig();

  return usePostApi<
    PlaidCreateLinkTokenResponse,
    { provider_connection_id: string | null }
  >(API_ROUTES.plaid.createLinkToken.post({ organizationId, membershipId }));
};
