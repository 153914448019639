import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../../../config';
import { useApiConfig, useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';

export const useCreateTransactionRule = () => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    API_ROUTES.transactionRules.post({ organizationId, membershipId }),
    async (
      url: string,
      {
        arg,
      }: {
        arg: {
          pattern: string[];
          transaction_category_id: number;
        };
      }
    ) =>
      fetchApi(url, {
        body: arg,
        method: 'POST',
        ...apiConfiguration,
      })
  );
};
