import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  FondsEuroSummaryResponse,
  NotSharingLinkProps,
  SharingLinkProps,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { usePeriodDisplayMode } from '../../configuration';
import { useApi } from '../../useApi';

export type UsePortfolioFondsEuroProps = NotSharingLinkProps | SharingLinkProps;

export const usePortfolioFondsEuro = ({
  sharingLinkId,
  accessCode,
}: UsePortfolioFondsEuroProps = {}) => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const { data: periodDisplayMode } = usePeriodDisplayMode(!!sharingLinkId);

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('period', periodDisplayMode);

  return useApi<FondsEuroSummaryResponse>(
    API_ROUTES.portfolio.fonds_euro.get({
      organizationId,
      membershipId,
      params,
    }),
    undefined,
    !!sharingLinkId
  );
};
