import {
  RealEstateBuildingPeriod,
  RealEstateBuildingType,
  RealEstateCategory,
  RealEstateRentalPeriod,
  RealEstateRentalType,
} from '../types';

export const REAL_ESTATES_BUILDING_TYPES: RealEstateBuildingType[] = [
  'apartment',
  'house',
  'building',
  'parking_box',
  'land',
  'commercial',
  'other',
];

export const REAL_ESTATE_BUILDING_PERIOD: RealEstateBuildingPeriod[] = [
  'before_1850',
  'between_1850_1913',
  'between_1914_1947',
  'between_1948_1974',
  'between_1975_1984',
  'between_1985_2007',
  'after_2008',
];

export const REAL_ESTATE_CATEGORY: RealEstateCategory[] = [
  'live_primary',
  'live_secondary',
  'rent',
  'other',
];

export const REAL_ESTATE_RENTAL_TYPES: RealEstateRentalType[] = [
  'lmnp',
  'sci',
  'nue',
  'pinel',
];

export const REAL_ESTATE_RENTAL_PERIODS: RealEstateRentalPeriod[] = [
  'annual',
  'seasonal',
];
