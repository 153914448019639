import * as RadixDropdown from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';
import * as React from 'react';

import { Box } from '../Box';

import { dropdownStyles } from './Dropdown.css';
import { dropdownSeparator } from './DropdownItem.css';

export type DropdownProps = {
  items: React.ReactNode[][];
  className?: React.CSSProperties;
  disabled?: boolean;
  style?: React.CSSProperties;
} & RadixDropdown.DropdownMenuContentProps &
  Pick<RadixDropdown.DropdownMenuProps, 'onOpenChange' | 'modal'>;

export const Dropdown = ({
  className,
  items,
  children,
  disabled,
  onOpenChange,
  modal = false,
  ...dropdownProps
}: DropdownProps) => {
  return (
    <RadixDropdown.Root modal={modal} onOpenChange={onOpenChange}>
      <RadixDropdown.Trigger
        disabled={disabled}
        asChild={typeof children === 'string' ? undefined : true}
      >
        {children}
      </RadixDropdown.Trigger>
      <RadixDropdown.Portal>
        <RadixDropdown.Content
          className={classNames(dropdownStyles, className)}
          side="right"
          align="start"
          sideOffset={4}
          hideWhenDetached
          onCloseAutoFocus={(event) => event.preventDefault()}
          {...dropdownProps}
        >
          <Box flexDirection="column" gap="s2">
            {items.map((group, index) =>
              group.length > 0 ? (
                <React.Fragment key={index}>
                  <RadixDropdown.Group key={index}>{group}</RadixDropdown.Group>
                  {index < items.length - 1 ? (
                    <RadixDropdown.Separator className={dropdownSeparator} />
                  ) : null}
                </React.Fragment>
              ) : null
            )}
          </Box>
        </RadixDropdown.Content>
      </RadixDropdown.Portal>
    </RadixDropdown.Root>
  );
};
