import Cookies from 'js-cookie';

const defaultLang: AvailableLanguage = 'en';

export const availableLanguages = [
  { name: 'Français', value: 'fr' },
  { name: 'English', value: 'en' },
  { name: 'Deutsch', value: 'de' },
] as const;

export type AvailableLanguageObjs = (typeof availableLanguages)[number];

export type AvailableLanguage = AvailableLanguageObjs['value'];

export const getAvailableLanguage = (language?: string): AvailableLanguage => {
  const match = availableLanguages.find(
    (availableLanguage) => availableLanguage.value === language
  );

  return match ? match.value : defaultLang;
};

const isBrowser = typeof window !== 'undefined';

const getBrowserLanguageParts = () =>
  isBrowser ? window.navigator.language.split('-') : undefined;

const getBrowserLanguage = () => {
  const parts = getBrowserLanguageParts();
  return parts ? parts[0] : defaultLang;
};

const getFinaryLanguage = () =>
  Cookies.get('finary_language') as AvailableLanguage;

const getNextlocale = () => Cookies.get('NEXT_LOCALE') as AvailableLanguage;

export const getSupportedLanguage = () =>
  getAvailableLanguage(
    getFinaryLanguage() ?? getNextlocale() ?? getBrowserLanguage()
  );
