import { API_ROUTES } from '../../../routes';
import { RealEstateBuildingType } from '../../../types';
import { useApi } from '../../useApi';

export type RealEstateEstimableResult = {
  is_estimable: boolean;
};

export const useRealEstatesEstimable = (
  place_id: string,
  building_type: RealEstateBuildingType
) => {
  const params = new URLSearchParams();
  params.append('place_id', place_id);
  params.append('building_type', building_type);

  return useApi<RealEstateEstimableResult>(
    API_ROUTES.real_estates.estimable(params),
    { keepPreviousData: true }
  );
};
