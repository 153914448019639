import { fetchApi } from '../../../config';
import { useApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';

export type FlanksGetResetTokenResponse = {
  reset_token: string | null;
  sca_token: string | null;
};

export const useFlanksGetResetToken = () => {
  const apiConfiguration = useApiConfig();

  return {
    getFlanksResetToken: (providerConnectionId: string) => {
      const params = new URLSearchParams();
      params.append('provider_connection_id', providerConnectionId);

      return fetchApi<FlanksGetResetTokenResponse>(
        API_ROUTES.flanks.resetToken.get(params),
        apiConfiguration
      );
    },
  };
};
