import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  DividendsResponse,
  NotSharingLinkProps,
  SharingLinkProps,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { useApi } from '../../useApi';

export type UsePortfolioInvestmentsByAccountDividendsBaseProps = {
  accountId: string;
};

export type UsePortfolioInvestmentsByAccountDividendsWithoutSharingProps =
  UsePortfolioInvestmentsByAccountDividendsBaseProps & NotSharingLinkProps;

export type UsePortfolioInvestmentsByAccountDividendsWithSharingProps =
  UsePortfolioInvestmentsByAccountDividendsBaseProps & SharingLinkProps;

export type UsePortfolioInvestmentsByAccountDividendsProps =
  | UsePortfolioInvestmentsByAccountDividendsWithoutSharingProps
  | UsePortfolioInvestmentsByAccountDividendsWithSharingProps;

export const usePortfolioInvestmentsByAccountDividends = ({
  accountId,
  sharingLinkId,
  accessCode,
}: UsePortfolioInvestmentsByAccountDividendsProps) => {
  const { membershipId, organizationId } = useFinaryApiConfig();

  const params = new URLSearchParams({
    with_real_estate: 'true',
  });

  const sharingLinkParams = createSharingLinkParams(
    { sharingLinkId, accessCode },
    params
  );

  return useApi<DividendsResponse>(
    API_ROUTES.portfolio.investments.accountById(accountId).dividends.get({
      membershipId,
      organizationId,
      params: sharingLinkParams,
    }),
    {
      keepPreviousData: true,
    },
    !!sharingLinkId
  );
};
