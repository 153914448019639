import useSWRMutation from 'swr/mutation';

import { fetchApi } from '../config';
import { useApiConfig, useFinaryApiConfig } from '../providers';
import { API_ROUTES } from '../routes';
import { HoldingsAccount, WealthStatement } from '../types';

export const useCreateWealthStatement = () => {
  const { organizationId, membershipId } = useFinaryApiConfig();
  const apiConfiguration = useApiConfig();

  return useSWRMutation(
    API_ROUTES.wealth_statements.post({ organizationId, membershipId }),
    async (url: string, { arg }: { arg: HoldingsAccount[] }) =>
      fetchApi<WealthStatement>(url, {
        method: 'POST',
        body: {
          holdings_accounts_ids: arg.map((account) => account.id),
        },
        ...apiConfiguration,
      })
  );
};
