import * as React from 'react';

import { Select, SelectCurrencyButton, SingleSelectProps } from '../Select';

import { TextField, TextFieldProps } from './TextField';

export type TextFieldWithSelectProps<T extends string> = Omit<
  TextFieldProps,
  'suffix' | 'ref'
> & {
  selectProps: Pick<
    SingleSelectProps<T>,
    'options' | 'onChange' | 'defaultValue' | 'children' | 'value'
  >;
};

const TextFieldWithSelectInner = <T extends string>(
  { selectProps, ...props }: TextFieldWithSelectProps<T>,
  ref: React.Ref<HTMLInputElement>
) => {
  const { children, ...selectPropsWithoutChildren } = selectProps;

  const suffix = (
    <Select {...selectPropsWithoutChildren}>
      <SelectCurrencyButton>{children}</SelectCurrencyButton>
    </Select>
  );
  return <TextField {...props} suffix={suffix} ref={ref} />;
};

// TODO - Check if there's another option https://fettblog.eu/typescript-react-generic-forward-refs/
export const TextFieldWithSelect = React.forwardRef(
  TextFieldWithSelectInner
) as <T extends string>(
  props: TextFieldWithSelectProps<T> & {
    ref?: React.Ref<HTMLInputElement>;
  }
) => ReturnType<typeof TextFieldWithSelectInner>;
