import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  FondsEuroDistributionResponse,
  NotSharingLinkProps,
  SharingLinkProps,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { useApi } from '../../useApi';

export type UsePortfolioFondsEuroDistributionProps =
  | NotSharingLinkProps
  | SharingLinkProps;

export const usePortfolioFondsEuroDistribution = ({
  sharingLinkId,
  accessCode,
}: UsePortfolioFondsEuroDistributionProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('type', 'name'); // TODO: add other type when supported by backend

  return useApi<FondsEuroDistributionResponse>(
    API_ROUTES.portfolio.fonds_euro.distribution.get({
      organizationId,
      membershipId,
      params,
    }),
    { keepPreviousData: true },
    !!sharingLinkId
  );
};
