export const NARROW_NO_BREAK_SPACE = '\u202F';

export const NO_BREAK_SPACE = '\u00A0';

export const capitalize = (str: string) => {
  return str[0]?.toUpperCase() + str.slice(1);
};

export const snakeCaseToCamelCase = (str: string) =>
  str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace('-', '').replace('_', '')
    );

export const kebabCaseToCamelCase = (str: string) =>
  str.replace(/-./g, (x) => x[1]?.toUpperCase() ?? '');

/**
 * Returns a random string of the given length, with each character being in the given ranges.
 * ex: randomString(10, ['a', 'z'], ['A', 'Z'], ['0', '9']) => 'aB3dE4fG5h'
 */
export const randomString = (length: number, ...ranges: [string, string][]) => {
  let str = '';
  [...Array(length)].forEach(() => {
    const index = Math.floor(Math.random() * ranges.length);
    const bound1 = ranges[index]?.[0].charCodeAt(0) ?? '0'.charCodeAt(0);
    const bound2 = ranges[index]?.[1].charCodeAt(0) ?? '9'.charCodeAt(0);
    const min = Math.min(bound1, bound2);
    const max = Math.max(bound1, bound2);
    const charNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    str += String.fromCharCode(charNumber);
  });
  return str;
};

/**
 * Returns true if the searchString matches one of the given patterns
 * @param searchString
 * @param patterns a list a pattern to search against, will be converted to a
 *                 regex with ^ and $ around it
 */
export const matchPattern = (
  searchString: string,
  patterns: (string | RegExp)[]
) =>
  patterns.some((p) =>
    searchString.match(typeof p === 'string' ? new RegExp(`^${p}$`) : p)
  );
