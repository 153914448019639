import { useFinaryApiConfig } from '../../providers';
import { API_ROUTES } from '../../routes';
import { Capability, InstitutionWithProviders } from '../../types';
import { useApi } from '../useApi';
import { useUser } from '../users';

export const useMostPopularInstitutions = (
  capabilities: Capability[] = ['cash', 'crypto']
) => {
  const { data: user } = useUser();
  const { membershipId, organizationId } = useFinaryApiConfig();

  const params = new URLSearchParams();
  params.append('capability', capabilities.join(','));
  if (user?.country) {
    params.append('country', user.country);
  }

  return useApi<InstitutionWithProviders[]>(
    API_ROUTES.institutions.mostPopular.get({
      membershipId,
      organizationId,
      params,
    }),
    { keepPreviousData: true }
  );
};
