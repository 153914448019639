import { API_ROUTES } from '../../../routes';
import { Startup } from '../../../types';
import { useApi } from '../../useApi';

export type AutocompleteStartup = Pick<Startup, 'logo_url' | 'name'> & {
  correlation_id: string | undefined;
  external_logo_url?: string | null;
};

export const useStartupsAutocomplete = (query: string) => {
  const params = new URLSearchParams();

  if (query) {
    params.append('query', query);
  }

  return useApi<AutocompleteStartup[]>(
    query ? API_ROUTES.startups.autocomplete.get(params) : null,
    {
      keepPreviousData: true,
    }
  );
};
