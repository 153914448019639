import { useFinaryApiConfig } from '../../../providers';
import { API_ROUTES } from '../../../routes';
import {
  AssetsDistributionResponse,
  NotSharingLinkProps,
  SharingLinkProps,
} from '../../../types';
import { createSharingLinkParams } from '../../../utils';
import { useApi } from '../../useApi';

export type UsePortfolioCryptosByAccountDistributionBaseProps = {
  accountId: string;
};

export type UsePortfolioCryptosByAccountDistributionWithoutSharingProps =
  UsePortfolioCryptosByAccountDistributionBaseProps & NotSharingLinkProps;

export type UsePortfolioCryptosByAccountDistributionWithSharingProps =
  UsePortfolioCryptosByAccountDistributionBaseProps & SharingLinkProps;

export type UsePortfolioCryptosByAccountDistributionProps =
  | UsePortfolioCryptosByAccountDistributionWithoutSharingProps
  | UsePortfolioCryptosByAccountDistributionWithSharingProps;

export const usePortfolioCryptosByAccountDistribution = ({
  accountId,
  sharingLinkId,
  accessCode,
}: UsePortfolioCryptosByAccountDistributionProps) => {
  const { organizationId, membershipId } = useFinaryApiConfig();

  const params = createSharingLinkParams({ sharingLinkId, accessCode });
  params.append('type', 'crypto');
  params.append('period', 'all');

  return useApi<AssetsDistributionResponse>(
    API_ROUTES.portfolio.cryptos.accountById(accountId).distribution.get({
      organizationId,
      membershipId,
      params,
    }),
    { keepPreviousData: true },
    !!sharingLinkId
  );
};
