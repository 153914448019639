import * as RadixDropdown from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';
import React from 'react';

import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from '../Polymorphic';
import { Typography } from '../Typography';

import { dropdownControlStyles } from './DropdownControl.css';
import { dropdownItemLabelStyles } from './DropdownItem.css';

export type DropdownControlComponentProps<C extends React.ElementType> =
  PolymorphicComponentPropWithRef<C>;

export type DropdownControlComponent = <C extends React.ElementType = 'button'>(
  props: DropdownControlComponentProps<C>
) => React.ReactNode | null;

export const DropdownControl: DropdownControlComponent & {
  displayName?: string;
} = React.forwardRef(
  <C extends React.ElementType = 'button'>(
    {
      as,
      className,
      children,
      ...componentProps
    }: DropdownControlComponentProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const Component = as || 'button';
    return (
      <RadixDropdown.Item asChild>
        <Component
          ref={ref}
          className={classNames(dropdownControlStyles, className)}
          {...componentProps}
        >
          <Typography variant="bodyMedium" className={dropdownItemLabelStyles}>
            {children}
          </Typography>
        </Component>
      </RadixDropdown.Item>
    );
  }
);

DropdownControl.displayName = 'DropdownControl';
